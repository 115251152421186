angular.module('ics')
    .controller('FSPRiskProfileAdminController', function ($scope, $state, $controller, $timeout,
                                                           FSPRiskProfileService, FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);

        $scope.risk_profile_scope_of_license = [];
        $scope.risk_profile_fit_and_proper = [];
        $scope.risk_profile_operational_ability = [];
        $scope.risk_profile_financial_soundness = [];
        $scope.risk_profile_code_of_conduct = [];
        $scope.risk_profile_representatives = [];
        $scope.risk_profile_honesty_and_integrity = [];
        $scope.risk_profile_discretionary = [];
        $scope.risk_profile_general = [];

        $scope.tokenId = '';
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            retrieveRiskProfile();
        });

        function retrieveRiskProfile () {

            $scope.processing = true;
            $scope.editing = false;

            FSPRiskProfileService
                .getRiskProfile($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.risk_profile_scope_of_license = res.data.risk_profile_scope_of_license;
                    $scope.risk_profile_fit_and_proper = res.data.risk_profile_fit_and_proper;
                    $scope.risk_profile_operational_ability = res.data.risk_profile_operational_ability;
                    $scope.risk_profile_financial_soundness = res.data.risk_profile_financial_soundness;
                    $scope.risk_profile_code_of_conduct = res.data.risk_profile_code_of_conduct;
                    $scope.risk_profile_representatives = res.data.risk_profile_representatives;
                    $scope.risk_profile_honesty_and_integrity = res.data.risk_profile_honesty_and_integrity;
                    $scope.risk_profile_discretionary = res.data.risk_profile_discretionary;
                    $scope.risk_profile_general = res.data.risk_profile_general;

                    $scope.title_scope_of_license = res.data.title_scope_of_license;
                    $scope.title_fit_and_proper = res.data.title_fit_and_proper;
                    $scope.title_operational_ability = res.data.title_operational_ability;
                    $scope.title_financial_soundness = res.data.title_financial_soundness;
                    $scope.title_code_of_conduct = res.data.title_code_of_conduct;
                    $scope.title_representatives = res.data.title_representatives;
                    $scope.title_honesty_and_integrity = res.data.title_honesty_and_integrity;
                    $scope.title_discretionary = res.data.title_discretionary;
                    $scope.title_general = res.data.title_general;

                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.saveRiskProfile = function () {
            $scope.processing = true;
            FSPRiskProfileService.saveRiskProfile($scope.fspId,
                $scope.risk_profile_scope_of_license,
                $scope.risk_profile_fit_and_proper,
                $scope.risk_profile_operational_ability,
                $scope.risk_profile_financial_soundness,
                $scope.risk_profile_code_of_conduct,
                $scope.risk_profile_representatives,
                $scope.risk_profile_honesty_and_integrity,
                $scope.risk_profile_discretionary,
                $scope.risk_profile_general,
                $scope.tokenId, $scope.visitorId)
                .then(() => {
                    $scope.processing = false;
                    $scope.goBack();
                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

    });
