angular.module('ics')

    .factory('FSPListsService', function ($http) {

        return {

            getRegRiskMatrixList: function (firebaseTokenId, fspId) {

                var req = {
                    method: 'GET',
                    url: `/api/reg_risk_matrix/list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createRegRiskMatrixItem: function (fsp_id, item_category,
                                               relevant, responsible_party, risk_of_non_compliance,
                                               impact_of_non_compliance, firebaseTokenId, visitorId) {

                let req = {
                    method: 'POST',
                    url: '/api/reg_risk_matrix/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        item_category: item_category,
                        fsp_id: fsp_id,
                        relevant: relevant,
                        responsible_party: responsible_party,
                        risk_of_non_compliance: risk_of_non_compliance,
                        impact_of_non_compliance: impact_of_non_compliance
                    }
                };

                return $http(req);
            },

            updateRegRiskMatrixItem: function (item_id, fsp_id, item_category,
                                               relevant, responsible_party, risk_of_non_compliance,
                                               impact_of_non_compliance, firebaseTokenId, visitorId) {
                let req = {
                    method: 'PUT',
                    url: `/api/reg_risk_matrix/${item_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        item_category: item_category,
                        fsp_id: fsp_id,
                        relevant: relevant,
                        responsible_party: responsible_party,
                        risk_of_non_compliance: risk_of_non_compliance,
                        impact_of_non_compliance: impact_of_non_compliance
                    }
                };

                return $http(req);
            },


            deleteRegRiskMatrixItem: function (id, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/reg_risk_matrix/${id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            },

            getMarketRiskMatrixList: function (firebaseTokenId, fspId) {

                var req = {
                    method: 'GET',
                    url: `/api/mkt_risk_matrix/list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createMarketRiskMatrixItem: function (fsp_id, item_category, relevant,
                                                  implementation, third_party,
                                                  firebaseTokenId, visitorId) {

                let req = {
                    method: 'POST',
                    url: '/api/mkt_risk_matrix/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        item_category: item_category,
                        fsp_id: fsp_id,
                        relevant: relevant,
                        implementation: implementation,
                        third_party: third_party
                    }
                };

                return $http(req);
            },

            updateMarketRiskMatrixItem: function (item_id, fsp_id, item_category,
                                                  relevant, implementation,
                                                  third_party, firebaseTokenId, visitorId) {
                let req = {
                    method: 'PUT',
                    url: `/api/mkt_risk_matrix/${item_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        item_category: item_category,
                        fsp_id: fsp_id,
                        relevant: relevant,
                        implementation: implementation,
                        third_party: third_party
                    }
                };

                return $http(req);
            },


            deleteMarketRiskMatrixItem: function (id, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/mkt_risk_matrix/${id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            },


            getOperationalRiskMatrixList: function (firebaseTokenId, fspId) {

                var req = {
                    method: 'GET',
                    url: `/api/operational_risk_matrix/list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createOperationalRiskMatrixItem: function (fsp_id, item_category, relevant,
                                                       implementation, third_party,
                                                       firebaseTokenId, visitorId) {

                let req = {
                    method: 'POST',
                    url: '/api/operational_risk_matrix/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        item_category: item_category,
                        fsp_id: fsp_id,
                        relevant: relevant,
                        implementation: implementation,
                        third_party: third_party
                    }
                };

                return $http(req);
            },

            updateOperationalRiskMatrixItem: function (item_id, fsp_id, item_category,
                                                       relevant, implementation,
                                                       third_party, firebaseTokenId, visitorId) {
                let req = {
                    method: 'PUT',
                    url: `/api/operational_risk_matrix/${item_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        item_category: item_category,
                        fsp_id: fsp_id,
                        relevant: relevant,
                        implementation: implementation,
                        third_party: third_party
                    }
                };

                return $http(req);
            },


            deleteOperationalRiskMatrixItem: function (id, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/operational_risk_matrix/${id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            },


            getExternalAuditorList: function (fsp_id, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/external_auditor/list/${fsp_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            getExternalAuditorDetails: function (item_id, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/external_auditor/${item_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createExternalAuditor: function (fsp_id, name,
                                             responsible_partner, contact_number, practice_number, appointment_date,
                                             attachments, firebaseTokenId, visitorId) {
                var req = {
                    method: 'POST',
                    url: '/api/external_auditor/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp_id: fsp_id,
                        name: name,
                        responsible_partner: responsible_partner,
                        contact_number: contact_number,
                        practice_number: practice_number,
                        appointment_date: appointment_date,
                        attachments: attachments
                    }
                };

                return $http(req);
            },


            editExternalAuditor: function (item_id, fsp_id, name,
                                           responsible_partner, contact_number, practice_number, appointment_date,
                                           attachments, firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/external_auditor/${item_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp_id: fsp_id,
                        name: name,
                        responsible_partner: responsible_partner,
                        contact_number: contact_number,
                        practice_number: practice_number,
                        appointment_date: appointment_date,
                        attachments: attachments
                    }
                };

                return $http(req);
            },

            deleteExternalAuditor: function (id, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/external_auditor/${id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            },


        }

    });
