angular.module('ics')

    .factory('FSPDirectorsService', function ($http) {

        return {

            getDirectorsList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_director_list/${fspId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            createDirector: function (fspId, first_name, last_name, id_number, 
                date_appointed, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_director/',
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        first_name: first_name,
                        last_name: last_name,
                        id_number: id_number,
                        date_appointed: date_appointed,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            editDirector: function (fspDirectorId, first_name, last_name, 
                id_number, date_appointed, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_director/${fspDirectorId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        first_name: first_name,
                        last_name: last_name,
                        id_number: id_number,
                        date_appointed: date_appointed,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            deleteDirector: function (fspDirectorId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_director/${fspDirectorId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
