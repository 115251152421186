angular.module('ics')
    .controller('BaseAdminController', function ($state, $rootScope, $scope, $window, FirebaseAuthService, FirebaseStorageService) {

        $scope.errorMessage = '';
        $scope.uploadedFileNames = [];
        $scope.visitorId = "";

        angular.element(function () {

            $(document).ready(function () {

                // Materialise SideNav
                $('.toggle-side-nav').sideNav({
                    menuWidth: 179
                });
                $('.close-side-nav').on('click', function () {
                    $('toggle-side-nav').sideNav('hide');
                });

                // Materialise Tabs
                $('ul.tabs').tabs({
                    onShow: function (tab) {
                        currentTab = $(tab)[0].id;
                        localStorage.setItem('currentTab', currentTab);
                    }
                });
                if (currentTab !== '') {
                    $('ul.tabs').tabs('select_tab', currentTab);
                }

                const fpCookieValue = getCookie("ics-visitor-id");
                if (fpCookieValue) {
                    $scope.visitorId = fpCookieValue;
                    console.log($scope.visitorId);
                } else {
                    // Get the visitor identifier when you need it.
                    fpPromise.then(fp => fp.get()).then(result => {
                        console.log(result.visitorId);
                        $scope.visitorId = result.visitorId;
                        setCookie("ics-visitor-id", result.visitorId, 999);
                    });
                }

            });

        });

        $scope.checkErrStatus = function (error) {
            console.log('checkErrStatus');
            console.log(error);
            if (error.status === 403) {
                return $scope.signOut();
            }
            $scope.errorMessage = error.data || error.message || error;
            console.log('$scope.errorMessage set');
            $scope.$applyAsync();
            if ($scope.processing) {
                $scope.processing = false;
            }

        };

        $scope.removeAttachment = function (index, attachments) {
            const filesUploadedIndex = $scope.uploadedFileNames.findIndex(item => item === attachments[index].file_name);
            if (filesUploadedIndex !== -1) {
                $scope.uploadedFileNames.splice(filesUploadedIndex, 1);
            }
            attachments.splice(index, 1);
        };

        $scope.fileUploader = function (e) {

            const fileNames = [], firebasePromises = [];

            if (!e.target.files || e.target.files.length === 0) {
                return;
            }

            for (var i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                console.log(file);
                if (!file || !file.name) {
                    continue;
                }
                if ($scope.uploadedFileNames.find(item => item === file.name)) {
                    continue;
                }
                $scope.uploadedFileNames.push(file.name);
                fileNames.push(file.name);
                firebasePromises
                    .push(FirebaseStorageService.uploadFileToCloudStorage(this.folder, file, null));
            }

            if (firebasePromises.length === 0) {
                return;
            }

            $('.file-field #uploading-document').removeClass('ng-hide');

            Promise
                .all(firebasePromises)
                .then(storageInfos => {

                    for (let i = 0; i < storageInfos.length; i++) {

                        this.attachments.push({
                            file_name: fileNames[i],
                            file_url: storageInfos[i].downloadUrl
                        });
                    }

                    $scope.$broadcast('filesUploaded', this.attachments);
                    $('.file-field #uploading-document').addClass('ng-hide');

                })
                .catch(err => {
                    $('.file-field #uploading-document').addClass('ng-hide');
                    $scope.checkErrStatus(err);
                });


        }

        $scope.signOut = function () {
            console.log('sign out');
            FirebaseAuthService.signOut().then(function () {
                $rootScope.loggedInUserRole = {};
                $rootScope.isAuthenticated = false;
                $scope.isSuperAdmin = false;
                $scope.isAdmin = false;
                $scope.isFSP = false;
                currentTab = '';
                localStorage.removeItem('currentTab');
                $state.transitionTo('signin');
            }, function (error) {
                //console.error('Sign out error', error);
            });
        };

        $scope.padNumber = function (value) {
            const item = parseInt(value, 10);
            if (item < 10) {
                return '0' + item;
            }
            return item + '';
        };

        $scope.historyBack = function () {
            $window.history.back();
        };

        //materialize boilerplate
        setTimeout(function () {

            $(document).ready(function () {

                $('.modal').modal({
                    complete: function (args) {
                        console.log('complete');
                    }
                });

                $('select').material_select();

            });

        }, 500);

    });