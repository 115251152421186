angular.module('ics')

    .factory('CategoriesService', function ($http) {

        return {

            getCategoriesList: function (categoryTypeId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/category/list/${categoryTypeId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            getCategoriesListFromCode: function (categoryTypeCode, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/category/list_from_code/${categoryTypeCode}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createCategory: function (categoryTypeId, name, code, display_order, firebaseTokenId, visitorId) {
                var req = {
                    method: 'POST',
                    url: '/api/category/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'category_type_id': categoryTypeId,
                        'name': name,
                        'code': code,
                        'display_order': display_order
                    }
                };

                return $http(req);
            },

            editCategory: function (categoryId, name, code, display_order, firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/category/${categoryId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'name': name,
                        'code': code,
                        'display_order': display_order
                    }
                };

                return $http(req);
            },

            deleteCategory: function (categoryId, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/category/${categoryId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            }
        }

    });
