angular.module('ics')
    .factory('responseObserver', function responseObserver($q, $location) {
        return {
            'responseError': function (errorResponse) {
                switch (errorResponse.status) {
                    case 403:
                        $location.url('/');
                        break;
                    case 500:
                        //$location.url('/');
                        break;
                    case 404:
                        $location.url('/');
                        break;
                }
                return $q.reject(errorResponse);
            }
        };
    });