angular.module('ics').
directive('fileUpload', [function () {
    return {
        link: function (scope, element, attrs) {
            // Modified from https://developer.mozilla.org/en-US/docs/Web/API/FileReader
            var fileReader = new FileReader();

            scope.file = {};

            fileReader.onload = function (fileReaderEvent) {
                scope.$apply(function () {
                    scope.file.data = fileReaderEvent.target.result;
                });
            };

            var load_file = function (fileInput) {
                if (fileInput.files.length === 0) {
                    return;
                }

                var file = fileInput.files[0];
                scope.file = file;
                fileReader.readAsDataURL(file);
                scope.$apply();
            };

            element[0].onchange = function () {
                load_file(element[0]);
            };
        },
        restrict: 'A'
    };
}]);
