angular.module ('ics')

.factory ('FSBSubmissionDatesService', function ($http) {

    return {

        getSubmissionDatesList: function (fspId, firebaseTokenId, visitorId) {

            var req = {
                method: 'GET',
                url: `/api/fsb_submission_dates_list/${fspId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http (req);

        },

        createSubmissionDate: function (fspId,
                                        submission_type,
                                        due_date_day,
                                        due_date_month,
                                        is_recurring,
                                        send_email_reminder,
                                        reminder_date,
                                        email_reminder_to,
                                        email_reminder_subject,
                                        email_reminder_body,
                                        due_date_year,
                                        is_submitted,
                                        notes,
                                        email_template,
                                        frequency,
                                        firebaseTokenId, visitorId) {

            const req = {
                method: 'POST',
                url: '/api/fsb_sub_date/',
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    fsp: fspId,
                    submission_type: submission_type,
                    due_date_day: due_date_day,
                    due_date_month: due_date_month,
                    is_recurring: is_recurring,
                    send_email_reminder: send_email_reminder,
                    reminder_date: reminder_date,
                    email_reminder_to: email_reminder_to,
                    email_reminder_subject: email_reminder_subject,
                    email_reminder_body: email_reminder_body,
                    due_date_year: due_date_year,
                    is_submitted: is_submitted,
                    notes: notes,
                    email_template: email_template,
                    frequency: frequency
                }
            };

            return $http (req);

        },

        editSubmissionDate: function (fsbSubmissionDateId,
                                      submission_type,
                                      submissions_to_delete,
                                      due_date_day,
                                      due_date_month,
                                      is_recurring,
                                      send_email_reminder,
                                      reminder_date,
                                      email_reminder_to,
                                      email_reminder_subject,
                                      email_reminder_body,
                                      due_date_year,
                                      is_submitted,
                                      notes,
                                      email_template,
                                      frequency,
                                      firebaseTokenId, visitorId) {

            const req = {
                method: 'PUT',
                url: `/api/fsb_sub_date/${fsbSubmissionDateId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    submissions: submissions_to_delete,
                    submission_type: submission_type,
                    due_date_day: due_date_day,
                    due_date_month: due_date_month,
                    is_recurring: is_recurring,
                    send_email_reminder: send_email_reminder,
                    reminder_date: reminder_date,
                    email_reminder_to: email_reminder_to,
                    email_reminder_subject: email_reminder_subject,
                    email_reminder_body: email_reminder_body,
                    due_date_year: due_date_year,
                    is_submitted: is_submitted,
                    notes: notes,
                    email_template: email_template,
                    frequency: frequency
                }
            };

            return $http (req);

        },

        deleteSubmissionDate: function (fsbSubmissionDateId, firebaseTokenId, visitorId) {

            const req = {
                method: 'DELETE',
                url: `/api/fsb_sub_date/${fsbSubmissionDateId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http (req);


        },

        addSubmissionOnDate: function (submission_date_id, submission_date,
                                       extension_granted, firebaseTokenId, visitorId) {

            const req = {
                method: 'POST',
                url: '/api/fsb_sub_add',
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    submission_date_id: submission_date_id,
                    submission_date: submission_date,
                    extension_granted: extension_granted
                }
            };

            return $http (req);

        },

        getSubmissionRemindersEmailList: function (firebaseTokenId, visitorId) {

            var req = {
                method: 'GET',
                url: `/api/fsca_submission_reminder_email_template/list`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http (req);

        },


    }

});
