angular.module('ics')
    .controller('RiskMatricesAdminController', function ($scope,
                                                         $state,
                                                         $controller,
                                                         $timeout,
                                                         $q,
                                                         FSPListsService,
                                                         CategoriesService,
                                                         FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);

        // operational risk variables
        $scope.operationalRiskCategories = [];
        $scope.operationalRiskItems = [];
        $scope.operationalRiskItemCategory = {};
        $scope.operationalRiskItem = {
            id: 0,
            item_category: '',
            relevant: false,
            implementation: false,
            third_party: ''
        };

        // regulatory risk variables
        $scope.regulatoryRiskCategories = [];
        $scope.regulatoryRiskItems = [];
        $scope.regulatoryRiskItemCategory = {};
        $scope.regulatoryRiskItem = {
            id: 0,
            item_category: '',
            relevant: false,
            implementation: false,
            third_party: ''
        };

        // market risk variables
        $scope.marketRiskCategories = [];
        $scope.marketRiskItems = [];
        $scope.marketRiskItemCategory = {};
        $scope.marketRiskItem = {
            id: 0,
            item_category: '',
            relevant: false,
            implementation: false,
            third_party: ''
        };

        $scope.tokenId = '';

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            $scope.init();
        });

        $scope.init = function () {

            $scope.processing = true;
            retrieveOperationalRiskData()
                .then(retrieveOperationalRiskCategories)
                .then(retrieveRegulatoryRiskData)
                .then(retrieveRegulatoryRiskCategories)
                .then(retrieveMarketRiskData)
                .then(retrieveMarketRiskCategories)
                .then(() => {

                    resetOperationalRisk();
                    resetRegulatoryRisk();
                    resetMarketRisk();

                    $('select').material_select();
                    $scope.processing = false;
                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.goBack = function () {

            if ($scope.editingRegulatory) {
                resetRegulatoryRisk();
            } else if ($scope.editingOperational) {
                resetOperationalRisk();
            } else if ($scope.editingMarket) {
                resetMarketRisk();
            } else {
                $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
            }

        };

        // ****** O P E R A T I O N A L  R I S K  F U N C T I O N S ******

        function retrieveOperationalRiskData () {

            return $q((resolve) => {

                FSPListsService
                    .getOperationalRiskMatrixList($scope.tokenId, $scope.fspId)
                    .then(response => {

                        $scope.operationalRiskItems = response.data.items;

                        $scope.operationalRiskItems.sort((a, b) => {
                            let textA = a.item_category.name.toUpperCase();
                            let textB = b.item_category.name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });

                        resolve();

                    })
                    .catch(err => reject(err));

            });
        }

        function retrieveOperationalRiskCategories () {

            return $q((resolve) => {

                CategoriesService.getCategoriesListFromCode('fsp_risk_mgt', $scope.tokenId, $scope.visitorId).then(res => {
                    $scope.operationalRiskCategories = res.data.categories;
                    $('select').material_select();
                    resolve();
                });
            });
        }

        $scope.submitRiskMgtForm = function ($form) {
            if ($form.$valid) {
                $scope.saveOperationalRiskItem(
                    $scope.operationalRiskItem.id,
                    $scope.operationalRiskItem.item_category,
                    $scope.operationalRiskItem.relevant,
                    $scope.operationalRiskItem.implementation,
                    $scope.operationalRiskItem.third_party);
            }
        };

        $scope.saveOperationalRiskItem = function (id,
                                                   item_category,
                                                   relevant,
                                                   implementation,
                                                   third_party) {

            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.operationalRiskItem.id === 0) {
                FSPListsService
                    .createOperationalRiskMatrixItem($scope.fspId,
                        item_category,
                        !!relevant,
                        !!implementation,
                        third_party,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            } else {
                FSPListsService
                    .updateOperationalRiskMatrixItem(id,
                        $scope.fspId,
                        item_category,
                        !!relevant,
                        !!implementation,
                        third_party,
                        $scope.tokenId, $scope.visitorId).then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteOperationalRiskItem = function (id, index) {

            $('#modalConfirmHeader').html('DELETING ITEM');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteItemCallback = function () {

                FSPListsService
                    .deleteOperationalRiskMatrixItem(this.id, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            $('#modalAgreeLink').click(function () {

                deleteItemCallback.bind({id: id})();

                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.editOperationalRiskItem = function (id, index) {
            // $('body').removeClass('bg-grey-1');
            $scope.operationalRiskItem = {
                id: $scope.operationalRiskItems[index].id,
                item_category: $scope.operationalRiskItems[index].item_category.id,
                relevant: !!$scope.operationalRiskItems[index].relevant,
                implementation: $scope.operationalRiskItems[index].implementation,
                third_party: $scope.operationalRiskItems[index].third_party
            };

            $timeout(function () {
                $('select').material_select();
            });

            $scope.editingOperational = true;
        };

        $scope.addOperationalRiskItem = function () {
            // $('body').removeClass('bg-grey-1');
            $scope.operationalRiskItem = {
                id: 0,
                item_category: '',
                relevant: false,
                implementation: false,
                third_party: ''
            };
            $('select').material_select();
            $scope.editingOperational = true;
            $scope.errorMessage = '';
        };

        $scope.cancelOperationalRiskSaveItem = function () {
            // $('body').addClass('bg-grey-1');
            resetOperationalRisk();
        };

        function resetOperationalRisk () {
            $scope.processing = false;
            $scope.operationalRiskItem = {
                id: 0,
                item_category: '',
                relevant: false,
                implementation: false,
                third_party: ''
            };
            $scope.editingOperational = false;
            $scope.errorMessage = '';
        }


        // ****** R E G U L A T O R Y  R I S K  F U N C T I O N S ******


        function retrieveRegulatoryRiskData () {

            return $q((resolve) => {

                FSPListsService.getRegRiskMatrixList($scope.tokenId,
                    $scope.fspId).then((response) => {
                    $scope.regulatoryRiskItems = response.data.items;

                    $scope.regulatoryRiskItems.sort((a, b) => {
                        let textA = a.item_category.name.toUpperCase();
                        let textB = b.item_category.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });

                    resolve();

                });
            });
        }


        function retrieveRegulatoryRiskCategories () {

            return $q((resolve) => {

                CategoriesService.getCategoriesListFromCode('reg_risk_acts', $scope.tokenId, $scope.visitorId).then(res => {
                    $scope.regRiskCategories = res.data.categories;
                    $timeout(function () {
                        $('select').material_select();
                    });
                    resolve();
                });
            });
        }

        $scope.submitRegulatoryRiskForm = function ($form) {

            if ($form.$valid) {
                $scope.saveRegulatoryRiskItem($scope.regulatoryRiskItem.id,
                    $scope.regulatoryRiskItem.item_category,
                    $scope.regulatoryRiskItem.relevant,
                    $scope.regulatoryRiskItem.responsible_party,
                    $scope.regulatoryRiskItem.risk_of_non_compliance,
                    $scope.regulatoryRiskItem.impact_of_non_compliance)
            }

        };

        $scope.saveRegulatoryRiskItem = function (id,
                                                  item_category,
                                                  relevant,
                                                  responsible_party,
                                                  risk_of_non_compliance,
                                                  impact_of_non_compliance) {

            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.regulatoryRiskItem.id === 0) {
                FSPListsService.createRegRiskMatrixItem($scope.fspId,
                    item_category,
                    !!relevant,
                    responsible_party,
                    risk_of_non_compliance,
                    impact_of_non_compliance,
                    $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));
            } else {
                FSPListsService.updateRegRiskMatrixItem(id,
                    $scope.fspId,
                    item_category,
                    !!relevant,
                    responsible_party,
                    risk_of_non_compliance,
                    impact_of_non_compliance,
                    $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteRegulatoryRiskItem = function (id, index) {

            $('#modalConfirmHeader').html('DELETING ITEM');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteItemCallback = function () {

                FSPListsService
                    .deleteRegRiskMatrixItem(this.id, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            $('#modalAgreeLink').click(function () {

                deleteItemCallback.bind({id: id})();

                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.editRegulatoryRiskItem = function (id, index) {
            // $('body').removeClass('bg-grey-1');
            $scope.regulatoryRiskItem = {
                id: $scope.regulatoryRiskItems[index].id,
                item_category: $scope.regulatoryRiskItems[index].item_category.id,
                relevant: !!$scope.regulatoryRiskItems[index].relevant,
                responsible_party: $scope.regulatoryRiskItems[index].responsible_party,
                risk_of_non_compliance: $scope.regulatoryRiskItems[index].risk_of_non_compliance,
                impact_of_non_compliance: $scope.regulatoryRiskItems[index].impact_of_non_compliance
            };

            $timeout(function () {
                $('select').material_select();
            });

            $scope.editingRegulatory = true;
        };

        $scope.addRegulatoryRiskItem = function () {
            // $('body').removeClass('bg-grey-1');
            $scope.regulatoryRiskItem = {
                id: 0,
                item_category: '',
                relevant: false,
                responsible_party: '',
                risk_of_non_compliance: '',
                impact_of_non_compliance: ''
            };

            $scope.editingRegulatory = true;
            $scope.errorMessage = '';
        };

        $scope.cancelRegulatoryRiskSaveItem = function () {
            // $('body').addClass('bg-grey-1');
            resetRegulatoryRisk();
        };

        function resetRegulatoryRisk () {
            $scope.processing = false;
            $scope.regulatoryRiskItem = {
                id: 0,
                item_category: '',
                relevant: false,
                responsible_party: '',
                risk_of_non_compliance: '',
                impact_of_non_compliance: ''
            };
            $scope.editingRegulatory = false;
            $scope.errorMessage = '';
        }


        // ****** M A R K E T  R I S K  F U N C T I O N S ******

        function retrieveMarketRiskData () {

            return $q((resolve) => {

                FSPListsService.getMarketRiskMatrixList($scope.tokenId,
                    $scope.fspId).then((response) => {
                    $scope.marketRiskItems = response.data.items;

                    $scope.marketRiskItems.sort((a, b) => {
                        let textA = a.item_category.name.toUpperCase();
                        let textB = b.item_category.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });

                    resolve();


                });
            });
        }

        function retrieveMarketRiskCategories () {

            return $q((resolve) => {

                CategoriesService.getCategoriesListFromCode('market_rist_mgt', $scope.tokenId, $scope.visitorId).then(res => {
                    $scope.marketRiskCategories = res.data.categories;
                    $timeout(function () {
                        $('select').material_select();
                    });
                });

                resolve();

            });

        }

        $scope.submitMktRiskForm = function ($form) {
            if ($form.$valid) {
                saveMarketRiskItem($scope.marketRiskItem.id,
                    $scope.marketRiskItem.item_category,
                    $scope.marketRiskItem.relevant,
                    $scope.marketRiskItem.implementation,
                    $scope.marketRiskItem.third_party);
            }
        };

        $scope.saveMarketRiskItem = function (id,
                                              item_category,
                                              relevant,
                                              implementation,
                                              third_party) {

            $scope.errorMessage = '';
            $scope.processing = true;

            if ($scope.marketRiskItem.id === 0) {
                FSPListsService
                    .createMarketRiskMatrixItem($scope.fspId,
                        item_category,
                        !!relevant,
                        !!implementation,
                        third_party,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));
            } else {
                FSPListsService
                    .updateMarketRiskMatrixItem(id,
                        $scope.fspId,
                        item_category,
                        !!relevant,
                        !!implementation,
                        third_party,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteMarketRiskItem = function (id, index) {

            $('#modalConfirmHeader').html('DELETING ITEM');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteItemCallback = function () {

                FSPListsService
                    .deleteMarketRiskMatrixItem(this.id, $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));

            };

            $('#modalAgreeLink').click(function () {

                deleteItemCallback.bind({id: id})();

                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.editMarketRiskItem = function (id, index) {
            // $('body').removeClass('bg-grey-1');
            $scope.marketRiskItem = {
                id: $scope.marketRiskItems[index].id,
                item_category: $scope.marketRiskItems[index].item_category.id,
                relevant: !!$scope.marketRiskItems[index].relevant,
                implementation: $scope.marketRiskItems[index].implementation,
                third_party: $scope.marketRiskItems[index].third_party
            };

            $timeout(function () {
                $('select').material_select();
            });

            $scope.editingMarket = true;
        };

        $scope.addMarketRiskItem = function () {
            // $('body').removeClass('bg-grey-1');
            $scope.marketRiskItem = {
                id: 0,
                item_category: '',
                relevant: false,
                implementation: false,
                third_party: ''
            };
            $scope.editingMarket = true;
            $scope.errorMessage = '';
        };

        $scope.cancelMarketRiskSaveItem = function () {
            // $('body').addClass('bg-grey-1');
            resetMarketRisk();
        };

        function resetMarketRisk () {
            $scope.processing = false;
            $scope.marketRiskItem = {
                id: 0,
                item_category: '',
                relevant: false,
                implementation: false,
                third_party: ''
            };
            $scope.editingMarket = false;
            $scope.errorMessage = '';
        }
    });
