angular.module('ics')
    .controller('RegRiskMatrixAdminController', function ($scope,
                                                          $state,
                                                          $controller,
                                                          $timeout,
                                                          FSPListsService,
                                                          CategoriesService,
                                                          FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);

        $scope.regRiskCategories = [];
        $scope.items = [];

        $scope.itemCategory = {};

        $scope.item = {
            id: 0,
            item_category: '',
            relevant: false,
            responsible_party: '',
            risk_of_non_compliance: '',
            impact_of_non_compliance: ''
        };

        $scope.errorMessage = '';
        $scope.tokenId = '';

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            $scope.init();
        });

        $scope.init = function () {

            $scope.processing = true;

            FSPListsService
                .getRegRiskMatrixList($scope.tokenId, $scope.fspId)
                .then((response) => {
                    $scope.items = response.data.items;

                    $scope.items.sort((a, b) => {
                        let textA = a.item_category.name.toUpperCase();
                        let textB = b.item_category.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    reset();
                })
                .then(() => {

                    CategoriesService.getCategoriesListFromCode('reg_risk_acts', $scope.tokenId, $scope.visitorId)
                        .then(res => {
                            $scope.regRiskCategories = res.data.categories;
                            $timeout(function () {
                                $('select').material_select();
                            });
                        })
                        .catch(err => $scope.checkErrStatus(err));

                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.saveItem = function (id,
                                    item_category,
                                    relevant,
                                    responsible_party,
                                    risk_of_non_compliance,
                                    impact_of_non_compliance) {

            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.item.id === 0) {
                FSPListsService
                    .createRegRiskMatrixItem($scope.fspId,
                        item_category,
                        !!relevant,
                        responsible_party,
                        risk_of_non_compliance,
                        impact_of_non_compliance,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            } else {
                FSPListsService
                    .updateRegRiskMatrixItem(id,
                        $scope.fspId,
                        item_category,
                        !!relevant,
                        responsible_party,
                        risk_of_non_compliance,
                        impact_of_non_compliance,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteItem = function (id, index) {

            $('#modalConfirmHeader').html('DELETING ITEM');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteItemCallback = function () {

                FSPListsService.deleteRegRiskMatrixItem(this.id, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            $('#modalAgreeLink').click(function () {

                deleteItemCallback.bind({id: id})();

                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.editItem = function (id, index) {
            $('body').removeClass('bg-grey-1');
            $scope.item = {
                id: $scope.items[index].id,
                item_category: $scope.items[index].item_category.id,
                relevant: !!$scope.items[index].relevant,
                responsible_party: $scope.items[index].responsible,
                risk_of_non_compliance: $scope.items[index].risk_of_non_compliance,
                impact_of_non_compliance: $scope.items[index].impact_of_non_compliance
            };

            $timeout(function () {
                $('select').material_select();
            });

            $scope.editing = true;
        };

        $scope.addItem = function () {
            $('body').removeClass('bg-grey-1');
            $scope.item = {
                id: 0,
                item_category: '',
                relevant: false,
                responsible_party: '',
                risk_of_non_compliance: '',
                impact_of_non_compliance: ''
            };

            $scope.editing = true;
            $scope.errorMessage = '';
        };

        $scope.cancelSaveItem = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset() {
            $scope.processing = false;
            $scope.item = {
                id: 0,
                item_category: '',
                relevant: false,
                responsible_party: '',
                risk_of_non_compliance: '',
                impact_of_non_compliance: ''
            };
            $scope.editing = false;
            $scope.errorMessage = '';
        }

    });
