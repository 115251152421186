angular.module('ics')
    .controller('FSPReportGenerateController', function ($scope, $state, $controller, $timeout,
                                                         FSPService, FirebaseStorageService, FSPReportsService,
                                                         FSPContactPersonsService, ReportEmailTemplatesService, FirebaseAuthService) {

        $scope.processing = true;
        $scope.selectAllItems = false;
        $scope.email_template = null;
        $scope.subject = 'Your compliance report';
        $scope.body = `Dear {fsp_contact}

Attached is the {fsp_name} compliance report to be discussed at the upcoming compliance review meeting.

If you have any specific questions that relate to the report please contact us on ics@complianceservices.co.za

Regards,
{user_name}`;

        $scope.cc = '';
        $scope.to = '';

        // if (typeof(Storage) !== "undefined") {
        //     if (localStorage.getItem("ics_report_email_subject")) $scope.subject = localStorage.getItem("ics_report_email_subject");
        //     if (localStorage.getItem("ics_report_email_body")) $scope.body = localStorage.getItem("ics_report_email_body");
        // }

        $scope.includeFinProds = false;
        $scope.reportDate = new Date();

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken()
            .then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.init();
            }).catch(err => $scope.checkErrStatus(err));

        $scope.viewFSPDetails = function ($event, fspId) {
            $event.preventDefault();
            $state.transitionTo('admin-fsp-details', {fsp_id: fspId});
        };

        $scope.init = function () {

            $scope.processing = true;

            FSPService.GetFSPList($scope.tokenId, $scope.visitorId)
                .then((response) => {
                    $scope.fspList = response.data.fsps;
                    $scope.processing = false;
            }).then(async () => {

                const resp = await ReportEmailTemplatesService.getReportEmailTemplatesList($scope.tokenId, $scope.visitorId);
                $scope.emailTemplates = resp.data.report_email_templates;

                $timeout(function () {
                    $('select').material_select();
                    $('textarea').trigger('autoresize');
                });

                const resp2 = await FSPContactPersonsService.getContactPersonEmailRecipients($scope.tokenId, $scope.visitorId)
                const contactPersons = resp2.data.contact_persons;
                $scope.fspList.forEach(fsp => {
                    fsp.selected = false;
                    fsp.contactPersons = contactPersons.filter(person => person.fsp_id == fsp.id);
                });

            }).catch(err => $scope.checkErrStatus(err));
        };

        $scope.previewReport = function ($event, fspId) {
            $event.preventDefault();
            FirebaseAuthService.getUserToken()
                .then(tokenId => {
                    $scope.tokenId = tokenId;
                    $scope.processing = true;
                    FSPReportsService.previewReport(fspId, $scope.includeFinProds, moment($scope.reportDate).format('YYYY-MM-DD'), $scope.tokenId, $scope.visitorId)
                        .then(response => {
                            $state.transitionTo('fsp-report', {fsp_report_id: response.data.report.id});
                        })
                        .catch(err => $scope.checkErrStatus(err));
                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.sendReport = function ($event, fspId) {

            $event.preventDefault();
            $scope.fspList.forEach(fsp => {
                fsp.selected = parseInt(fsp.id, 10) === parseInt(fspId);
            });
            $scope.selectAllItems = false;
            $scope.generateReport();
        };

        $scope.selectEmailTemplate = function () {
            $scope.emailTemplates.forEach(template => {
                if (template.id == $scope.email_template) {
                    $scope.subject = template.email_subject;
                    $scope.body = template.email_body;
                    setTimeout(() => $scope.$applyAsync(), 0);
                }
            });
        };

        $scope.selectAll = function () {
            $scope.fspList.forEach(fsp => {
                if (fsp.contactPersons.length > 0) {
                    fsp.selected = !$scope.selectAllItems
                } else {
                    fsp.selected = false;
                }

            });
            $scope.selectAllItems = !$scope.selectAllItems;
        };

        $scope.generateReport = function () {
            if ($scope.subject.trim().length === 0 || $scope.body.trim().length === 0) return;
            let idList = $scope.fspList.filter(fsp => fsp.selected === true);
            if (!idList || idList.length === 0) {
                $('#modalInfoHeader').html('ERROR');
                $('#modalInfoText').html('Please select at least one service provider.');
                $('#information_modal').modal('open');
                setTimeout(function () {
                    $('#information_modal').modal('close');
                }, 2000);
                return;
            }

            idList = idList.map(fsp => {
                return {
                    id: fsp.id
                };
            });

            let cc = $scope.cc.split(',');
            cc = (cc || []).map(item => item.trim()).join(',');

            let to = $scope.to.split(',');
            to = (to || []).map(item => item.trim()).join(',');

            localStorage.setItem("ics_report_email_subject", $scope.subject.trim());
            localStorage.setItem("ics_report_email_body", $scope.body.trim());

            FirebaseAuthService.getUserToken()
                .then(tokenId => {
                    $scope.tokenId = tokenId;
                    $scope.processing = true;
                    FSPReportsService
                        .generateReport(idList, $scope.subject.trim(),
                            $scope.body.trim(), $scope.includeFinProds,
                            cc || '', to || '',
                            moment($scope.reportDate).format('YYYY-MM-DD'),
                            $scope.tokenId, $scope.visitorId)
                        .then(response => {
                            console.log(response);
                            $scope.processing = false;
                            $scope.fspList.forEach(fsp => fsp.selected = false);
                            $scope.selectAllItems = false;
                            $('#modalInfoHeader').html('THANK YOU');
                            $('#modalInfoText').html('Your reports are being generated and sent.');
                            $('#information_modal').modal('open');
                            setTimeout(function () {
                                $('#information_modal').modal('close');
                            }, 2000);
                            return;
                        })
                        .catch(err => $scope.checkErrStatus(err));
                })
                .catch(err => $scope.checkErrStatus(err));

        }

    });