angular.module('ics')
    .controller('MarketRiskMatrixAdminController', function ($scope,
                                                             $state,
                                                             $controller,
                                                             $timeout,
                                                             FSPListsService,
                                                             CategoriesService,
                                                             FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);

        $scope.mktRiskCategories = [];
        $scope.items = [];

        $scope.itemCategory = {};

        $scope.item = {
            id: 0,
            item_category: '',
            relevant: false,
            implementation: false,
            third_party: ''
        };

        $scope.errorMessage = '';
        $scope.tokenId = '';

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            $scope.init();
        });

        $scope.init = function () {

            $scope.processing = true;

            FSPListsService.getMarketRiskMatrixList($scope.tokenId,
                $scope.fspId).then((response) => {
                $scope.items = response.data.items;

                $scope.items.sort((a, b) => {
                    let textA = a.item_category.name.toUpperCase();
                    let textB = b.item_category.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                reset();
            }).then(() => {

                CategoriesService.getCategoriesListFromCode('market_rist_mgt', $scope.tokenId, $scope.visitorId).then(res => {
                    $scope.mktRiskCategories = res.data.categories;
                    $timeout(function () {
                        $('select').material_select();
                    });
                }, reject => {
                    if (reject.status === 403) {
                        $state.transitionTo('signin');
                    }
                });

            }).catch(err => $scope.checkErrStatus(err));
        };

        $scope.saveItem = function (id,
                                    item_category,
                                    relevant,
                                    implementation,
                                    third_party) {

            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.item.id === 0) {

                FSPListsService.createMarketRiskMatrixItem($scope.fspId,
                    item_category,
                    !!relevant,
                    !!implementation,
                    third_party,
                    $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            } else {

                FSPListsService.updateMarketRiskMatrixItem(id,
                    $scope.fspId,
                    item_category,
                    !!relevant,
                    !!implementation,
                    third_party,
                    $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteItem = function (id, index) {

            $('#modalConfirmHeader').html('DELETING ITEM');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteItemCallback = function () {

                FSPListsService
                    .deleteMarketRiskMatrixItem(this.id, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            };

            $('#modalAgreeLink').click(function () {
                deleteItemCallback.bind({id: id})();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.editItem = function (id, index) {
            $('body').removeClass('bg-grey-1');
            $scope.item = {
                id: $scope.items[index].id,
                item_category: $scope.items[index].item_category.id,
                relevant: !!$scope.items[index].relevant,
                implementation: $scope.items[index].implementation,
                third_party: $scope.items[index].third_party
            };

            $timeout(function () {
                $('select').material_select();
            });

            $scope.editing = true;
        };

        $scope.addItem = function () {
            $('body').removeClass('bg-grey-1');
            $scope.item = {
                id: 0,
                item_category: '',
                relevant: false,
                implementation: false,
                third_party: ''
            };
            $scope.editing = true;
            $scope.errorMessage = '';
        };

        $scope.cancelSaveItem = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset() {
            $scope.processing = false;
            $scope.item = {
                id: 0,
                item_category: '',
                relevant: false,
                implementation: false,
                third_party: ''
            };
            $scope.editing = false;
            $scope.errorMessage = '';
        }

    });
