angular.module('ics')

    .factory('CategoryTypesService', function ($http) {

        return {

            getCategoryTypesList: function (firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: '/api/category_type/list',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },

            createCategoryType: function (categoryType, code, firebaseTokenId, visitorId) {
                var req = {
                    method: 'POST',
                    url: '/api/category_type/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'category_type': categoryType,
                        'code': code
                    }
                };

                return $http(req);
            },

            editCategoryType: function (categoryTypeId, categoryType, code, firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/category_type/${categoryTypeId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'category_type': categoryType,
                        'code': code
                    }
                };

                return $http(req);
            },

            deleteCategoryType: function (categoryTypeId, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/category_type/${categoryTypeId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            }
        }

    });
