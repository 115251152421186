'use strict';

let homeRoute = '/';
let signinRoute = '/signin';
let adminDashboardRoute = '/admin-dashboard';
let adminRegistrationRoute = '/admin-registration';

let calendarRoute = '/calendar';
let adminCategoryTypes = '/admin/category_types';
let adminCategories = '/admin/categories/:category_type_id/:category_type_name';
let adminSubCategories = '/admin/sub_categories/:category_id/:category_name/:category_type_id/:category_type_name';

let adminComplianceDocumentTypes = '/admin/compliance_document_types';
let adminComplianceDocumentTemplates = '/admin/compliance_document_templates/:document_type_id/:document_type_name';

let adminFSPList = '/admin/fsp_list';
let adminFSPReportGenerate = '/admin/fsp_report_generate';
let adminFSPReportHistory = '/admin/fsp_report_history/:fsp_id/:fsp_name';
let adminFSPDetails = '/admin/fsp_list/:fsp_id';
let adminFSPProfileChange = '/admin/fsp_profile_change/:fsp_id';

//FSP SETTINGS
let adminFSPComplianceDocuments = '/admin/compliance_documents/:fsp_id/:fsp_name';
let adminFSPRiskProfile = '/admin/risk_profile/:fsp_id/:fsp_name';
let adminFSPClients = '/admin/clients/:fsp_id/:fsp_name';
let adminFSPShareholders = '/admin/shareholders/:fsp_id/:fsp_name';
let adminFSPDirectors = '/admin/directors/:fsp_id/:fsp_name';
let adminFSPComplianceBreaches = '/admin/compliance_breaches/:fsp_id/:fsp_name';
let adminFSPKeyIndividuals = '/admin/key_individuals/:fsp_id/:fsp_name';
let adminFSPAuditors = '/admin/auditors/:fsp_id/:fsp_name';
let adminFSPThirdPartyAdministrators = '/admin/third_party_administrators/:fsp_id/:fsp_name';
let adminFSPThirdPartyProviders = '/admin/third_party_providers/:fsp_id/:fsp_name';
let adminFSPThirdPartyLISPs = '/admin/third_party_lisps/:fsp_id/:fsp_name';
let adminFSPContactPersons = '/admin/contact_persons/:fsp_id/:fsp_name';
let adminFSPRepresentatives = '/admin/representatives/:fsp_id/:fsp_name';
let adminFSPScopeOfLicensing = '/admin/scope_of_licensing/:fsp_id/:fsp_name';
let adminFSBSubmissionDates = '/admin/submission_dates/:fsp_id/:fsp_name';

let fspReport = '/report/:fsp_report_id';

//LISTS
let adminRiskMatrices = '/admin/risk_matrices/:fsp_id/:fsp_name';
let adminRegRiskMatrix = '/admin/regulatory_risk_matrix/:fsp_id/:fsp_name';
let adminMktRiskMatrix = '/admin/market_risk_matrix/:fsp_id/:fsp_name';
let adminOperationalRiskMatrix = '/admin/operational_risk_matrix/:fsp_id/:fsp_name';
let adminExternalAuditorsList = '/admin/external_auditors/:fsp_id/:fsp_name';
let adminExternalAuditorDetails = '/admin/external_auditors/item/:fsp_id/:fsp_name/:item_id';

let adminFSPProfileChangeDetails = '/admin/fsp_profile_change_details/:profile_change_id/:fsp_id';
let adminFSPFollowUpProfileChange = '/admin/fsp_follow_up_profile_change/:profile_change_id/:fsp_id';
let adminFSBSubmitProfileChange = '/admin/fsb_submit_profile_change/:profile_change_id/:fsp_id';

let adminFSPDocuments = '/admin/fsp_documents/:fsp_id/:category_type_code/:document_type_title';
let adminFSPDocumentTree = '/admin/fsp_document_tree/:fsp_id/:fsp_name';

let adminEmailTemplates = '/admin/email_templates';
let adminFollowUpEmailTemplates = '/admin/follow_up_email_templates';
let adminFSCASubmissionReminderEmailTemplates = '/admin/fsca_submission_reminder_email_templates';
let adminFSBEmailTemplates = '/admin/fsb_email_templates';
let adminReportEmailTemplates = '/admin/report_email_templates';
let adminRecycleBin = '/admin/recycle_bin';
let authTwoFactorRoute = '/two_factor_auth'

var currentTab = localStorage.currentTab ? localStorage.currentTab : '';

// User Roles
const ROLE_SUPER_ADMIN = 'super_admin';
const ROLE_ADMIN = 'admin';
const ROLE_FSP = 'fsp';

angular.module('ics', ['firebase', 'ui.router', 'mwl.calendar'])

.config(['$locationProvider', function ($locationProvider) {
    $locationProvider.html5Mode(true);
}])

.config(function ($httpProvider) {
    $httpProvider.interceptors.push('responseObserver');
})

.config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider

    .state('home', {
        url: homeRoute,
        templateUrl: '/static/partials/home/home.html',
        controller: 'HomeController'
    })

    /* .state('signin', {
        url: signinRoute,
        templateUrl: '/static/partials/signin/signin.html',
        controller: 'AuthController'
    })*/

    .state('admin-dashboard', {
        url: adminDashboardRoute,
        templateUrl: '/static/partials/admin/dashboard.html',
        controller: 'AdminDashboardController'
    })

    .state('admin-registration', {
        url: adminRegistrationRoute,
        templateUrl: '/static/partials/admin/admin_registration.html',
        controller: 'AdminRegistrationController'
    })

    .state('admin-category-types', {
        url: adminCategoryTypes,
        templateUrl: '/static/partials/admin/category_types.html',
        controller: 'CategoryTypesAdminController'
    })

    .state('admin-recycle-bin', {
        url: adminRecycleBin,
        templateUrl: '/static/partials/admin/recycle_bin.html',
        controller: 'RecycleBinController'
    })

    .state('calendar', {
        url: calendarRoute,
        templateUrl: '/static/partials/admin/calendar.html',
        controller: 'CalendarController'
    })

    .state('admin-categories', {
        url: adminCategories,
        templateUrl: '/static/partials/admin/categories.html',
        controller: 'CategoriesAdminController'
    })

    .state('admin-sub-categories', {
        url: adminSubCategories,
        templateUrl: '/static/partials/admin/sub_categories.html',
        controller: 'SubCategoriesAdminController'
    })

    .state('admin-compliance-document-types', {
        url: adminComplianceDocumentTypes,
        templateUrl: '/static/partials/admin/compliance_document_types.html',
        controller: 'ComplianceDocumentTypesAdminController'
    })

    .state('admin-compliance-templates', {
        url: adminComplianceDocumentTemplates,
        templateUrl: '/static/partials/admin/compliance_document_templates.html',
        controller: 'ComplianceDocTemplatesAdminController'
    })

    .state('admin-email-templates', {
        url: adminEmailTemplates,
        templateUrl: '/static/partials/admin/email_templates.html',
        controller: 'EmailTemplatesAdminController'
    })

    .state('admin-follow-up-email-templates', {
        url: adminFollowUpEmailTemplates,
        templateUrl: '/static/partials/admin/follow_up_email_templates.html',
        controller: 'FollowUpEmailTemplatesAdminController'
    })

    .state('admin-fsca-submission-reminder-email-templates', {
        url: adminFSCASubmissionReminderEmailTemplates,
        templateUrl: '/static/partials/admin/fsca_submission_reminder_email_templates.html',
        controller: 'FCSASubmissionReminderEmailTemplatesAdminController'
    })

    .state('admin-fsb-email-templates', {
        url: adminFSBEmailTemplates,
        templateUrl: '/static/partials/admin/fsb_email_templates.html',
        controller: 'FSBEmailTemplatesAdminController'
    })

    .state('admin-report-email-templates', {
        url: adminReportEmailTemplates,
        templateUrl: '/static/partials/admin/report_email_templates.html',
        controller: 'ReportEmailTemplatesAdminController'
    })

    .state('admin-fsp-list', {
        url: adminFSPList,
        templateUrl: '/static/partials/admin/financial_service_providers.html',
        controller: 'FSPController'
    })

    .state('admin-fsp-report-generate', {
        url: adminFSPReportGenerate,
        templateUrl: '/static/partials/admin/fsp_report_generate.html',
        controller: 'FSPReportGenerateController'
    })

    .state('admin-fsp-report-history', {
        url: adminFSPReportHistory,
        templateUrl: '/static/partials/admin/fsp_report_history.html',
        controller: 'FSPReportHistoryController'
    })

    .state('admin-fsp-details', {
        url: adminFSPDetails,
        templateUrl: '/static/partials/admin/financial_service_provider_details.html',
        controller: 'FSPDetailsController'
    })

    .state('admin-fsp-profile-change', {
        url: adminFSPProfileChange,
        templateUrl: '/static/partials/admin/fsp_profile_change.html',
        controller: 'FSPProfileChangeController'
    })

    .state('admin-fsp-profile-change-details', {
        url: adminFSPProfileChangeDetails,
        templateUrl: '/static/partials/admin/fsp_profile_change_details.html',
        controller: 'FSPProfileChangeDetailsController'
    })

    .state('admin-fsp-follow-up-profile-change', {
        url: adminFSPFollowUpProfileChange,
        templateUrl: '/static/partials/admin/fsp_follow_up_profile_change.html',
        controller: 'FSPFollowUpProfileChangeController'
    })

    .state('admin-fbp-submit-profile-change', {
        url: adminFSBSubmitProfileChange,
        templateUrl: '/static/partials/admin/fsb_submit_profile_change.html',
        controller: 'SubmitProfileChangeController'
    })

    .state('admin-fsp-document-tree', {
        url: adminFSPDocumentTree,
        templateUrl: '/static/partials/admin/fsp_document_tree.html',
        controller: 'FSPDocumentTreeAdminController'
    })
    .state('admin-fsp-documents', {
        url: adminFSPDocuments,
        templateUrl: '/static/partials/admin/fsp_docs_list.html',
        controller: 'FSPDocsAdminController'
    })
    .state('admin-fsp-compliance-documents', {
        url: adminFSPComplianceDocuments,
        templateUrl: '/static/partials/admin/fsp_compliance_documents.html',
        controller: 'FSPComplianceDocumentsAdminController'
    })
    .state('admin-fsp-risk-profile', {
        url: adminFSPRiskProfile,
        templateUrl: '/static/partials/admin/fsp_risk_profile.html',
        controller: 'FSPRiskProfileAdminController'
    })
    .state('signin', {
        url: '/signin',
        templateUrl: '/static/partials/signin/signin.html',
        controller: 'AuthController'
    })

    .state('forgot-password', {
        url: '/forgot-password',
        templateUrl: '/static/partials/signin/forgot_password.html',
        controller: 'AuthController'
    })

    .state('fsp-team-member-sign-up', {
        url: '/fsp-member-sign-up/:token_id',
        templateUrl: '/static/partials/fsp/team_member_sign_up.html',
        controller: 'FSPTeamMemberSignUpController'
    })

    //SETTINGS


    .state('fsp-clients-list', {
        url: adminFSPClients,
        templateUrl: '/static/partials/admin/fsp_clients_list.html',
        controller: 'FSPClientsAdminController'
    })

    .state('fsp-shareholders-list', {
        url: adminFSPShareholders,
        templateUrl: '/static/partials/admin/fsp_shareholders_list.html',
        controller: 'FSPShareholdersAdminController'
    })

    .state('fsp-directors-list', {
        url: adminFSPDirectors,
        templateUrl: '/static/partials/admin/fsp_directors_list.html',
        controller: 'FSPDirectorsAdminController'
    })

    .state('fsp-compliance-breaches-list', {
        url: adminFSPComplianceBreaches,
        templateUrl: '/static/partials/admin/fsp_compliance_breaches_list.html',
        controller: 'FSPComplianceBreachesAdminController'
    })

    .state('fsp-report', {
        url: fspReport,
        templateUrl: '/static/partials/admin/fsp_report.html',
        controller: 'FSPReportController'
    })

    .state('fsp-key-individuals-list', {
        url: adminFSPKeyIndividuals,
        templateUrl: '/static/partials/admin/fsp_key_individuals_list.html',
        controller: 'FSPKeyIndividualsAdminController'
    })

    .state('fsp-auditors-list', {
        url: adminFSPAuditors,
        templateUrl: '/static/partials/admin/fsp_auditors_list.html',
        controller: 'FSPAuditorsAdminController'
    })

    .state('fsp-contact-persons-list', {
        url: adminFSPContactPersons,
        templateUrl: '/static/partials/admin/fsp_contact_persons_list.html',
        controller: 'FSPContactPersonsAdminController'
    })

    .state('fsp-representatives-list', {
        url: adminFSPRepresentatives,
        templateUrl: '/static/partials/admin/fsp_representatives_list.html',
        controller: 'FSPRepresentativesAdminController'
    })

    .state('fsb-submission-dates', {
        url: adminFSBSubmissionDates,
        templateUrl: '/static/partials/admin/fsb_submission_dates.html',
        controller: 'FSBSubmissionDatesAdminController'
    })

    .state('fsp-scope-of-licensing', {
        url: adminFSPScopeOfLicensing,
        templateUrl: '/static/partials/admin/fsp_scope_of_licensing.html',
        controller: 'FSPScopeOfLicensingAdminController'
    })

    .state('fsp-third-party-administrators-list', {
        url: adminFSPThirdPartyAdministrators,
        templateUrl: '/static/partials/admin/fsp_third_party_administrators_list.html',
        controller: 'FSPThirdPartyAdministratorsAdminController'
    })

    .state('fsp-third-party-providers-list', {
        url: adminFSPThirdPartyProviders,
        templateUrl: '/static/partials/admin/fsp_third_party_providers_list.html',
        controller: 'FSPThirdPartyProvidersAdminController'
    })

    .state('fsp-third-party-lisps-list', {
        url: adminFSPThirdPartyLISPs,
        templateUrl: '/static/partials/admin/fsp_third_party_lisps_list.html',
        controller: 'FSPThirdPartyLispsAdminController'
    })

    //LISTS

    .state('risk-matrices', {
        url: adminRiskMatrices,
        templateUrl: '/static/partials/admin/lists/risk_matrices.html',
        controller: 'RiskMatricesAdminController'
    })

    .state('reg-risk-matrix', {
        url: adminRegRiskMatrix,
        templateUrl: '/static/partials/admin/lists/regulatory_risk_matrix.html',
        controller: 'RegRiskMatrixAdminController'
    })

    .state('mkt-risk-matrix', {
        url: adminMktRiskMatrix,
        templateUrl: '/static/partials/admin/lists/market_risk_matrix.html',
        controller: 'MarketRiskMatrixAdminController'
    })

    .state('operational-risk-matrix', {
        url: adminOperationalRiskMatrix,
        templateUrl: '/static/partials/admin/lists/operational_risk_matrix.html',
        controller: 'OperationalRiskMatrixAdminController'
    })

    .state('external-auditor-list', {
        url: adminExternalAuditorsList,
        templateUrl: '/static/partials/admin/lists/external_auditor_list.html',
        controller: 'ExternalAuditorListAdminController'
    })

    .state('external-auditor-details', {
        url: adminExternalAuditorDetails,
        templateUrl: '/static/partials/admin/lists/external_auditor_details.html',
        controller: 'ExternalAuditorDetailsAdminController'
    })

    .state('two_factor_auth', {
        url: authTwoFactorRoute,
        templateUrl: '/static/partials/signin/two_factor_auth.html',
        controller: 'TwoFactorAuthController'
    });

    // catch all routes
    $urlRouterProvider.otherwise(homeRoute);

});
