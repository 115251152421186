angular.module('ics')

    .factory('FSPAuditorsService', function ($http) {

        return {

            getAuditorsList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_auditor_list/${fspId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            createAuditor: function (fspId,
                name,
                responsible_partner,
                contact_number,
                practice_number,
                // date_appointed,
                attachments,
                firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_auditor/',
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        name: name,
                        responsible_partner: responsible_partner,
                        contact_number: contact_number,
                        practice_number: practice_number,
                        // date_appointed: date_appointed,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            editAuditor: function (fspAuditorId,
                name,
                responsible_partner,
                contact_number,
                practice_number,
                // date_appointed,
                attachments,
                firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_auditor/${fspAuditorId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        name: name,
                        responsible_partner: responsible_partner,
                        contact_number: contact_number,
                        practice_number: practice_number,
                        // date_appointed: date_appointed,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            deleteAuditor: function (fspAuditorId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_auditor/${fspAuditorId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
