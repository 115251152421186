angular.module('ics')
    .controller('FSPScopeOfLicensingAdminController', function ($scope, $state, $controller,
                                                                $timeout, FSPScopeOfLicensingService,
                                                                CategoriesService, SubCategoriesService, FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);

        $scope.scope_of_licensing = {
            id: 0,
            category_i_applicable: false,
            category_ii_applicable: false,
            category_iia_applicable: false,
            scope_of_licensing_items: [],
            classes_of_business: [],
            sub_classes_of_business: [],
            attachments: []
        };

        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - scope of licensing`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $scope.init = async function () {

            $scope.tokenId = await FirebaseAuthService.getUserToken();
            await retrieveScopesOfLicensing();
            // Materialise Modal
            $('.modal').modal({
                opacity: .8,
                startingTop: '4%',
                endingTop: '25%'
            });
        };

        $scope.cat1Filter = function (item) {
            return item.category_code === 'Category I';
        };
        $scope.cat2Filter = function (item) {
            return item.category_code === 'Category II';
        };
        $scope.cat2aFilter = function (item) {
            return item.category_code === 'Category IIA';
        };

        async function retrieveScopesOfLicensing () {

            try {

                $scope.processing = true;
                $scope.editing = false;

                const res = await FSPScopeOfLicensingService
                    .getScopeOfLicensing($scope.fspId, $scope.tokenId, $scope.visitorId);

                if (res.data.fsp_scope_of_licensing && res.data.fsp_scope_of_licensing.id) {
                    $scope.scope_of_licensing = res.data.fsp_scope_of_licensing;
                }

                const res2 = await CategoriesService
                    .getCategoriesListFromCode('licencing_categories', $scope.tokenId, $scope.visitorId);

                $scope.licencingCategories = res2.data.categories.sort((a, b) => a.display_order - b.display_order);

                const res3 = await CategoriesService
                    .getCategoriesListFromCode('financial_products', $scope.tokenId, $scope.visitorId);

                $scope.licensingItems = [];
                $scope.financialProducts = res3.data.categories.sort((a, b) => a.display_order - b.display_order);

                // loop through each fin product
                $scope.financialProducts.forEach(product => {

                    // loop through each licensing category
                    $scope.licencingCategories.forEach(category => {

                        // financial instrument category must match licensing category
                        if (category.code.toUpperCase() === product.code.toUpperCase()) {

                            $scope.licensingItems.push({
                                category_id: category.id,
                                category_code: category.code,
                                category_name: category.name,
                                financial_product_id: product.id,
                                financial_product_name: product.name,
                                date_approved_by_fcsa: new Date(),
                                date_approved: new Date(),
                                advice: false,
                                intermediary: false,
                                discretion: false
                            });
                        }

                    });
                });

                if ($scope.scope_of_licensing.scope_of_licensing_items.length > 0) {

                    // update to reflect the FSP's existing scope of licensing if applicable
                    $scope.scope_of_licensing.scope_of_licensing_items.forEach(item => {
                        let foundItem = $scope.licensingItems.find(i => i.category_id == item.category_id && i.financial_product_id == item.financial_product_id);
                        if (foundItem) {
                            foundItem.date_approved_by_fcsa = new Date(item.date_approved_by_fcsa);
                            foundItem.date_approved = new Date(item.date_approved);
                            foundItem.advice = item.advice;
                            foundItem.intermediary = item.intermediary;
                            foundItem.discretion = item.discretion;
                        }
                    });
                }

                const res4 = await CategoriesService.getCategoriesListFromCode ('COB', $scope.tokenId, $scope.visitorId);
                $scope.classesOfBusiness = res4.data.categories;

                $scope.classesOfBusiness
                .sort ((a, b) => a.display_order - b.display_order)
                .forEach (item => {
                    item.selected = false;
                    item.subClasses = [];
                });

                for (const category of $scope.classesOfBusiness) {
                    const subCategoriesRes = await SubCategoriesService.getSubCategoriesList (category.id, $scope.tokenId, $scope.visitorId);
                    let subClassesOfBusiness = [];
                    subClassesOfBusiness = subClassesOfBusiness.concat (subCategoriesRes.data.sub_categories || []);
                    subClassesOfBusiness.sort ((a, b) => a.display_order - b.display_order).forEach (item => item.selected = false);
                    category.subClasses = subClassesOfBusiness || [];
                }

                ($scope.scope_of_licensing.classes_of_business || []).forEach (item => {
                    const classOfBusiness = $scope.classesOfBusiness.find (businessClass => businessClass.id === item.id);
                    if ( classOfBusiness ) {
                        classOfBusiness.selected = true;

                        ( $scope.scope_of_licensing.sub_classes_of_business || [] ).forEach (item => {

                            const subClassOfBusiness = classOfBusiness.subClasses.find (businessClass => businessClass.id === item.id);
                            if ( subClassOfBusiness ) {
                                subClassOfBusiness.selected = true;
                            }
                        });
                    }
                });

                $scope.processing = false;
                $scope.$applyAsync();

                setTimeout(function () {

                    $(document).ready(function () {

                        const docUploadControl = document.getElementById("documentUpload");

                        const fileDetails = {
                            folder: `/fsp_scope_of_licensing_attachments/${$scope.fspId}`,
                            attachments: $scope.scope_of_licensing.attachments
                        };

                        const eventDelegator = $scope.fileUploader.bind(fileDetails);
                        docUploadControl.addEventListener('change', eventDelegator);

                        $scope.$on('filesUploaded', function (event, attachments) {
                            const combined = new Set($scope.scope_of_licensing.attachments.concat(attachments));
                            $scope.scope_of_licensing.attachments = [...combined];
                            $scope.$apply();
                        });

                    })
                }, 1000);

                $scope.processing = false;

            } catch (err) {
                $scope.checkErrStatus(err);
            }

        }

        $scope.submitForm = function ($form) {

            if ($form.$valid) {
                $scope.saveItems();
            }

        };

        $scope.saveItems = async () => {

            try {

                let finalItems = $scope.licensingItems.filter(item => item.advice || item.intermediary || item.discretion);

                let error = false;
                finalItems.forEach(item => {
                    if (isNaN(item.date_approved.getTime())) {
                        item.date_approved = new Date();
                    }
                    if (isNaN(item.date_approved_by_fcsa.getTime())) {
                        item.date_approved_by_fcsa = new Date();
                    }
                    if (Math.abs(moment(item.date_approved).diff(moment(), 'years')) > 100) {
                        error = true;
                    }
                    if (Math.abs(moment(item.date_approved_by_fcsa).diff(moment(), 'years')) > 100) {
                        error = true;
                    }

                });

                if (error){
                    return alert('Please check your dates, 1 or more appear to be invalid.');
                }

                finalItems.forEach(item => {
                    item.date_approved = moment(item.date_approved).format('YYYY-MM-DD');
                    item.date_approved_by_fcsa = moment(item.date_approved_by_fcsa).format('YYYY-MM-DD');

                });

                $scope.processing = true;
                const selectedClassesOfBusiness = $scope.classesOfBusiness
                .filter (item => item.selected === true)
                .map (item => item.id);

                let selectedSubClassesOfBusiness = [];
                $scope.classesOfBusiness
                .filter (item => item.selected === true)
                .forEach (cob => {
                    selectedSubClassesOfBusiness = selectedSubClassesOfBusiness.concat (cob.subClasses
                    .filter (item => item.selected === true)
                    .map (item => item.id));
                });

                $scope.tokenId = await FirebaseAuthService.getUserToken();
                await FSPScopeOfLicensingService.postScopeOfLicensing($scope.fspId,
                    $scope.scope_of_licensing.category_i_applicable || false,
                    $scope.scope_of_licensing.category_ii_applicable || false,
                    $scope.scope_of_licensing.category_iia_applicable || false,
                    finalItems || [], $scope.scope_of_licensing.attachments,
                    selectedClassesOfBusiness,
                    selectedSubClassesOfBusiness,
                    $scope.tokenId, $scope.visitorId);
                $scope.processing = false;
                $('#modalInfoHeader').html('Thank you');
                $('#modalInfoText').html('The changes have been saved.');
                $('#information_modal').modal('open');

                setTimeout(function () {
                    $('#information_modal').modal('close');
                    $scope.goBack();
                }, 2000);

            } catch (e) {
                $scope.processing = false;
                $scope.checkErrStatus(e);
            }

        };

        $scope.init();

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer scope of licensing`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

    });
