angular.module ('ics')
.controller ('SubCategoriesAdminController', function ($scope, $state, $controller,
                                                       SubCategoriesService, FirebaseAuthService) {

    $scope.categoryId = '';
    $scope.categoryName = '';
    $scope.subCategoryName = '';
    $scope.subCategoryId = '';
    $scope.index = -1;
    $scope.code = '';
    $scope.editing = false;
    $scope.processing = true;

    angular.extend (this, $controller ('BaseAdminController', {$scope: $scope}));

    $scope.init = function () {

        $scope.processing = true;
        $scope.categoryId = $state.params.category_id;
        $scope.categoryName = $state.params.category_name;
        $scope.categoryTypeId = $state.params.category_type_id;
        $scope.categoryTypeName = $state.params.category_type_name;
        getSubCategoriesList ();

    };

    $scope.saveSubCategory = function (name, code, display_order) {

        $scope.errorMessage = '';
        $scope.processing = true;

        if ( isNaN (display_order) || !isFinite (display_order) ) {
            display_order = 1;
        }
        if ( $scope.subCategoryId === '' ) {

            FirebaseAuthService
            .getUserToken ()
            .then (tokenId => SubCategoriesService.createSubCategory ($scope.categoryId, name,
            code, display_order, tokenId, $scope.visitorId))
            .then (() => location.reload ())
            .catch (err => $scope.checkErrStatus (err));

        } else {

            FirebaseAuthService.getUserToken ()
            .then (tokenId => SubCategoriesService.editSubCategory ($scope.subCategoryId, name,
            code, display_order, tokenId, $scope.visitorId))
            .then (() => location.reload ())
            .catch (err => $scope.checkErrStatus (err));
        }
    };

    $scope.deleteSubCategory = function (subCategoryId, index) {

        $ ('#modalConfirmHeader').html ('Deleting Sub Category');
        $ ('#modalConfirmText').html ('Are you sure you want to delete this item?');

        const deleteType = function () {

            FirebaseAuthService.getUserToken ()
            .then (tokenId => SubCategoriesService.deleteSubCategory (this.subCategoryId, tokenId, $scope.visitorId))
            .then (() => location.reload ())
            .catch (err => $scope.checkErrStatus (err))

        };

        const deletionEvent = deleteType.bind ({subCategoryId: subCategoryId});

        $ (document).ready (function () {

            $ ('#modalAgreeLink').click (function () {
                deletionEvent ();
                $ ('#are_you_sure_modal').modal ('close');
            });

            $ ('#are_you_sure_modal').modal ('open');

        });

    };

    $scope.editSubCategory = function (subCategoryId, index) {
        $ ('body').removeClass ('bg-grey-1');
        $scope.subCategoryId = subCategoryId;
        $scope.index = index;
        $scope.subCategoryName = $scope.subCategoriesList[index].name;
        $scope.subCategoryCode = $scope.subCategoriesList[index].code || "";
        $scope.subCategoryOrder = $scope.subCategoriesList[index].display_order || 1;
        $scope.editing = true;
    };

    $scope.addSubCategory = function () {
        $ ('body').removeClass ('bg-grey-1');
        $scope.subCategoryId = '';
        $scope.subCategoryName = '';
        $scope.subCategoryCode = '';
        $scope.subCategoryOrder = 1;
        $scope.editing = true;
        $scope.errorMessage = '';
        $scope.index = -1;
    };

    $scope.cancelSaveSubCategory = function () {
        $ ('body').addClass ('bg-grey-1');
        reset ();
    };

    function getSubCategoriesList () {

        FirebaseAuthService
        .getUserToken ()
        .then (tokenId => SubCategoriesService.getSubCategoriesList ($scope.categoryId, tokenId, $scope.visitorId))
        .then ((response) => {
            $scope.subCategoriesList = response.data.sub_categories;
            $scope.subCategoriesList.sort ((a, b) => a.display_order - b.display_order);
            $scope.$applyAsync ();
            reset ();
        })
        .catch (err => $scope.checkErrStatus (err));

    }

    function reset () {
        $scope.processing = false;
        $scope.errorMessage = '';
        $scope.subCategoryId = '';
        $scope.subCategoryName = '';
        $scope.subCategoryCode = '';
        $scope.subCategoryOrder = 1;
        $scope.index = -1;
        $scope.editing = false;
    }

    $scope.init ();

})
;