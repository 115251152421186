angular.module('ics')
    .controller('AdminRegistrationController',
        function ($scope, $state, FirebaseAuthService, AdminService) {

            $scope.formData = {};
            $scope.error_message = '';
            $scope.processing = false;
            $scope.firebaseAuthService = FirebaseAuthService;

            $scope.signUp = function () {

                $scope.error_message = '';
                $scope.processing = true;

                FirebaseAuthService.signOut()
                    .then(() => FirebaseAuthService.createUserWithEmailAndPassword(
                        $scope.formData.email_address,
                        $scope.formData.password,
                        $scope.formData.first_name,
                        $scope.formData.last_name))
                    .then(token => AdminService.signupAdminUser($scope.formData.first_name,
                        $scope.formData.last_name, 'super_admin', token))
                    .then(res => FirebaseAuthService.signInWithCustomToken(res.data.token))
                    .then(() => {
                        $scope.processing = false;
                        $state.transitionTo('admin-dashboard');
                    })
                    .catch(e => {
                        $scope.processing = false;
                        $scope.$apply(function () {
                            $scope.error_message = e.message;
                        });
                        console.log('error encountered');
                        console.log(e);

                    });

            }

        });