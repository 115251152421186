angular.module('ics')

    .factory('FSPClientsService', function ($http) {

        return {

            getClientsList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_client_list/${fspId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            createClient: function (fspId, name, fund, type, structure,
                administrator, attachments, notes, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_client/',
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        name: name,
                        fund: fund,
                        notes: notes,
                        type: type,
                        structure: structure,
                        administrator: administrator,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            editClient: function (fspClientId, name, fund, type, structure,
                administrator, attachments, notes, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_client/${fspClientId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        name: name,
                        fund: fund,
                        notes: notes,
                        type: type,
                        structure: structure,
                        administrator: administrator,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            deleteClient: function (fspClientId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_client/${fspClientId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
