angular.module('ics')

    .factory('FSPThirdPartyAdministratorsService', function ($http) {

        return {

            getThirdPartyAdministratorsList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_third_party_administrator_list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createThirdPartyAdministrator: function (fspId, lisps, client_administered, contact_person, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_third_party_administrator/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        lisps: lisps,
                        client_administered: client_administered,
                        contact_person: contact_person,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            editThirdPartyAdministrator: function (fspThirdPartyAdministratorId, lisps, client_administered, contact_person, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_third_party_administrator/${fspThirdPartyAdministratorId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        lisps: lisps,
                        client_administered: client_administered,
                        contact_person: contact_person,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            deleteThirdPartyAdministrator: function (fspThirdPartyAdministratorId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_third_party_administrator/${fspThirdPartyAdministratorId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
