var utils = (function () {

    let DURATION_IN_SECONDS = {
        epochs: ['year', 'month', 'day', 'hour', 'minute'],
        year: 31536000,
        month: 2592000,
        day: 86400,
        hour: 3600,
        minute: 60
    };

    function getDuration(seconds) {
        let epoch, interval;

        for (let i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
            epoch = DURATION_IN_SECONDS.epochs[i];
            interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
            if (interval >= 1) {
                return {interval: interval, epoch: epoch};
            }
        }

    }

    return {

        chunkArray: function (arr, size) {
            var newArr = [];
            for (var i = 0; i < arr.length; i += size) {
                newArr.push(arr.slice(i, i + size));
            }
            return newArr;
        },

        sanitizeURL: function (url) {

            if (url != null && url.indexOf("http://") == -1 && url.indexOf("https://") == -1) {
                return "https://" + url;
            }

            return url;
        },

        timeSince: function (date) {
            let seconds = Math.floor((new Date() - new Date(date)) / 1000);
            let duration = getDuration(seconds);
            if (!duration){
                return 'just now';
            }
            let suffix = (duration.interval > 1 || duration.interval === 0) ? 's' : '';
            return duration.interval + ' ' + duration.epoch + suffix + ' ago';
        }

    }

})();
