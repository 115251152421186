angular.module('ics')
    .controller('FSPProfileChangeDetailsController', function ($scope, $state, $controller, $timeout,
                                                               FSPService, CategoriesService,
                                                               FirebaseAuthService, FirebaseStorageService) {

        $scope.profileChangeId = '';
        $scope.fspId = '';
        $scope.errorMessage = '';
        $scope.tokenId = '';
        $scope.profileChangeStatusCategories = [];
        $scope.processing = false;
        $scope.verify_email_send = false;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $(document).ready(function () {

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {
                    $scope.tokenId = tokenId;
                    $scope.processing = true;

                    $scope.profileChangeId = $state.params.profile_change_id;
                    $scope.fspId = $state.params.fsp_id;

                    FSPService
                        .GetFSPChangeDetails($scope.profileChangeId, $scope.tokenId, $scope.visitorId)
                        .then((response) => {

                            $scope.fspChangeDetails = response.data.status_change;
                            $scope.logs = response.data.logs || [];
                            $scope.processing = false;
                            $scope.fspChangeDetails.follow_up_on = $scope.fspChangeDetails.follow_up_on ? new Date($scope.fspChangeDetails.follow_up_on) : new Date();
                            $scope.fspChangeDetails.submission_date = $scope.fspChangeDetails.submission_date ? new Date($scope.fspChangeDetails.submission_date) : new Date();
                            $scope.fspChangeDetails.implemented_on = $scope.fspChangeDetails.implemented_on ? new Date($scope.fspChangeDetails.implemented_on): new Date();
                            $scope.fspChangeDetails.notes = $scope.fspChangeDetails.notes || '';

                            $scope.fspChangeDetails.followed_up_with_fsp_yn = !!$scope.fspChangeDetails.followed_up_with_fsp_yn;
                            $scope.fspChangeDetails.followed_up_with_fsca_yn = !!$scope.fspChangeDetails.followed_up_with_fsca_yn;
                            $scope.fspChangeDetails.followed_up_with_fsp_on = $scope.fspChangeDetails.followed_up_with_fsp_on ? new Date($scope.fspChangeDetails.followed_up_with_fsp_on) : new Date();
                            $scope.fspChangeDetails.followed_up_with_fsca_on = $scope.fspChangeDetails.followed_up_with_fsca_on ? new Date($scope.fspChangeDetails.followed_up_with_fsca_on) : new Date();

                            CategoriesService.getCategoriesListFromCode('profile_change_status', $scope.tokenId, $scope.visitorId)
                                .then(res => {
                                    $scope.profileChangeStatusCategories = res.data.categories;
                                    $timeout(function () {
                                        $('select').material_select();
                                    });
                                });

                            const docUploadControl = document.getElementById("documentUpload");

                            const fileDetails = {
                                folder: "/fsp_profile_change_attachments/",
                                attachments: $scope.fspChangeDetails.attachments
                            };

                            const eventDelegator = $scope.fileUploader.bind(fileDetails);
                            docUploadControl.addEventListener('change', eventDelegator);

                            $scope.$on('filesUploaded', function (event, attachments) {
                                console.log(attachments);
                                const combined = new Set($scope.fspChangeDetails.attachments.concat(attachments));
                                $scope.fspChangeDetails.attachments = [...combined];
                                $scope.$apply();
                            });

                        });
                })
                .catch(err => $scope.checkErrStatus(err));

        });

        $scope.saveChanges = function () {

            $scope.processing = true;

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {

                    $scope.followed_up_date = $('#followed_up_date').val();
                    $scope.submission_date = $('#submission_date').val();
                    $scope.implemented_on = $('#implemented_on').val();
                    $scope.followed_up_with_fsp_on = $('#followed_up_with_fsp_on').val();
                    $scope.followed_up_with_fsca_on = $('#followed_up_with_fsca_on').val();

                    FSPService
                        .editFSPProfileChange($scope.fspId, $scope.fspChangeDetails.id,
                            $scope.fspChangeDetails.status.id,
                            $scope.fspChangeDetails.follow_up_yn,
                            $scope.followed_up_date,
                            $scope.fspChangeDetails.implemented_yn,
                            $scope.implemented_on,
                            $scope.fspChangeDetails.submitted_yn,
                            $scope.submission_date,
                            $scope.fspChangeDetails.description,
                            $scope.fspChangeDetails.notes || '',
                            $scope.fspChangeDetails.attachments,
                            $scope.fspChangeDetails.email_subject,
                            $scope.fspChangeDetails.email_body,
                            $scope.verify_email_send,
                            $scope.fspChangeDetails.followed_up_with_fsp_yn,
                            $scope.followed_up_with_fsp_on,
                            $scope.fspChangeDetails.followed_up_with_fsca_yn,
                            $scope.followed_up_with_fsca_on,
                            tokenId, $scope.visitorId)
                        .then(() => {

                            $scope.processing = false;

                            $('#modalInfoHeader').html('Thank you');
                            $('#modalInfoText').html($scope.verify_email_send ? 'The profile change has been saved, and emails sent.' : 'The profile change has been saved.');
                            $('#information_modal').modal('open');

                            setTimeout(function () {
                                $('#information_modal').modal('close');
                                $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
                            }, 2000);

                        })
                        .catch(err => $scope.checkErrStatus(err));

                })
                .catch(err => $scope.checkErrStatus(err));

        };

        $scope.submitProfileChanges = function () {
            $state.transitionTo('admin-fbp-submit-profile-change', {
                fsp_id: $scope.fspId,
                profile_change_id: $scope.profileChangeId
            });
        };


        $scope.deleteProfileChange = function () {

            $('#modalConfirmHeader').html('DELETING PROFILE CHANGE');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteType = function () {

                FSPService.deleteFSPProfileChange(this.profileChangeId, $scope.tokenId, $scope.visitorId)
                    .then(() => $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId}))
                    .catch(err => $scope.checkErrStatus(err));

            };

            let deletionEvent = deleteType.bind({profileChangeId: $scope.profileChangeId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };


    });