angular.module('ics')

    .factory('FCSASubmissionReminderEmailTemplatesService', function ($http) {

        return {

            getTemplatesList: function (firebaseTokenId, visitorId) {

                let req = {
                    method: 'GET',
                    url: '/api/fsca_submission_reminder_email_template/list',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },

            createTemplate: function (templateName, emailSubject, emailBody, firebaseTokenId, visitorId) {
                let req = {
                    method: 'POST',
                    url: '/api/fsca_submission_reminder_email_template/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'template_name': templateName,
                        'email_body': emailBody,
                        'email_subject': emailSubject
                    }
                };

                return $http(req);
            },

            editTemplate: function (templateId, templateName, emailSubject, emailBody, firebaseTokenId, visitorId) {
                let req = {
                    method: 'PUT',
                    url: `/api/fsca_submission_reminder_email_template/${templateId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'template_name': templateName,
                        'email_body': emailBody,
                        'email_subject': emailSubject
                    }
                };

                return $http(req);
            },

            deleteTemplate: function (templateId, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/fsca_submission_reminder_email_template/${templateId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            }
        }

    });
