angular.module('ics')
    .controller('ExternalAuditorListAdminController', function ($scope, $state, $controller,
                                                                 FirebaseAuthService, FSPListsService) {

    $scope.items = [];
    $scope.fspId = $state.params.fsp_id;
    $scope.fspName = decodeURIComponent($state.params.fsp_name);

    angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

    init();

    function init() {

        FirebaseAuthService.getUserToken().then(tokenId => {

            FSPListsService.getExternalAuditorList($scope.fspId, tokenId, $scope.visitorId).then(res => {

                $scope.items = res.data.items;

            });

        });

    }

    $scope.editItem = function (id) {
        $('body').removeClass('bg-grey-1');
        $state.transitionTo('external-auditor-details', {
            fsp_id: $scope.fspId,
            fsp_name: $scope.fspName,
            item_id: id
        });

    };

    $scope.addItem = function () {
        $('body').removeClass('bg-grey-1');
        $state.transitionTo('external-auditor-details', {
            fsp_id: $scope.fspId,
            fsp_name: $scope.fspName,
            item_id: null
        });

    };

    $scope.deleteItem = function (id) {

        $('#modalConfirmHeader').html('DELETING EXTERNAL AUDITOR');
        $('#modalConfirmText').html('Are you sure you want to delete this item?');

        let deleteItemCallback = function () {

            FirebaseAuthService.getUserToken().then(tokenId => {

                FSPListsService.deleteExternalAuditor(this.id, tokenId, $scope.visitorId).then((response) => {
                    location.reload();
                }, (reject) => {
                    $scope.errorMessage = reject.message;
                    if (reject.status === 403) {
                        $state.transitionTo('signin');
                    }
                });

            });

        };

        $('#modalAgreeLink').click(function () {

            deleteItemCallback.bind({id: id})();

            $('#are_you_sure_modal').modal('close');
        });

        $('#are_you_sure_modal').modal('open');

    }

});
