angular.module('ics')

    .factory('FSPReportsService', function ($http) {

        return {

            getFSPReport: function (fspReportId) {

                const req = {
                    method: 'GET',
                    url: `/api/fsp_report/${fspReportId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            getReportHistory: function (fspId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'GET',
                    url: `/api/fsp_report_history/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000
                };

                return $http(req);

            },

            previewReport: function (fspId, includeFinProds, reportDate, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_report_preview',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {fsp_id: fspId, include_fin_prods: includeFinProds, report_date : reportDate}
                };

                return $http(req);


            },

            generateReport: function (fspIds, emailSubject, emailBody, includeFinProds, cc, to, reportDate, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_report/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp_ids: fspIds,
                        email_subject: emailSubject,
                        email_body: emailBody,
                        include_fin_prods: includeFinProds,
                        cc: cc,
                        to: to,
                        report_date: reportDate
                    }
                };

                return $http(req);

            },

            digitallySignReport: function (reportId, visitorId, firebaseTokenId) {
                var req = {
                    method: 'GET',
                    url: `/api/fsp_report_digitally_sign/${reportId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 10000
                };

                return $http(req);
            }

        }

    });
