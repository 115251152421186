angular.module('ics')

    .controller('FSPShareholdersAdminController', function ($scope, $state, $controller, $timeout,
                                                            FSPShareholdersService, FirebaseStorageService, FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.shareholders = [];
        $scope.shareholder = {
            id: 0,
            first_name: '',
            last_name: '',
            id_reg_number: '',
            date_obtained: new Date(),
            percentage: 0,
            attachments: []

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - administer shareholding`;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init () {
            retrieveShareholders();

            $(document).ready(function () {
                initiateDateControl();
            });
        }

        function initiateDateControl () {
            setTimeout(function () {

                // const $dateInput = $(`#date_obtained`).pickadate({
                //     selectMonths: true, // Creates a dropdown to control month
                //     selectYears: 100, // Creates a dropdown of 100 years to control year
                //     format: "yyyy-mm-dd"
                // });
                //
                // let datePicker = $dateInput.pickadate('picker');
                // datePicker.set('select', $scope.shareholder.date_obtained);

                const docUploadControl = document.getElementById("documentUpload");

                const fileDetails = {
                    folder: `/fsp_shareholder_attachments/${$scope.fspId}`,
                    attachments: $scope.shareholder.attachments
                };

                const eventDelegator = $scope.fileUploader.bind(fileDetails);
                docUploadControl.addEventListener('change', eventDelegator);

                $scope.$on('filesUploaded', function (event, attachments) {
                    const combined = new Set($scope.shareholder.attachments.concat(attachments));
                    $scope.shareholder.attachments = [...combined];
                    $scope.$apply();
                });


            }, 500);
        }

        function retrieveShareholders () {

            $scope.processing = true;
            $scope.editing = false;

            FSPShareholdersService
                .getShareholdersList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.shareholders = res.data.shareholders;
                    $scope.shareholders.sort((a,b) => parseFloat(b.percentage) - parseFloat(a.percentage));
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.editShareholder = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit shareholding';

            const shareholder = Object.assign({}, $scope.shareholders[index]);

            $scope.shareholder = {
                id: shareholder.id,
                first_name: shareholder.first_name,
                last_name: shareholder.last_name,
                id_reg_number: shareholder.id_reg_number,
                date_obtained: new Date(shareholder.date_obtained),
                percentage: !isNaN(shareholder.percentage) ? parseFloat(shareholder.percentage) : 0,
                attachments: shareholder.attachments

            };
            initiateDateControl();
            $scope.editing = true;
        };

        $scope.addShareholder = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add shareholding';

            $scope.shareholder = {
                id: 0,
                first_name: '',
                last_name: '',
                id_reg_number: '',
                date_obtained: new Date(),
                percentage: '',
                attachments: []

            };
            initiateDateControl();
            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer shareholding`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };


        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveShareholder($scope.shareholder.id, $scope.shareholder.first_name, $scope.shareholder.last_name,
                    $scope.shareholder.id_reg_number, $scope.shareholder.percentage, $scope.shareholder.attachments);
            }
        };

        $scope.saveShareholder = function (id, first_name, last_name, id_reg_number, percentage, attachments) {

            FirebaseAuthService.getUserToken()
                .then(tokenId => {

                    $scope.processing = true;

                    percentage = parseFloat(percentage);

                    let dateObtained = $('#date_obtained').val();
                    if (isNaN(percentage)) {
                        percentage = 0;
                    }

                    if (Math.abs(moment(dateObtained).diff(moment(), 'years')) > 100) {
                        dateObtained = moment().format('YYYY-MM-DD');
                    }

                    if (id) {

                        FSPShareholdersService
                            .editShareholder(id, first_name, last_name, id_reg_number,
                                dateObtained, percentage, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPShareholdersService
                            .createShareholder($scope.fspId, first_name, last_name,
                                id_reg_number, dateObtained, percentage, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

        };

        $scope.cancelEditing = function ($event) {

            $event.preventDefault();
            $('body').addClass('bg-grey-1');
            $scope.editing = false;
            $scope.title = `${$scope.fspName} - administer shareholding`;

        };


        $scope.deleteShareholder = function (shareholderId, index) {

            $('#modalConfirmHeader').html('DELETING SHAREHOLDING');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPShareholdersService
                    .deleteShareholder(this.shareholderId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({shareholderId: shareholderId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

    });
