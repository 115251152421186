angular.module('ics')
    .controller('FCSASubmissionReminderEmailTemplatesAdminController', function ($scope, $state, $controller, $timeout,
                                                                   FCSASubmissionReminderEmailTemplatesService,
                                                                   CategoriesService, FirebaseAuthService) {

        $scope.item = {

            templateId: '',
            templateName: '',
            emailSubject: '',
            emailBody: ''

        };

        $scope.editing = false;
        $scope.errorMessage = '';
        $scope.tokenId = '';
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService
            .getUserToken()
            .then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.init();
            })
            .catch(err => $scope.checkErrStatus(err));

        $scope.init = function () {

            $scope.processing = true;

            FCSASubmissionReminderEmailTemplatesService
                .getTemplatesList($scope.tokenId, $scope.visitorId)
                .then((response) => {
                    $scope.emailTemplatesList = response.data.templates;
                    reset();
                })
                .catch(err => $scope.checkErrStatus(err));

        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveTemplate(
                    $scope.item.templateName,
                    $scope.item.emailSubject,
                    $scope.item.emailBody);
            }
        };

        $scope.saveTemplate = function (templateName,
                                                     emailSubject,
                                                     emailBody) {

            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.item.templateId === '') {
                FCSASubmissionReminderEmailTemplatesService
                    .createTemplate(
                        templateName,
                        emailSubject,
                        emailBody,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            } else {
                FCSASubmissionReminderEmailTemplatesService
                    .editTemplate(
                        $scope.item.templateId,
                        templateName,
                        emailSubject,
                        emailBody,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteTemplate = function (templateId, index) {

            $('#modalConfirmHeader').html('DELETING EMAIL TEMPLATE');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FCSASubmissionReminderEmailTemplatesService
                    .deleteTemplate(this.templateId, $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({templateId: templateId});

            $(document).ready(function () {

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            });

        };

        $scope.editTemplate = function (templateId, index) {

            $('body').removeClass('bg-grey-1');

            $scope.item.templateId = templateId;
            $scope.item.templateName = $scope.emailTemplatesList[index].template_name;
            $scope.item.emailSubject = $scope.emailTemplatesList[index].email_subject;
            $scope.item.emailBody = $scope.emailTemplatesList[index].email_body;

            $timeout(function () {
                $('textarea').trigger('autoresize');
            });

            $scope.editing = true;
        };

        $scope.addTemplate = function () {
            $('body').removeClass('bg-grey-1');
            $scope.item.templateId = '';
            $scope.item.templateName = '';
            $scope.item.emailSubject = '';
            $scope.item.emailBody = '';

            $scope.editing = true;
            $scope.errorMessage = '';

            $timeout(function () {
                $('textarea').trigger('autoresize');
            });
        };

        $scope.cancelSaveTemplate = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset () {
            $scope.processing = false;
            $scope.editing = false;
            $scope.errorMessage = '';

            $scope.item.templateId = '';
            $scope.item.templateName = '';
            $scope.item.emailSubject = '';
            $scope.item.emailBody = '';
        }

    });