angular.module('ics')

    .factory('FSPContactPersonsService', function ($http) {

        return {

            getContactPersonsList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_contact_person_list/${fspId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
                         'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 20000
                };

                return $http(req);

            },

            getContactPersonEmailRecipients: function (firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: '/api/fsp_contact_person_email_recipients',
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 30000
                };

                return $http(req);

            },

            createContactPerson: function (fspId, name, last_name, email, uid, cell_number, tel_number,
                                           fax_number, physical_address, postal_address, receive_emails,
                                           receive_profile_change_emails,
                                           report_view_contact_persons,
                                           report_view_auditor,
                                           report_view_fsb_submissions,
                                           report_view_fsp_details,
                                           report_view_scope_of_licensing,
                                           report_view_clients,
                                           report_view_shareholders,
                                           report_view_directors,
                                           report_view_compliance_breaches,
                                           report_view_key_individuals,
                                           report_view_representatives,
                                           report_view_third_party_admins,
                                           report_view_third_party_providers,
                                           report_view_external_auditor,
                                           report_view_profile_changes,
                                           report_view_operational_risk_matrix,
                                           report_view_market_risk_matrix,
                                           report_view_regulatory_risk_matrix,
                                           report_view_compliance_documents, attachments, notes, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_contact_person/',
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        name: name,
                        last_name: last_name,
                        email: email,
                        uid: uid,
                        cell_number: cell_number,
                        tel_number: tel_number,
                        fax_number: fax_number,
                        physical_address: physical_address,
                        postal_address: postal_address,
                        receive_emails: receive_emails,
                        receive_profile_change_emails: receive_profile_change_emails,
                        report_view_contact_persons: report_view_contact_persons,
                        report_view_auditor: report_view_auditor,
                        report_view_fsb_submissions: report_view_fsb_submissions,
                        report_view_fsp_details: report_view_fsp_details,
                        report_view_scope_of_licensing: report_view_scope_of_licensing,
                        report_view_clients: report_view_clients,
                        report_view_shareholders: report_view_shareholders,
                        report_view_directors: report_view_directors,
                        report_view_compliance_breaches: report_view_compliance_breaches,
                        report_view_key_individuals: report_view_key_individuals,
                        report_view_representatives: report_view_representatives,
                        report_view_third_party_admins: report_view_third_party_admins,
                        report_view_third_party_providers: report_view_third_party_providers,
                        report_view_external_auditor: report_view_external_auditor,
                        report_view_profile_changes: report_view_profile_changes,
                        report_view_operational_risk_matrix: report_view_operational_risk_matrix,
                        report_view_market_risk_matrix: report_view_market_risk_matrix,
                        report_view_regulatory_risk_matrix: report_view_regulatory_risk_matrix,
                        report_view_compliance_documents: report_view_compliance_documents,
                        attachments: attachments || [],
                        notes: notes || ''
                    }
                };

                return $http(req);

            },

            editContactPerson: function (fspContactPersonId, name, last_name, email, cell_number, tel_number,
                                         fax_number, physical_address, postal_address, receive_emails,
                                         receive_profile_change_emails,
                                         report_view_contact_persons,
                                         report_view_auditor,
                                         report_view_fsb_submissions,
                                         report_view_fsp_details,
                                         report_view_scope_of_licensing,
                                         report_view_clients,
                                         report_view_shareholders,
                                         report_view_directors,
                                         report_view_compliance_breaches,
                                         report_view_key_individuals,
                                         report_view_representatives,
                                         report_view_third_party_admins,
                                         report_view_third_party_providers,
                                         report_view_external_auditor,
                                         report_view_profile_changes,
                                         report_view_operational_risk_matrix,
                                         report_view_market_risk_matrix,
                                         report_view_regulatory_risk_matrix,
                                         report_view_compliance_documents, attachments, notes, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_contact_person/${fspContactPersonId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        name: name,
                        last_name: last_name,
                        email: email,
                        cell_number: cell_number,
                        tel_number: tel_number,
                        fax_number: fax_number,
                        physical_address: physical_address,
                        postal_address: postal_address,
                        receive_emails: receive_emails,
                        receive_profile_change_emails: receive_profile_change_emails,
                        report_view_contact_persons: report_view_contact_persons,
                        report_view_auditor: report_view_auditor,
                        report_view_fsb_submissions: report_view_fsb_submissions,
                        report_view_fsp_details: report_view_fsp_details,
                        report_view_scope_of_licensing: report_view_scope_of_licensing,
                        report_view_clients: report_view_clients,
                        report_view_shareholders: report_view_shareholders,
                        report_view_directors: report_view_directors,
                        report_view_compliance_breaches: report_view_compliance_breaches,
                        report_view_key_individuals: report_view_key_individuals,
                        report_view_representatives: report_view_representatives,
                        report_view_third_party_admins: report_view_third_party_admins,
                        report_view_third_party_providers: report_view_third_party_providers,
                        report_view_external_auditor: report_view_external_auditor,
                        report_view_profile_changes: report_view_profile_changes,
                        report_view_operational_risk_matrix: report_view_operational_risk_matrix,
                        report_view_market_risk_matrix: report_view_market_risk_matrix,
                        report_view_regulatory_risk_matrix: report_view_regulatory_risk_matrix,
                        report_view_compliance_documents: report_view_compliance_documents,
                        attachments: attachments || [],
                        notes: notes || ''
                    }
                };

                return $http(req);

            },

            deleteContactPerson: function (fspContactPersonId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_contact_person/${fspContactPersonId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
