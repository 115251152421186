angular.module('ics')
    .controller('FSPRepresentativesAdminController', function ($scope,
                                                               $state,
                                                               $controller,
                                                               $timeout,
                                                               FSPRepresentativesService,
                                                               FirebaseAuthService,
                                                               FSPScopeOfLicensingService,
                                                               FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.representatives = [];
        $scope.financialProducts = [];
        $scope.supervisionFinancialProducts = [];

        $scope.representative = {
            id: 0,
            first_name: '',
            last_name: '',
            id_number: '',

            date_appointed: new Date(),
            date_of_first_appointment: new Date(),

            passed_re1: false,
            passed_re3: false,
            passed_re5: false,
            re5_exempt: false,
            re5_due_date: new Date(),

            license_items: [],
            qualification: false,
            supervisor: '',
            qualification_name: '',
            qualification_code: '',
            qualification_due_date: new Date(),
            attachments: [],
            key_individual_id: '',
            key_individual_name : '',
            cob: 'N/A',
            cob_due_date: new Date()
        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - administer representatives`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $scope.init = async () => {

            try {

                $scope.tokenId = await FirebaseAuthService.getUserToken();
                await retrieveRepresentatives();
                await retrieveScopesOfLicensing();

                $timeout(() => {
                    $(document).ready(() => {

                        const docUploadControl = document.getElementById("documentUpload");

                        const fileDetails = {
                            folder: `/fsp_representatives_attachments/${$scope.fspId}`,
                            attachments: $scope.representative.attachments
                        };

                        const eventDelegator = $scope.fileUploader.bind(fileDetails);
                        docUploadControl.addEventListener('change', eventDelegator);

                        $scope.$on('filesUploaded', function (event, attachments) {
                            const combined = new Set($scope.representative.attachments.concat(attachments));
                            $scope.representative.attachments = [...combined];
                            $scope.$apply();
                        });

                    })
                }, 1000);

            } catch (e) {
                $scope.checkErrStatus(e);
            }
        };
        $scope.init();

        async function retrieveRepresentatives() {

            $scope.processing = true;
            $scope.editing = false;
            const res = await FSPRepresentativesService.getRepresentativesList($scope.fspId, $scope.tokenId, $scope.visitorId);
            $scope.representatives = res.data.representatives;
            $scope.processing = false;

        }

        $scope.cat1Filter = function (item) {
            return item.category_name === 'Category I';
        };
        $scope.cat2Filter = function (item) {
            return item.category_name === 'Category II';
        };
        $scope.cat2aFilter = function (item) {
            return item.category_name === 'Category IIA';
        };

        async function retrieveScopesOfLicensing() {
            const res = await FSPScopeOfLicensingService.getScopeOfLicensing($scope.fspId, $scope.tokenId, $scope.visitorId);
            $scope.scope_of_licensing_items = res.data.fsp_scope_of_licensing.scope_of_licensing_items || [];
        }

        $scope.editRepresentative = function (index) {

            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit representative';

            const representative = $scope.representatives[index];
            const licenseItems = [].concat(representative.license_items || []);
            const finalLicenseItems = [];

            $scope.scope_of_licensing_items.forEach(item => {

                let repLicenseItem = licenseItems.find(i => i.category_id == item.category_id && i.financial_product_id == item.financial_product_id);

                if (repLicenseItem) {
                    repLicenseItem.advice_date = new Date(repLicenseItem.advice_date);
                    repLicenseItem.intermediary_date = new Date(repLicenseItem.intermediary_date);
                    repLicenseItem.discretion_date = new Date(repLicenseItem.discretion_date);
                    repLicenseItem.sus_date = new Date(repLicenseItem.sus_date);

                } else {

                    repLicenseItem = {
                        category_id: item.category_id,
                        financial_product_id: item.financial_product_id,
                        category_name: item.category_name,
                        financial_product_name: item.financial_product_name,
                        advice_date: new Date(),
                        intermediary_date: new Date(),
                        discretion_date: new Date(),
                        sus_date: new Date(),
                        advice: false,
                        intermediary: false,
                        discretion: false,
                        sus: false
                    }
                }

                finalLicenseItems.push(repLicenseItem);
            });

            $scope.representative = {
                id: representative.id,
                first_name: representative.first_name,
                last_name: representative.last_name,
                id_number: representative.id_number,
                date_appointed: new Date(representative.date_appointed),
                date_of_first_appointment: new Date(representative.date_of_first_appointment),
                license_items: finalLicenseItems,
                passed_re1: !!representative.passed_re1,
                passed_re3: !!representative.passed_re3,
                passed_re5: !!representative.passed_re5,
                re5_exempt: !!representative.re5_exempt,
                re5_due_date: representative.re5_due_date ? new Date(representative.re5_due_date) : new Date(),
                qualification: !!representative.qualification,
                supervisor: representative.supervisor,
                qualification_name: representative.qualification_name,
                qualification_code: representative.qualification_code,
                qualification_due_date: representative.qualification_due_date ? new Date(representative.qualification_due_date) : new Date(),
                attachments: representative.attachments,
                key_individual_id: representative.key_individual_id || '',
                key_individual_name: representative.key_individual_name || '',
                cob: representative.cob || 'N/A',
                cob_due_date: representative.cob_due_date ? new Date(representative.cob_due_date) : new Date(),
            };

            $scope.editing = true;

            $timeout(function () {
                $('select').material_select();
                $('#category_of_licensing').material_select();
                $('#financial_products').material_select();
                $('#supervision_financial_products').material_select();
            });

        };

        $scope.addRepresentative = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add representative';

            const finalLicenseItems = [];

            $scope.scope_of_licensing_items.forEach(item => {

                finalLicenseItems.push({
                    category_id: item.category_id,
                    financial_product_id: item.financial_product_id,
                    category_name: item.category_name,
                    financial_product_name: item.financial_product_name,
                    advice_date: new Date(),
                    intermediary_date: new Date(),
                    discretion_date: new Date(),
                    sus_date: new Date(),
                    advice: false,
                    intermediary: false,
                    discretion: false,
                    sus: false
                });
            });

            $scope.financialProducts.forEach(item => item.selected = false);
            $scope.supervisionFinancialProducts.forEach(item => item.selected = false);

            $scope.representative = {
                id: 0,
                first_name: '',
                last_name: '',
                id_number: '',
                date_appointed: new Date(),
                date_of_first_appointment: new Date(),
                license_items: finalLicenseItems,
                passed_re1: false,
                passed_re3: false,
                passed_re5: false,
                re5_exempt: false,
                re5_due_date: new Date(),
                qualification: false,
                supervisor: '',
                qualification_name: '',
                qualification_code: '',
                qualification_due_date: new Date(),
                attachments: [],
                key_individual_id: '',
                key_individual_name: '',
                cob: 'N/A',
                cob_due_date: new Date()
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer representatives`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.submitForm = function ($form) {

            if ($form.$valid) {
                $scope.saveRepresentative();
            }

        };

        $scope.saveRepresentative = async function () {

            $scope.tokenId = await FirebaseAuthService.getUserToken();

            let error = false;
            $scope.representative.license_items.forEach(item => {
                if (isNaN(item.advice_date.getTime())) {
                    item.advice_date = new Date();
                }
                if (isNaN(item.intermediary_date.getTime())) {
                    item.intermediary_date = new Date();
                }
                if (isNaN(item.discretion_date.getTime())) {
                    item.discretion_date = new Date();
                }
                if (isNaN(item.sus_date.getTime())) {
                    item.sus_date = new Date();
                }

                if (Math.abs(moment(item.advice_date).diff(moment(), 'years')) > 100) {
                    error = true;
                }
                if (Math.abs(moment(item.discretion_date).diff(moment(), 'years')) > 100) {
                    error = true;
                }
                if (Math.abs(moment(item.sus_date).diff(moment(), 'years')) > 100) {
                    error = true;
                }

            });

            if (error){
                return alert('Please check your dates, 1 or more appear to be invalid.');
            }

            $scope.representative.license_items.forEach(item => {
                item.advice_date = moment(item.advice_date).format('YYYY-MM-DD');
                item.intermediary_date = moment(item.intermediary_date).format('YYYY-MM-DD');
                item.discretion_date = moment(item.discretion_date).format('YYYY-MM-DD');
                item.sus_date = moment(item.sus_date).format('YYYY-MM-DD');
            });

            $scope.processing = true;
            $scope.representative.date_appointed = $('#date_appointed').val();
            $scope.representative.date_of_first_appointment = $('#date_of_first_appointment').val();
            $scope.representative.qualification_due_date = $('#qualification_due_date').val();
            $scope.representative.re5_due_date = $('#re5_due_date').val();
            $scope.representative.cob_due_date = $('#cob_due_date').val();

            if ($scope.representative.id) {

                await FSPRepresentativesService
                    .editRepresentative(
                        $scope.representative.id,
                        $scope.representative.first_name,
                        $scope.representative.last_name,
                        $scope.representative.id_number,
                        $scope.representative.date_appointed,
                        $scope.representative.date_of_first_appointment,
                        $scope.representative.license_items,
                        $scope.representative.qualification,
                        $scope.representative.passed_re1,
                        $scope.representative.passed_re3,
                        $scope.representative.passed_re5,
                        $scope.representative.re5_exempt,
                        $scope.representative.re5_due_date,
                        $scope.representative.supervisor,
                        $scope.representative.qualification_name,
                        $scope.representative.qualification_code,
                        $scope.representative.qualification_due_date,
                        $scope.representative.attachments,
                        $scope.representative.key_individual_id,
                        $scope.representative.key_individual_name,
                        $scope.representative.cob,
                        $scope.representative.cob_due_date,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));

            } else {

                FSPRepresentativesService
                    .createRepresentative(
                        $scope.fspId,
                        $scope.representative.first_name,
                        $scope.representative.last_name,
                        $scope.representative.id_number,
                        $scope.representative.date_appointed,
                        $scope.representative.date_of_first_appointment,
                        $scope.representative.license_items,
                        $scope.representative.qualification,
                        $scope.representative.passed_re1,
                        $scope.representative.passed_re3,
                        $scope.representative.passed_re5,
                        $scope.representative.re5_exempt,
                        $scope.representative.re5_due_date,
                        $scope.representative.supervisor,
                        $scope.representative.qualification_name,
                        $scope.representative.qualification_code,
                        $scope.representative.qualification_due_date,
                        $scope.representative.attachments,
                        $scope.representative.key_individual_id,
                        $scope.representative.key_individual_name,
                        $scope.representative.cob,
                        $scope.representative.cob_due_date,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));

            }


        };

        $scope.cancelEditing = function ($event) {
            $('body').addClass('bg-grey-1');
            $event.preventDefault();

            $scope.editing = false;
            $scope.title = `${$scope.fspName} - administer representatives`;
        };

        $scope.deleteRepresentative = function (representativeId, index) {

            $('#modalConfirmHeader').html('DELETING REPRESENTATIVE');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPRepresentativesService
                    .deleteRepresentative(this.representativeId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            let deletionEvent = deleteType.bind({representativeId: representativeId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };


    });
