angular.module('ics')

    .controller('FSPThirdPartyAdministratorsAdminController', function ($scope, $state, $controller,
                                                                        $timeout, FSPThirdPartyAdministratorsService,
                                                                        FirebaseAuthService, FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.third_party_administrators = [];
        $scope.third_party_administrator = {
            id: 0,
            lisps: '',
            client_administered: '',
            contact_person: '',
            date_appointed: new Date(),
            attachments: []

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - third-party administrators`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init() {

            retrieveThirdPartyAdministrators();

            setTimeout(function () {
                $(document).ready(function () {

                    const docUploadControl = document.getElementById("documentUpload");

                    const fileDetails = {
                        folder: `/fsp_third_party_administrator_attachments/${$scope.fspId}`,
                        attachments: $scope.third_party_administrator.attachments
                    };

                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                    docUploadControl.addEventListener('change', eventDelegator);

                    $scope.$on('filesUploaded', function (event, attachments) {
                        const combined = new Set($scope.third_party_administrator.attachments.concat(attachments));
                        $scope.third_party_administrator.attachments = [...combined];
                        $scope.$apply();
                    });
                })
            }, 1000);

        };

        function retrieveThirdPartyAdministrators() {

            $scope.processing = true;
            $scope.editing = false;

            FSPThirdPartyAdministratorsService
                .getThirdPartyAdministratorsList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.third_party_administrators = res.data.third_party_administrators;
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.editThirdPartyAdministrator = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit third party administrator';

            const third_party_administrator = $scope.third_party_administrators[index];
            $scope.third_party_administrator = {
                id: third_party_administrator.id,
                lisps: third_party_administrator.lisps,
                client_administered: third_party_administrator.client_administered,
                contact_person: third_party_administrator.contact_person,
                attachments: third_party_administrator.attachments

            };
            $scope.editing = true;
        };

        $scope.addThirdPartyAdministrator = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add third party administrator';

            $scope.third_party_administrator = {
                id: 0,
                lisps: '',
                client_administered: '',
                contact_person: '',
                attachments: []
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - third-party administrators`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.saveThirdPartyAdministrator = function (id, lisps, client_administered, contact_person, attachments) {

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {

                    $scope.processing = true;
                    $scope.third_party_administrator.date_appointed = $('#date_appointed').val();

                    if (id) {

                        FSPThirdPartyAdministratorsService
                            .editThirdPartyAdministrator(id, lisps, client_administered, contact_person, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPThirdPartyAdministratorsService
                            .createThirdPartyAdministrator($scope.fspId, lisps, client_administered, contact_person, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

        };

        $scope.cancelEditing = function ($event) {

            $event.preventDefault();
            $('body').addClass('bg-grey-1');
            $scope.editing = false;
            $scope.title = `${$scope.fspName} - third_party_administrators`;

        };

        $scope.deleteThirdPartyAdministrator = function (third_party_administratorId, index) {

            $('#modalConfirmHeader').html('DELETING ADMINISTRATOR');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPThirdPartyAdministratorsService
                    .deleteThirdPartyAdministrator(this.third_party_administratorId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())


            };

            let deletionEvent = deleteType.bind({third_party_administratorId: third_party_administratorId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.removeAttachment = function (index) {
            const filesUploadedIndex = filesUploaded.findIndex(item => item === $scope.third_party_administrator.attachments[index].file_name);
            if (filesUploadedIndex !== -1) {
                filesUploaded.splice(filesUploadedIndex, 1);
            }
            $scope.third_party_administrator.attachments.splice(index, 1);
        }

    });
