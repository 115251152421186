angular.module('ics')
    .controller('TwoFactorAuthController', function ($scope, $rootScope, 
        $controller, $state, FirebaseAuthService, TwoFactorAuthService) {

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $scope.awaitingOTP = false;
        $scope.sentOTP = false;
        $scope.validatedSuccess = false;

        $scope.setupTwoFactor = async function ($event) {
            
            try {
                $event.preventDefault()
                $scope.processing = true;
                $scope.errorMessage = '';

                const tokenId = await FirebaseAuthService.getUserToken();
                const res = await TwoFactorAuthService.setupTwoFactorAuthentication(tokenId);
                $scope.processing = false;
                $scope.errorMessage = '';
                $rootScope.twoFARequired = true;
                $rootScope.show_loading = true;
                $scope.$apply();
                $scope.$applyAsync();
                $rootScope.$apply();
                $rootScope.$applyAsync();
                window.location = res && res.data && res.data.result;

            } catch (err) {
                $scope.checkErrStatus(err);
            }
        };

        // Invoked if user does not have Authy installed and asks for OTP to be sent to them
        $scope.requestOneTimePin = async function($event) {
            try {
                $event.preventDefault();
                $scope.processing = true;
                $scope.errorMessage = '';

                const tokenId = await FirebaseAuthService.getUserToken();
                await TwoFactorAuthService.requestOneTimePin(tokenId, $scope.visitorId);
                $scope.sentOTP = true;
                $scope.processing = false;

            } catch (err) {
                $scope.checkErrStatus(err);
            }
        }

        $scope.confirmOTP = async function (otp) {
            
            try {

                $scope.processing = true;
                $scope.errorMessage = '';

                // Retrieve the visitor Id
                let visitorId = getCookie("ics-visitor-id");
                if (!visitorId) {
                    const fp = await fpPromise;
                    result = await fp.get();
                    visitorId = result.visitorId;
                    setCookie("ics-visitor-id", result.visitorId, 999);
                }
                // Verify the OTP
                const idToken = await firebase.auth().currentUser.getToken(/* forceRefresh */ true);
                const twoFAToken = await TwoFactorAuthService.verifyOneTimePin(idToken, otp, visitorId);
                
                setCookie("ics-2fa-auth-token", twoFAToken, 999);
                $rootScope.twoFARequired = true;
                $scope.processing = false;
                $scope.awaitingOTP = false;
                $scope.errorMessage = 'Thank you, 2FA has been setup on your account.';
                $scope.$apply();
                $rootScope.$apply();

            } catch (err) {
                $scope.checkErrStatus(err);
            }
        };

        $scope.cancelTwoFactorAuthentication = async function ($event) {
            
            try {

                $event.preventDefault();
                $scope.errorMessage = '';
                $scope.processing = true;

                const tokenId = await FirebaseAuthService.getUserToken();
                await TwoFactorAuthService.cancelTwoFactorAuthentication(tokenId);
                $scope.processing = false;
                $scope.errorMessage = '';
                $scope.awaitingOTP = false;
                $scope.sentOTP = false;
                $scope.validatedSuccess = false;
                $rootScope.twoFARequired = false;
                let twoFaReq = {'two_fa_required': false};
                FirebaseAuthService.saveTwoFaRequired(twoFaReq);
                $scope.errorMessage = 'Thank you, two factor authentication has been cancelled on your account.';
                $scope.$apply();
                $scope.$applyAsync();
                $rootScope.$apply();
                $rootScope.$applyAsync();

            } catch (err) {
                $scope.checkErrStatus(err);
            }
        };
       
    });
