angular.module('ics')
    .controller('FSPContactPersonsAdminController', function ($scope, $state, $controller, $timeout,
                                                              FSPContactPersonsService, FirebaseAuthService,
                                                              FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.contact_persons = [];
        $scope.contact_person = {
            id: 0,
            name: '',
            last_name: '',
            notes: '',
            email: '',
            password: '',
            cell_number: '',
            tel_number: '',
            fax_number: '',
            physical_address: '',
            postal_address: '',
            receive_emails: true,
            receive_profile_change_emails: true,
            report_view_contact_persons: true,
            report_view_auditor: true,
            report_view_fsb_submissions: true,
            report_view_fsp_details: true,
            report_view_scope_of_licensing: true,
            report_view_clients: true,
            report_view_shareholders: true,
            report_view_directors: true,
            report_view_compliance_breaches: true,
            report_view_key_individuals: true,
            report_view_representatives: true,
            report_view_third_party_admins: true,
            report_view_third_party_providers: true,
            report_view_external_auditor: true,
            report_view_profile_changes: true,
            report_view_operational_risk_matrix: true,
            report_view_market_risk_matrix: true,
            report_view_regulatory_risk_matrix: true,
            report_view_compliance_documents: true,
            attachments: []

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - administer contact persons`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init() {

            retrieveContactPersons();

            setTimeout(function () {
                $(document).ready(function () {

                    const docUploadControl = document.getElementById("documentUpload");

                    const fileDetails = {
                        folder: `/fsp_contact_person_attachments/${$scope.fspId}`,
                        attachments: $scope.contact_person.attachments
                    };

                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                    docUploadControl.addEventListener('change', eventDelegator);

                    $scope.$on('filesUploaded', function (event, attachments) {
                        const combined = new Set($scope.contact_person.attachments.concat(attachments));
                        $scope.contact_person.attachments = [...combined];
                        $scope.$apply();
                    });

                })
            }, 500);

        };

        function validatePassword(password) {

            const pRE = /(?=^.{6,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/;
            return pRE.test(password);

        }

        function makeId(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        function retrieveContactPersons() {

            $scope.processing = true;
            $scope.editing = false;

            FSPContactPersonsService
                .getContactPersonsList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.contact_persons = res.data.contact_persons;
                    $scope.contact_persons.sort((a, b) => {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    $scope.contact_persons.forEach(contact_person => {
                        contact_person.attachments.sort((a, b) => {
                            const textA = a.file_name.toUpperCase();
                            const textB = b.file_name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                    });
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.submitForm = function ($form) {

            if ($form.$valid) {
                $scope.saveContactPerson(
                    $scope.contact_person.id,
                    $scope.contact_person.name,
                    $scope.contact_person.last_name || '',
                    $scope.contact_person.email,
                    $scope.contact_person.password,
                    $scope.contact_person.cell_number,
                    $scope.contact_person.tel_number,
                    $scope.contact_person.fax_number,
                    $scope.contact_person.physical_address,
                    $scope.contact_person.postal_address,
                    $scope.contact_person.receive_emails,
                    $scope.contact_person.receive_profile_change_emails,
                    $scope.contact_person.report_view_contact_persons,
                    $scope.contact_person.report_view_auditor,
                    $scope.contact_person.report_view_fsb_submissions,
                    $scope.contact_person.report_view_fsp_details,
                    $scope.contact_person.report_view_scope_of_licensing,
                    $scope.contact_person.report_view_clients,
                    $scope.contact_person.report_view_shareholders,
                    $scope.contact_person.report_view_directors,
                    $scope.contact_person.report_view_compliance_breaches,
                    $scope.contact_person.report_view_key_individuals,
                    $scope.contact_person.report_view_representatives,
                    $scope.contact_person.report_view_third_party_admins,
                    $scope.contact_person.report_view_external_auditor,
                    $scope.contact_person.report_view_profile_changes,
                    $scope.contact_person.report_view_operational_risk_matrix,
                    $scope.contact_person.report_view_market_risk_matrix,
                    $scope.contact_person.report_view_regulatory_risk_matrix,
                    $scope.contact_person.report_view_compliance_documents,
                    $scope.contact_person.attachments,
                    $scope.contact_person.notes);
            }
        };

        $scope.editContactPerson = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit contact person';

            const contact_person = $scope.contact_persons[index];
            $scope.contact_person = {
                id: contact_person.id,
                name: contact_person.name,
                last_name: contact_person.last_name || '',
                password: '',
                email: contact_person.firebase_user.email,
                cell_number: contact_person.cell_number,
                tel_number: contact_person.tel_number,
                fax_number: contact_person.tel_number,
                physical_address: contact_person.physical_address,
                postal_address: contact_person.postal_address,
                receive_emails: contact_person.receive_emails || false,
                receive_profile_change_emails: contact_person.receive_profile_change_emails || false,
                report_view_contact_persons: contact_person.report_view_contact_persons || false,
                report_view_auditor: contact_person.report_view_auditor || false,
                report_view_fsb_submissions: contact_person.report_view_fsb_submissions || false,
                report_view_fsp_details: contact_person.report_view_fsp_details || false,
                report_view_scope_of_licensing: contact_person.report_view_scope_of_licensing || false,
                report_view_clients: contact_person.report_view_clients || false,
                report_view_shareholders: contact_person.report_view_shareholders || false,
                report_view_directors: contact_person.report_view_directors || false,
                report_view_compliance_breaches: contact_person.report_view_compliance_breaches || false,
                report_view_key_individuals: contact_person.report_view_key_individuals || false,
                report_view_representatives: contact_person.report_view_representatives || false,
                report_view_third_party_admins: contact_person.report_view_third_party_admins || false,
                report_view_third_party_providers: contact_person.report_view_third_party_providers || false,
                report_view_external_auditor: contact_person.report_view_external_auditor || false,
                report_view_profile_changes: contact_person.report_view_profile_changes || false,
                report_view_operational_risk_matrix: contact_person.report_view_operational_risk_matrix || false,
                report_view_market_risk_matrix: contact_person.report_view_market_risk_matrix || false,
                report_view_regulatory_risk_matrix: contact_person.report_view_regulatory_risk_matrix || false,
                report_view_compliance_documents: contact_person.report_view_compliance_documents || false,
                notes: contact_person.notes || '',
                attachments: contact_person.attachments
            };

            $scope.editing = true;
        };

        $scope.addContactPerson = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add contact person';

            $scope.contact_person = {
                id: 0,
                name: '',
                last_name: '',
                password: '',
                email: '',
                notes: '',
                cell_number: '',
                tel_number: '',
                fax_number: '',
                physical_address: '',
                postal_address: '',
                receive_emails: true,
                receive_profile_change_emails: true,
                report_view_contact_persons: true,
                report_view_auditor: true,
                report_view_fsb_submissions: true,
                report_view_fsp_details: true,
                report_view_scope_of_licensing: true,
                report_view_clients: true,
                report_view_shareholders: true,
                report_view_directors: true,
                report_view_compliance_breaches: true,
                report_view_key_individuals: true,
                report_view_representatives: true,
                report_view_third_party_admins: true,
                report_view_third_party_providers: true,
                report_view_external_auditor: true,
                report_view_profile_changes: true,
                report_view_operational_risk_matrix: true,
                report_view_market_risk_matrix: true,
                report_view_regulatory_risk_matrix: true,
                report_view_compliance_documents: true,
                attachments: []
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer contact persons`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.saveContactPerson = function (id, name, last_name, email,
                                             password, cell_number, tel_number, fax_number,
                                             physical_address, postal_address,
                                             receive_emails,
                                             receive_profile_change_emails,
                                             report_view_contact_persons,
                                             report_view_auditor,
                                             report_view_fsb_submissions,
                                             report_view_fsp_details,
                                             report_view_scope_of_licensing,
                                             report_view_clients,
                                             report_view_shareholders,
                                             report_view_directors,
                                             report_view_compliance_breaches,
                                             report_view_key_individuals,
                                             report_view_representatives,
                                             report_view_third_party_admins,
                                             report_view_external_auditor,
                                             report_view_profile_changes,
                                             report_view_operational_risk_matrix,
                                             report_view_market_risk_matrix,
                                             report_view_regulatory_risk_matrix,
                                             report_view_compliance_documents,
                                             attachments,
                                             notes) {

            try {

                FirebaseAuthService.getUserToken()
                    .then(tokenId => {

                        $scope.processing = true;

                        if (id) {

                            FSPContactPersonsService
                                .editContactPerson(id, name, last_name, email,
                                    cell_number, tel_number, fax_number,
                                    physical_address, postal_address, receive_emails,
                                    receive_profile_change_emails,
                                    report_view_contact_persons,
                                    report_view_auditor,
                                    report_view_fsb_submissions,
                                    report_view_fsp_details,
                                    report_view_scope_of_licensing,
                                    report_view_clients,
                                    report_view_shareholders,
                                    report_view_directors,
                                    report_view_compliance_breaches,
                                    report_view_key_individuals,
                                    report_view_representatives,
                                    report_view_third_party_admins,
                                    report_view_third_party_providers,
                                    report_view_external_auditor,
                                    report_view_profile_changes,
                                    report_view_operational_risk_matrix,
                                    report_view_market_risk_matrix,
                                    report_view_regulatory_risk_matrix,
                                    report_view_compliance_documents,
                                    attachments, notes, tokenId, $scope.visitorId)
                                .then(() => location.reload())
                                .catch(err => $scope.checkErrStatus(err));

                        } else {

                            if (!validatePassword(password)) {
                                alert('Please ensure the password is at least 6 digits long, and contains at least one capital letter and at least one special character');
                                $scope.processing = false;
                                return;
                            }

                            const userUid = makeId(16);

                            FSPContactPersonsService.createContactPerson($scope.fspId, name, last_name,
                                email, userUid, cell_number, tel_number,
                                fax_number, physical_address, postal_address,
                                receive_emails,
                                receive_profile_change_emails,
                                report_view_contact_persons,
                                report_view_auditor,
                                report_view_fsb_submissions,
                                report_view_fsp_details,
                                report_view_scope_of_licensing,
                                report_view_clients,
                                report_view_shareholders,
                                report_view_directors,
                                report_view_compliance_breaches,
                                report_view_key_individuals,
                                report_view_representatives,
                                report_view_third_party_admins,
                                report_view_third_party_providers,
                                report_view_external_auditor,
                                report_view_profile_changes,
                                report_view_operational_risk_matrix,
                                report_view_market_risk_matrix,
                                report_view_regulatory_risk_matrix,
                                report_view_compliance_documents,
                                attachments, notes, tokenId, $scope.visitorId)
                                .then(() => location.reload());

                        }
                    });


            } catch (e) {
                $scope.checkErrStatus(e);
            }

        };

        $scope.cancelEditing = function ($event) {
            $('body').addClass('bg-grey-1');
            $event.preventDefault();

            $scope.editing = false;
            $scope.title = `${$scope.fspName} - administer contact persons`;

        };

        $scope.deleteContactPerson = function (contact_personId, index) {

            $('#modalConfirmHeader').html('DELETING CONTACT PERSON');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPContactPersonsService
                    .deleteContactPerson(this.contact_personId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({contact_personId: contact_personId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

    });
