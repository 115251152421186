angular.module('ics')

    .factory('SubmitProfileChangeService', function ($http) {

        return {

            submitProfileChange: function (statusChangeId, emailSubject, emailBody, recipientList, attachments, firebaseTokenId, visitorId) {
                let req = {
                    method: 'POST',
                    url: '/api/fsb/submit_profile_change',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'fsp_status_change_id': statusChangeId,
                        'email_body': emailBody,
                        'email_subject': emailSubject,
                        'recipient_list': recipientList,
                        'attachments': attachments
                    }
                };

                return $http(req);
            }
        }

    });
