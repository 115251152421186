angular.module('ics')
    .controller('FSPClientsAdminController', function ($scope, $state, $controller, $timeout, FSPClientsService, FirebaseAuthService, FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.clients = [];
        $scope.client = {
            id: 0,
            name: '',
            fund: '',
            notes: '',
            type: '',
            structure: '',
            administrator: '',
            attachments: []

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - administer clients`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init () {

            retrieveClients();

            setTimeout(function () {
                $(document).ready(function () {

                    const docUploadControl = document.getElementById("documentUpload");

                    const fileDetails = {
                        folder: `/fsp_client_attachments/${$scope.fspId}`,
                        attachments: $scope.client.attachments
                    };

                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                    docUploadControl.addEventListener('change', eventDelegator);

                    $scope.$on('filesUploaded', function (event, attachments) {
                        const combined = new Set($scope.client.attachments.concat(attachments));
                        $scope.client.attachments = [...combined];
                        $scope.$apply();
                    });

                });
            }, 500);

        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveClient($scope.client.id, $scope.client.name, $scope.client.fund,
                    $scope.client.type, $scope.client.structure, $scope.client.administrator,
                    $scope.client.attachments, $scope.client.notes);
            }
        };

        function retrieveClients () {

            $scope.processing = true;
            $scope.editing = false;

            FSPClientsService.getClientsList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.clients = res.data.clients;
                    $scope.clients.sort((a, b) => {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    $scope.clients.forEach(client => {
                        client.attachments.sort((a, b) => {
                            const textA = a.file_name.toUpperCase();
                            const textB = b.file_name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                    });
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.editClient = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit client';

            const client = $scope.clients[index];
            $scope.client = {
                id: client.id,
                name: client.name,
                fund: client.fund || '',
                notes: client.notes || '',
                type: client.type,
                structure: client.structure,
                administrator: client.administrator,
                attachments: client.attachments

            };
            $scope.editing = true;
        };

        $scope.addClient = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add client';

            $scope.client = {
                id: 0,
                name: '',
                fund: '',
                notes: '',
                type: '',
                structure: '',
                administrator: '',
                attachments: []

            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer clients`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.saveClient = function (id, name, fund, type, structure, 
            administrator, attachments, notes) {

            FirebaseAuthService.getUserToken()
                .then(tokenId => {

                    $scope.processing = true;

                    if (id) {

                        FSPClientsService
                            .editClient(id, name, fund, type, structure, 
                                administrator, attachments, notes, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPClientsService
                            .createClient($scope.fspId, name, fund, type, structure, 
                                administrator, attachments, notes, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

        };

        $scope.cancelEditing = function ($event) {
            $('body').addClass('bg-grey-1');
            $event.preventDefault();

            $scope.editing = false;
            $scope.title = `${$scope.fspName} - administer clients`;

        };

        $scope.deleteClient = function (clientId, index) {

            $('#modalConfirmHeader').html('DELETING CLIENT');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPClientsService.deleteClient(this.clientId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({clientId: clientId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

    });
