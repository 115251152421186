angular.module('ics')

    .factory('SubCategoriesService', function ($http) {

        return {

            getSubCategoriesList: function (categoryId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/sub_category/list/${categoryId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            getSubCategoriesListFromCode: function (categoryCode, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/sub_category/list_from_code/${categoryCode}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createSubCategory: function (categoryId, name, code, display_order, firebaseTokenId, visitorId) {
                var req = {
                    method: 'POST',
                    url: '/api/sub_category/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'category_id': categoryId,
                        'name': name,
                        'code': code,
                        'display_order': display_order
                    }
                };

                return $http(req);
            },

            editSubCategory: function (subCategoryId, name, code, display_order, firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/sub_category/${subCategoryId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'name': name,
                        'code': code,
                        'display_order': display_order
                    }
                };

                return $http(req);
            },

            deleteSubCategory: function (subCategoryId, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/sub_category/${subCategoryId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            }
        }

    });
