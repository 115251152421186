angular.module('ics')

    .factory('FSPComplianceBreachesService', function ($http) {

        return {

            getComplianceBreachesList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_compliance_breach_list/${fspId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            createComplianceBreach: function (fspId, breach_type, material, note, 
                notification_date, notification_date_na, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_compliance_breach/',
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        breach_type: breach_type,
                        material: material,
                        note: note,
                        notification_date: notification_date,
                        notification_date_na: notification_date_na,
                    }
                };

                return $http(req);

            },

            editComplianceBreach: function (complianceBreachId, breach_type, 
                material, note, notification_date, notification_date_na, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_compliance_breach/${complianceBreachId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        breach_type: breach_type,
                        material: material,
                        note: note,
                        notification_date: notification_date,
                        notification_date_na: notification_date_na,
                    }
                };

                return $http(req);

            },

            deleteComplianceBreach: function (complianceBreachId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_compliance_breach/${complianceBreachId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
