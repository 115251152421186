angular.module('ics')

    .factory('FSPKeyIndividualsService', function ($http) {

        return {

            getKeyIndividualsList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_key_individual_list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createKeyIndividual: function (fspId, first_name, last_name, id_number, date_appointed,
                                           categories_of_licensing, financial_products, classes_of_business,
                                           sub_classes_of_business, attachments, passed_re1, passed_re3,
                                           passed_re5, has_qualification, qualification_code,
                                           qualification_name, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_key_individual/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        first_name: first_name,
                        last_name: last_name,
                        id_number: id_number,
                        date_appointed: date_appointed,
                        categories_of_licensing: categories_of_licensing,
                        financial_products: financial_products,
                        classes_of_business: classes_of_business,
                        sub_classes_of_business: sub_classes_of_business,
                        passed_re1: passed_re1,
                        passed_re3: passed_re3,
                        passed_re5: passed_re5,
                        has_qualification: has_qualification,
                        qualification_code: qualification_code,
                        qualification_name: qualification_name,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            editKeyIndividual: function (fspKeyIndividualId, first_name, last_name, id_number, date_appointed,
                                         categories_of_licensing, financial_products, classes_of_business,
                                         sub_classes_of_business, attachments, passed_re1, passed_re3,
                                         passed_re5, has_qualification, qualification_code,
                                         qualification_name, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_key_individual/${fspKeyIndividualId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        first_name: first_name,
                        last_name: last_name,
                        id_number: id_number,
                        date_appointed: date_appointed,
                        categories_of_licensing: categories_of_licensing,
                        financial_products: financial_products,
                        classes_of_business: classes_of_business,
                        sub_classes_of_business: sub_classes_of_business,
                        passed_re1: passed_re1,
                        passed_re3: passed_re3,
                        passed_re5: passed_re5,
                        has_qualification: has_qualification,
                        qualification_code: qualification_code,
                        qualification_name: qualification_name,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            deleteKeyIndividual: function (fspKeyIndividualId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_key_individual/${fspKeyIndividualId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
