angular.module('ics')

    .factory('FSPDocumentTreeService', function ($http) {

        return {

            getFolders: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_document_folder/${fspId}/`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            createFolder: function (fspId, folderId, title, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: `/api/fsp_document_folder/${fspId}/`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        title: title,
                        fsp_parent_document_folder_id: folderId || null
                    }
                };

                return $http(req);

            },

            editFolder: function (fspId, folderId, title, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_document_folder/${fspId}/${folderId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        title: title
                    }
                };

                return $http(req);

            },

            deleteFolder: function (fspId, folderId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_document_folder/${fspId}/${folderId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);


            },

            createFile: function (fspId, folderId, title, url, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: `/api/fsp_document_file/${fspId}/`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        fsp_document_folder_id : folderId,
                        title: title,
                        url: url
                    }
                };

                return $http(req);

            },

            editFile: function (fspId, fileId, title, url, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_document_file/${fspId}/${fileId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        title: title,
                        url: url
                    }
                };

                return $http(req);

            },

            deleteFile: function (fspId, fileId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_document_file/${fspId}/${fileId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);


            }

        }

    });
