angular.module('ics')
    .controller('NavController', function ($scope, $rootScope, $controller, $state, $timeout, FirebaseAuthService) {

    $scope.firebaseAuthService = FirebaseAuthService;
    $scope.state = $state;
    /*$rootScope.isSuperAdmin = false;
    $rootScope.isAdmin = false;
    $rootScope.isFSP = false;*/

    /*if ($rootScope.isAuthenticated == undefined) {
        $rootScope.isAuthenticated = false;
    }*/

    angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

    console.log($rootScope.isAuthenticated);

    $scope.$on('user-role-loaded', function (event, args) {
        $rootScope.loggedInUserRole = FirebaseAuthService.getLoggedInUserRole();

        if ($rootScope.loggedInUserRole && $rootScope.loggedInUserRole.userRole !== null) {

            $rootScope.isAuthenticated = true;
            if ($rootScope.loggedInUserRole.userRole.entity_role.toUpperCase() === ROLE_SUPER_ADMIN.toUpperCase()) {
                $rootScope.isSuperAdmin = true;
            } else if ($rootScope.loggedInUserRole.userRole.entity_role.toUpperCase() === ROLE_ADMIN.toUpperCase()) {
                $rootScope.isAdmin = true;
            } else if ($rootScope.loggedInUserRole.userRole.entity_role.toUpperCase() === ROLE_FSP.toUpperCase()) {
                $rootScope.isFSP = true;
            }
        } else {
            $rootScope.isAuthenticated = false;
        }
        console.log($rootScope.isAuthenticated);
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        $rootScope.processing = true;
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, options) {

        if ($rootScope.loggedInUserRole == undefined) {
            $rootScope.loggedInUserRole = FirebaseAuthService.getLoggedInUserRole();

            if ($rootScope.loggedInUserRole && $scope.loggedInUserRole.userRole !== null) {

                $rootScope.isAuthenticated = true;
                $rootScope.twoFARequired = FirebaseAuthService.getTwoFaRequired();
                if ($rootScope.loggedInUserRole.userRole.entity_role.toUpperCase() === ROLE_SUPER_ADMIN.toUpperCase()) {
                    $rootScope.isSuperAdmin = true;
                } else if ($rootScope.loggedInUserRole.userRole.entity_role.toUpperCase() === ROLE_ADMIN.toUpperCase()) {
                    $rootScope.isAdmin = true;
                } else if ($rootScope.loggedInUserRole.userRole.entity_role.toUpperCase() === ROLE_FSP.toUpperCase()) {
                    $rootScope.isFSP = true;
                }
            } else {
                $rootScope.isAuthenticated = false;
            }
        }

        $timeout(() => {

            $rootScope.previousState = fromState.name;
            $rootScope.currentState = toState.name;

            //console.log('Previous state:'+$rootScope.previousState);
            //console.log('Current state:'+$rootScope.currentState);

            console.log($rootScope.isAuthenticated);

            if ($rootScope.currentState == 'signin' && $rootScope.isAuthenticated) {
                $state.transitionTo('admin-dashboard');
            } else if ($rootScope.currentState == 'signin' ||
                       $rootScope.currentState == 'forgot-password' ||
                       $rootScope.currentState == 'admin-registration') {
                $rootScope.isAuthenticated = false;
            } else if ($rootScope.currentState == 'admin-dashboard' && !$rootScope.isAuthenticated) {
                $state.transitionTo('signin');
            }

            //Apply bg-grey background to main container
            $(document).ready(function() {
                if ($('main .bg-grey-1').length > 0) {
                    $('body').addClass('bg-grey-1');
                } else {
                    $('body').removeClass('bg-grey-1');
                }
            });

            $rootScope.processing = false;

        }, 1000);

    });

});
