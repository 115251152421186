angular.module('ics')
    .controller('AuthController', function ($scope, $rootScope, 
        $controller, $state, FirebaseAuthService, TwoFactorAuthService) {

        //angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $rootScope.err = {
            message: 'rytr '
        };
        $scope.reset_email_sent = false;
        $rootScope.isAuthenticated = false;
        $scope.awaitingOTP = false;
        $rootScope.awaitingOTP = false;
        $scope.sentOTP = false;

        $scope.signIn = async function (email, password) {
           
            try {

                $rootScope.err.message = '';
                $scope.processing = true;

                await firebase.auth().signInWithEmailAndPassword(email, password);
                const idToken = await firebase.auth().currentUser.getToken(/* forceRefresh */ true);//retrieve new firebase token
                const res = await FirebaseAuthService.createCustomToken(idToken); //generate custom token on server, containing user's roles and 2fa status, and sign in with this custom token
                $rootScope.loggedInUserRole  = res.role;
                $rootScope.twoFARequired = res.two_fa_required;
                FirebaseAuthService.saveTwoFaRequired(res);
                console.log(res);
                if (res.two_fa_required) {
                    $scope.processing = false;
                    $rootScope.show_loading = true;
                    const res = await TwoFactorAuthService.setupTwoFactorAuthentication(idToken)
                    FirebaseAuthService.performDuo2FARedirect(res);
                } else {
                    $scope.processing = false;
                    $scope.proceedToNextPage();
                }
                // $rootScope.processing = false;
                $rootScope.err.message = '';
                $scope.$apply();
                $rootScope.$apply();
                console.log(`$scope.awaitingOTP=${$scope.awaitingOTP}`)

            } catch (err) {
                $scope.processing = false;
                $rootScope.isAuthenticated = false;
                $rootScope.err = {
                    message: err
                };
                alert('An error has occurred, please try again: ' + err);
                $scope.$apply();
                $rootScope.$apply();
                $rootScope.$applyAsync();
            }
        };

        $scope.sendPasswordResetEmail = function (email) {
            firebase.auth().sendPasswordResetEmail(email);
            $scope.reset_email_sent = true;
        }

        // Invoked if user does not have Authy installed and asks for OTP to be sent to them
        $scope.requestOneTimePin = async function($event) {
            try {
                $event.preventDefault();
                $scope.processing = true;
                const tokenId = await FirebaseAuthService.getUserToken();
                await TwoFactorAuthService.requestOneTimePin(tokenId, $scope.visitorId);
                $scope.sentOTP = true;
                $scope.processing = false;
                $scope.$apply();
                $rootScope.$apply();
                $rootScope.$applyAsync();
            } catch (err) {
                $scope.processing = false;
                $rootScope.isAuthenticated = false;
                $rootScope.err = {
                    message: err
                };
                alert('An error has occurred, please try again: ' + err);
                $scope.$apply();
                $rootScope.$apply();
                $rootScope.$applyAsync();
            }
        }

        $scope.confirmOTP = async function (otp) {
           
            try {

                $rootScope.err.message = '';
                $scope.processing = true;

                // Retrieve the visitor Id
                let visitorId = getCookie("ics-visitor-id");
                if (!visitorId) {
                    const fp = await fpPromise;
                    result = await fp.get();
                    visitorId = result.visitorId;
                    setCookie("ics-visitor-id", result.visitorId, 999);
                }
                // Verify the OTP
                const idToken = await firebase.auth().currentUser.getToken(/* forceRefresh */ true);
                const twoFAToken = await TwoFactorAuthService.verifyOneTimePin(idToken, otp, visitorId);
                setCookie("ics-2fa-auth-token", twoFAToken, 999);
                const res = await FirebaseAuthService.createCustomToken(idToken); //generate custom token on server, containing user's roles and 2fa status, and sign in with this custom token
                $rootScope.loggedInUserRole  = res.role;

                $scope.awaitingOTP = false;
                $rootScope.awaitingOTP = false;
                $scope.processing = false;
                $rootScope.err.message = '';
                $scope.proceedToNextPage();

            } catch (err) {
                $scope.processing = false;
                $rootScope.isAuthenticated = false;
                $rootScope.err = {
                    message: err
                };
                alert('An error has occurred, please try again: ' + err);
                $scope.$apply();
                $rootScope.$apply();
                $rootScope.$applyAsync();
            }
        };

        $scope.proceedToNextPage = async function(){
            window.localStorage.removeItem('associated_fsps');

            if (($rootScope.loggedInUserRole.entity_role === ROLE_SUPER_ADMIN || $rootScope.loggedInUserRole.entity_role === ROLE_ADMIN)) {
                $rootScope.isAuthenticated = true;
                $state.transitionTo('admin-dashboard');

            } else {

                if ($rootScope.loggedInUserRole.entity_role === ROLE_FSP) {

                    $rootScope.isAuthenticated = true;
                    const tokenId = await FirebaseAuthService.getUserToken();

                    setTimeout(async () => {
                        const res = await FirebaseAuthService.getContactPersonAssociatedFsps(tokenId, $scope.visitorId);
                        if (res && res.data && res.data.associated_fsps && res.data.associated_fsps) {
                            const activeFSPs = res.data.associated_fsps.filter((item) => !!item.active);
                            if (activeFSPs.length) {
                                window.localStorage.setItem('associated_fsps', JSON.stringify(activeFSPs));
                                $state.transitionTo('admin-fsp-details', { fsp_id: activeFSPs[0].id });
                            }
                        }
                    }, 500);

                } else {

                    $rootScope.isAuthenticated = false;
                    $state.transitionTo('home');
                }
            }
        }

    });
