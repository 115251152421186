angular.module('ics')

    .factory('ComplianceDocumentsService', function ($http) {

    return {

        getDocumentTemplatesList: function (complianceDocumentTypeId, firebaseTokenId, visitorId) {

            var req = {
                method: 'GET',
                url: `/api/compliance_document_template/list/${complianceDocumentTypeId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http(req);

        },

        getFullDocumentTemplatesList: function (firebaseTokenId, visitorId) {

            var req = {
                method: 'GET',
                url: `/api/compliance_document_template/full_list`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http(req);

        },

        createDocumentTemplate: function (documentTypeId, templateName, profileChangeTypes,
                                           profileChangeStatuses, firebaseStorageUri, firebaseTokenId, visitorId) {
            var req = {
                method: 'POST',
                url: '/api/compliance_document_template/',
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    'document_type': documentTypeId,
                    'template_name': templateName,
                    'profile_change_types': profileChangeTypes,
                    'profile_change_statuses': profileChangeStatuses,
                    'firebase_storage_uri': firebaseStorageUri,
                }
            };

            return $http(req);
        },

        editDocumentTemplate: function (documentTemplateId, templateName, profileChangeTypes,
                                         profileChangeStatuses, firebaseStorageUri, firebaseTokenId, visitorId) {
            var req = {
                method: 'PUT',
                url: `/api/compliance_document_template/${documentTemplateId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    'template_name': templateName,
                    'profile_change_types': profileChangeTypes,
                    'profile_change_statuses': profileChangeStatuses,
                    'firebase_storage_uri': firebaseStorageUri,
                }
            };

            return $http(req);
        },

        deleteDocumentTemplate: function (complianceDocumentTemplateId, firebaseTokenId, visitorId) {
            var req = {
                method: 'DELETE',
                url: `/api/compliance_document_template/${complianceDocumentTemplateId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http(req);
        }
    }

});