angular.module('ics')

    .factory('FirebaseStorageService', function ($http) {

        let storageRef = firebase.storage().ref(); //for firebase storage upload

        return {

            uploadFileToCloudStorage: function (location, file, listener) {

                /**RECEIVES A FILE FROM FILE INPUT AND SAVES TO FIREBASE STORAGE. RETURNS PROMISE CONTAINING FILE IMAGE URL**/
                console.log(file);

                return new Promise((resolve, reject) => {

                    var uploadTask = storageRef.child(`${location}${file.name}`).put(file);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        function (snapshot) {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            if (!!listener) listener(progress);
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        }, function (error) {
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    console.log('User doesn\'t have permission to access the object');
                                    reject('User doesn\'t have permission to access the object');
                                    break;

                                case 'storage/canceled':
                                    console.log('User canceled the upload');
                                    reject('User canceled the upload');
                                    break;
                                case
                                'storage/unknown':
                                    console.log('Unknown error occurred, inspect error.serverResponse');
                                    reject('Unknown error occurred, inspect error.serverResponse');
                                    break;
                            }
                        }, function () {
                            // Upload completed successfully, now we can get the download URL
                            let downloadURL = uploadTask.snapshot.downloadURL;
                            resolve({
                                gsPath: uploadTask.toString(),
                                downloadUrl: downloadURL
                            });

                        });


                });


            }

        }
    });