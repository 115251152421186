angular.module('ics')

    .factory('FSPThirdPartyProvidersService', function ($http) {

        return {

            getThirdPartyProvidersList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_third_party_provider_list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createThirdPartyProvider: function (fspId, name, client_administered, notes, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_third_party_provider/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        name: name,
                        client_administered: client_administered,
                        notes: notes,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            editThirdPartyProvider: function (fspThirdPartyProviderId, name, client_administered, notes, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_third_party_provider/${fspThirdPartyProviderId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        name: name,
                        client_administered: client_administered,
                        notes: notes,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            },

            deleteThirdPartyProvider: function (fspThirdPartyProviderId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_third_party_provider/${fspThirdPartyProviderId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
