angular.module('ics')

    .controller('FSPComplianceBreachesAdminController', function ($scope, $state, $controller,
                                                         $timeout, FSPComplianceBreachesService,
                                                         FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.complianceBreaches = [];
        $scope.complianceBreach = {
            id: 0,
            breach_type: '',
            material: false,
            note: '',
            notification_date_na: false,
            notification_date: new Date()

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - administer compliance breaches`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init() {

            retrieveComplianceBreaches();

            setTimeout(function () {
                $(document).ready(function () {

                    // let $dateInput = $('#date_appointed').pickadate({
                    //     selectMonths: true, // Creates a dropdown to control month
                    //     selectYears: 100, // Creates a dropdown of 100 years to control year
                    //     format: "yyyy-mm-dd"
                    // });
                    //
                    // let datePicker = $dateInput.pickadate('picker');
                    // datePicker.set('select', $scope.director.date_appointed);

                })
            }, 1000);

        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveComplianceBreach($scope.complianceBreach.id, $scope.complianceBreach.breach_type, $scope.complianceBreach.material,
                    $scope.complianceBreach.note, $scope.complianceBreach.notification_date, $scope.complianceBreach.notification_date_na);
            }
        };

        function retrieveComplianceBreaches() {

            $scope.processing = true;
            $scope.editing = false;

            FSPComplianceBreachesService.getComplianceBreachesList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {
                    console.log(res.data.compliance_breaches);
                    $scope.complianceBreaches = res.data.compliance_breaches;
                    $scope.processing = false;
                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.editComplianceBreach = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit compliance breach';

            const complianceBreach = $scope.complianceBreaches[index];
            $scope.complianceBreach = {
                id: complianceBreach.id,
                breach_type: complianceBreach.breach_type,
                material: !!complianceBreach.material,
                note: complianceBreach.note,
                notification_date: new Date(complianceBreach.notification_date),
                notification_date_na: !!complianceBreach.notification_date_na,

            };
            $scope.editing = true;
        };

        $scope.addComplianceBreach = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add compliance breach';

            $scope.complianceBreach = {
                id: 0,
                breach_type: '',
                material: false,
                note: '',
                notification_date: new Date(),
                notification_date_na: false,
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer compliance breaches`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.saveComplianceBreach = function (id, breach_type, material, note, notification_date, notification_date_na) {

            FirebaseAuthService.getUserToken()
                .then(tokenId => {

                   notification_date = $('#notification_date').val();

                    if (Math.abs(moment(new Date(notification_date)).diff(moment(), 'years')) > 100) {
                        return alert('the notification date appears to be incorrect!');
                    }

                    console.log(notification_date);
                    $scope.processing = true;

                    if (id) {

                        FSPComplianceBreachesService
                            .editComplianceBreach(id, breach_type, material, note, notification_date, notification_date_na, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPComplianceBreachesService
                            .createComplianceBreach($scope.fspId, breach_type, material, note, notification_date, notification_date_na, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

        };

        $scope.cancelEditing = function ($event) {
            $('body').addClass('bg-grey-1');
            $event.preventDefault();

            $scope.editing = false;
            $scope.title = `${$scope.fspName} - administer compliance breaches`;

        };

        $scope.deleteComplianceBreach = function (breachId, index) {

            $('#modalConfirmHeader').html('DELETING COMPLIANCE BREACH');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteType = function () {

                FSPComplianceBreachesService.deleteComplianceBreach(this.breachId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            let deletionEvent = deleteType.bind({breachId: breachId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

    });
