angular.module('ics')

    .factory('FSPComplianceDocumentsService', function ($http) {

        return {

            getComplianceDocuments: function (fspId, firebaseTokenId, visitorId) {
                var time = new Date().getTime();
                var req = {
                    method: 'GET',
                    url: `/api/fsp_compliance_documents/${fspId}?timestamp=${time}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            saveComplianceDocuments: function (fspId, complianceDocuments, 
                complianceDocumentNotes, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: `/api/fsp_compliance_documents/${fspId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                         'VisitorId': visitorId || getCookie("ics-visitor-id"),
 'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        compliance_documents: complianceDocuments,
                        compliance_document_notes: complianceDocumentNotes,
                    }
                };

                return $http(req);

            },
        }

    });
