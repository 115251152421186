angular.module('ics')
    .factory('CalendarService', function ($http) {

    const service = {

        getReminders: function (firebaseTokenId, visitorId) {
            const req = {
                method: 'GET',
                url: '/api/reminders_list',
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http(req);
        },

        createReminder: function (reminderDate, reminderTitle,
                                  reminderDescription, firebaseTokenId, visitorId) {
            const req = {
                method: 'POST',
                url: '/api/reminder/',
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    reminder_date : reminderDate,
                    reminder_title: reminderTitle,
                    reminder_description: reminderDescription
                }
            };

            return $http(req);
        },

        updateReminder: function (reminderId, reminderDate,
                                  reminderTitle, reminderDescription, firebaseTokenId, visitorId) {
            const req = {
                method: 'PUT',
                url: `/api/reminder/${reminderId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    reminder_date : reminderDate,
                    reminder_title: reminderTitle,
                    reminder_description: reminderDescription
                }
            };

            return $http(req);
        },

        deleteReminder: function (reminderId, firebaseTokenId, visitorId) {
            const req = {
                method: 'DELETE',
                url: `/api/reminder/${reminderId}`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };

            return $http(req);
        }

    };

    return service;

});