angular.module('ics')
    .controller('ComplianceDocTemplatesAdminController', function ($scope, $state, $controller, $timeout,
                                                                   ComplianceDocumentsService,
                                                                   CategoriesService, FirebaseAuthService,
                                                                   FirebaseStorageService) {

            $scope.index = -1;

            $scope.item = {
                documentTemplateId: '',
                templateName: '',
                profileChangeTypes: [],
                profileChangeStatuses: [],
                firebaseStorageUri: ''
            };

            $scope.editing = false;
            $scope.tokenId = "";
            $scope.processing = true;

            angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

            FirebaseAuthService.getUserToken().then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.init();
            });

            $scope.init = function () {

                $scope.processing = true;
                $scope.documentTypeId = $state.params.document_type_id;
                $scope.documentTypeName = $state.params.document_type_name;

                retrieveDocumentTemplates($scope.documentTypeId)
                    .then((templates) => {
                        $scope.documentTemplatesList = templates;
                        reset();
                    })
                    .then(() => {

                        CategoriesService.getCategoriesListFromCode('profile_change_type', $scope.tokenId, $scope.visitorId)
                            .then(res => {
                                $scope.profileChangeTypeCategories = res.data.categories;

                                $scope.profileChangeTypeCategories.sort((a, b) => {
                                    const textA = a.name.toUpperCase();
                                    const textB = b.name.toUpperCase();
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                });

                                $timeout(function () {
                                    $('select').material_select();
                                });

                            })
                            .then(() => {
                                CategoriesService.getCategoriesListFromCode('profile_change_status', $scope.tokenId, $scope.visitorId)
                                    .then(res => {
                                        $scope.profileChangeStatusCategories = res.data.categories;

                                        $scope.profileChangeStatusCategories.sort((a, b) => {
                                            const textA = a.name.toUpperCase();
                                            const textB = b.name.toUpperCase();
                                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                        });

                                        $scope.processing = false;

                                        $timeout(function () {
                                            $('select').material_select();
                                        });
                                    })
                            })
                            .catch(err => $scope.checkErrStatus(err));
                    }).catch(err => $scope.checkErrStatus(err));
            };

            $scope.submitForm = function ($form) {
                if ($form.$valid) {
                    $scope.saveDocumentTemplate(
                        $scope.item.templateName,
                        $scope.item.profileChangeTypes,
                        $scope.item.profileChangeStatuses,
                        $scope.item.firebaseStorageUri);
                }
            };

            $scope.saveDocumentTemplate = function (templateName,
                                                    profileChangeTypes,
                                                    profileChangeStatuses,
                                                    firebaseStorageUri) {

                if (templateName.length === 0 || firebaseStorageUri.length === 0) {
                    alert('Please upload a file first');
                    return;
                }

                $('body').addClass('bg-grey-1');
                $scope.errorMessage = '';
                $scope.processing = true;

                if ($scope.item.documentTemplateId === '') {
                    ComplianceDocumentsService.createDocumentTemplate(
                        $scope.documentTypeId,
                        templateName,
                        profileChangeTypes,
                        profileChangeStatuses,
                        firebaseStorageUri,
                        $scope.tokenId, $scope.visitorId)
                        .then($scope.init)
                        .catch(err => $scope.checkErrStatus(err))
                } else {
                    ComplianceDocumentsService.editDocumentTemplate(
                        $scope.item.documentTemplateId,
                        templateName,
                        profileChangeTypes,
                        profileChangeStatuses,
                        firebaseStorageUri,
                        $scope.tokenId, $scope.visitorId)
                        .then($scope.init)
                        .catch(err => $scope.checkErrStatus(err))
                }

            };

            $scope.deleteDocumentTemplate = function (documentTemplateId, index) {

                $('#modalConfirmHeader').html('Deleting Document Template');
                $('#modalConfirmText').html('Are you sure you want to delete this item?');

                const deleteType = function () {

                    ComplianceDocumentsService.deleteDocumentTemplate(this.documentTemplateId, $scope.tokenId, $scope.visitorId)
                        .then(() => location.reload())
                        .catch(err => $scope.checkErrStatus(err));

                };

                const deletionEvent = deleteType.bind({documentTemplateId: documentTemplateId});

                $(document).ready(function () {

                    $('#modalAgreeLink').click(function () {
                        deletionEvent();
                        $('#are_you_sure_modal').modal('close');
                    });

                    $('#are_you_sure_modal').modal('open');

                });

            };

            function uploadInit () {
                $timeout(function () {
                    $('select').material_select();

                    let docUploadControl = document.getElementById("documentUpload");

                    docUploadControl.addEventListener('change', function (e) {
                        $scope.processing = true;
                        let file = e.target.files[0];
                        if (!file || !file.name) return;
                        console.log($('.file-field #uploading-document'));
                        $('.file-field #uploading-document').removeClass('ng-hide');
                        $('.file-field .btn').addClass('ng-hide');
                        FirebaseStorageService.uploadFileToCloudStorage("/compliance_document_templates/", file, null).then(
                            (storageInfo) => {
                                $scope.item.firebaseStorageUri = storageInfo.downloadUrl;
                                $scope.processing = false;
                                $('.file-field #uploading-document').addClass('ng-hide');
                                $('.file-field .btn').removeClass('ng-hide');
                                $scope.$apply();
                            }, (errMessage) => {
                                $scope.processing = false;
                                $('.file-fieldf #uploading-document').addClass('ng-hide');
                                $('.file-field .btn').removeClass('ng-hide');
                                alert(errMessage);
                            });
                    });

                });
            }

            $scope.editDocumentTemplate = function (documentTemplateId, index) {
                $('body').removeClass('bg-grey-1');

                $scope.item.documentTemplateId = documentTemplateId;
                $scope.item.index = index;
                $scope.item.templateName = $scope.documentTemplatesList[index].template_name;
                $scope.item.profileChangeTypes = $scope.documentTemplatesList[index].profile_change_types.map(category => category.id);
                $scope.item.profileChangeStatuses = $scope.documentTemplatesList[index].profile_change_statuses.map(category => category.id);
                $scope.item.firebaseStorageUri = $scope.documentTemplatesList[index].firebase_storage_uri;

                $timeout(function () {
                    $('select').material_select();
                });

                uploadInit();

                $scope.editing = true;
            };

            $scope.addDocumentTemplate = function () {
                $('body').removeClass('bg-grey-1');

                $scope.item.documentTemplateId = '';
                $scope.item.templateName = '';
                $scope.item.profileChangeTypes = [];
                $scope.item.profileChangeStatuses = [];
                $scope.item.firebaseStorageUri = '';

                $scope.editing = true;
                $scope.errorMessage = '';
                $scope.index = -1;

                $timeout(function () {
                    $('select').material_select();
                });

                uploadInit();
            };

            $scope.cancelSaveDocumentTemplate = function () {
                $('body').addClass('bg-grey-1');
                reset();
            };

            function retrieveDocumentTemplates (documentTypeId,) {
                return new Promise((resolve, reject) => {

                    ComplianceDocumentsService
                        .getDocumentTemplatesList(documentTypeId, $scope.tokenId, $scope.visitorId)
                        .then((response) => {
                            resolve(response.data.document_templates);
                            reset();
                        })
                        .catch(err => {
                            reject(err);
                        })
                });
            }

            function reset () {

                $scope.processing = false;
                $scope.editing = false;
                $scope.errorMessage = '';
                $scope.index = -1;

                $scope.item.documentTemplateId = '';
                $scope.item.templateName = '';
                $scope.item.profileChangeTypes = [];
                $scope.item.profileChangeStatuses = [];
                $scope.item.firebaseStorageUri = '';

            }

        }
    );