angular.module('ics')

    .controller('FSPThirdPartyProvidersAdminController', function ($scope, $state, $controller,
                                                                        $timeout, FSPThirdPartyProvidersService,
                                                                        FirebaseAuthService, FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.third_party_providers = [];
        $scope.third_party_provider = {
            id: 0,
            name: '',
            client_administered: '',
            notes: '',
            date_appointed: new Date(),
            attachments: []

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - third-party providers`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init() {

            retrieveThirdPartyProviders();

            setTimeout(function () {
                $(document).ready(function () {

                    const docUploadControl = document.getElementById("documentUpload");

                    const fileDetails = {
                        folder: `/fsp_third_party_provider_attachments/${$scope.fspId}`,
                        attachments: $scope.third_party_provider.attachments
                    };

                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                    docUploadControl.addEventListener('change', eventDelegator);

                    $scope.$on('filesUploaded', function (event, attachments) {
                        const combined = new Set($scope.third_party_provider.attachments.concat(attachments));
                        $scope.third_party_provider.attachments = [...combined];
                        $scope.$apply();
                    });
                })
            }, 1000);

        };

        function retrieveThirdPartyProviders() {

            $scope.processing = true;
            $scope.editing = false;

            FSPThirdPartyProvidersService
                .getThirdPartyProvidersList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.third_party_providers = res.data.third_party_providers;
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.editThirdPartyProvider = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit third party provider';

            const third_party_provider = $scope.third_party_providers[index];
            $scope.third_party_provider = {
                id: third_party_provider.id,
                name: third_party_provider.name,
                client_administered: third_party_provider.client_administered,
                notes: third_party_provider.notes,
                attachments: third_party_provider.attachments

            };
            $scope.editing = true;
        };

        $scope.addThirdPartyProvider = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add third party provider';

            $scope.third_party_provider = {
                id: 0,
                name: '',
                client_administered: '',
                notes: '',
                attachments: []
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - third-party providers`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.saveThirdPartyProvider = function (id, name, client_administered, notes, attachments) {

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {

                    $scope.processing = true;
                    $scope.third_party_provider.date_appointed = $('#date_appointed').val();

                    if (id) {

                        FSPThirdPartyProvidersService
                            .editThirdPartyProvider(id, name, client_administered, notes, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPThirdPartyProvidersService
                            .createThirdPartyProvider($scope.fspId, name, client_administered, notes, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

        };

        $scope.cancelEditing = function ($event) {

            $event.preventDefault();
            $('body').addClass('bg-grey-1');
            $scope.editing = false;
            $scope.title = `${$scope.fspName} - third_party_providers`;

        };

        $scope.deleteThirdPartyProvider = function (third_party_providerId, index) {

            $('#modalConfirmHeader').html('DELETING ADMINISTRATOR');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPThirdPartyProvidersService
                    .deleteThirdPartyProvider(this.third_party_providerId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())


            };

            let deletionEvent = deleteType.bind({third_party_providerId: third_party_providerId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.removeAttachment = function (index) {
            const filesUploadedIndex = filesUploaded.findIndex(item => item === $scope.third_party_provider.attachments[index].file_name);
            if (filesUploadedIndex !== -1) {
                filesUploaded.splice(filesUploadedIndex, 1);
            }
            $scope.third_party_provider.attachments.splice(index, 1);
        }

    });
