angular.module('ics')
    .controller('FSPComplianceDocumentsAdminController', function ($scope, $state, $controller, $timeout,
                                                                   FSPComplianceDocumentsService, FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.complianceDocuments = [];
        $scope.complianceDocumentNotes = '';
        $scope.tokenId = '';
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            retrieveComplianceDocuments();
        });

        function retrieveComplianceDocuments() {

            $scope.processing = true;
            $scope.editing = false;

            FSPComplianceDocumentsService
                .getComplianceDocuments($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    const documents = res.data.compliance_documents;
                    $scope.complianceDocumentNotes = res.data.compliance_document_notes || '';

                    const uniqueNames = [...new Set(documents.map(doc => doc.name))];
                    const uniqueDocuments = [];
                    uniqueNames.forEach(uniqueName => {
                        uniqueDocuments.push(documents.find(doc => doc.name === uniqueName));
                    });

                    $scope.complianceDocuments = uniqueDocuments;
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.saveComplianceDocuments = function () {
            $scope.processing = true;
            FSPComplianceDocumentsService.saveComplianceDocuments($scope.fspId, $scope.complianceDocuments,
                $scope.complianceDocumentNotes || '', $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.processing = false;
                    $scope.goBack();

                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

    });
