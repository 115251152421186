angular.module('ics')

    .factory('FSPShareholdersService', function ($http) {

        return {

            getShareholdersList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_shareholder/list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createShareholder: function (fspId, first_name, last_name, id_reg_number, 
                date_obtained, percentage, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_shareholder/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        first_name: first_name,
                        last_name: last_name,
                        id_reg_number: id_reg_number,
                        date_obtained: date_obtained,
                        percentage: percentage,
                        attachments: attachments
                    }
                };

                return $http(req);

            },

            editShareholder: function (shareholderId, first_name, last_name,
                                       id_reg_number, date_obtained, percentage, attachments, firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_shareholder/${shareholderId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        name: name,
                        first_name: first_name,
                        last_name: last_name,
                        id_reg_number: id_reg_number,
                        date_obtained: date_obtained,
                        percentage: percentage,
                        attachments: attachments
                    }
                };

                return $http(req);

            },

            deleteShareholder: function (shareholderId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_shareholder/${shareholderId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
