angular.module('ics')
    .controller('FSPProfileChangeController', function ($scope, $state, $controller, $timeout,
                                                        FSPService, CategoriesService, ComplianceDocumentsService,
                                                        FirebaseAuthService, EmailTemplatesService) {

        $scope.fspId = '';
        $scope.errorMessage = '';
        $scope.followed_up_date = new Date();
        $scope.submission_date = new Date();
        $scope.attachments = [];
        $scope.email_subject = '';
        $scope.email_body = '';
        $scope.email_template = null;
        $scope.follow_up_yn = false;
        $scope.submitted_yn = false;
        $scope.email_send = true;
        $scope.tokenId = '';
        $scope.processing = true;
        $scope.verify_email_send = false;
        $scope.notes = '';

        $scope.implemented_yn = false;
        $scope.implemented_on = new Date();

        $scope.followed_up_with_fsp_yn = false;
        $scope.followed_up_with_fsp_on = new Date();

        $scope.followed_up_with_fsca_yn = false;
        $scope.followed_up_with_fsca_on = new Date();

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService
            .getUserToken()
            .then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.processing = true;

                $scope.fspId = $state.params.fsp_id;

                FSPService.GetFSPDetails($scope.fspId, $scope.tokenId, $scope.visitorId).then((response) => {

                    $scope.fspDetails = response.data.fsp;

                    // let $followedUpDateInput = $('#followed_up_date').pickadate({
                    //     selectMonths: true, // Creates a dropdown to control month
                    //     selectYears: 20, // Creates a dropdown of 20 years to control year
                    //     format: "yyyy-mm-dd"
                    // });
                    //
                    // let $submissionDateInput = $('#submission_date').pickadate({
                    //     selectMonths: true, // Creates a dropdown to control month
                    //     selectYears: 20, // Creates a dropdown of 20 years to control year
                    //     format: "yyyy-mm-dd"
                    // });

                }).then(() => {

                    CategoriesService.getCategoriesListFromCode('profile_change_status', $scope.tokenId, $scope.visitorId)
                        .then(res => {
                            $scope.profileChangeStatusCategories = res.data.categories;

                            $scope.profileChangeStatusCategories.sort((a, b) => {
                                let textA = a.name.toUpperCase();
                                let textB = b.name.toUpperCase();
                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                            });

                            $timeout(function () {
                                $('select').material_select();
                            });

                        });

                }).then(() => {

                    CategoriesService.getCategoriesListFromCode('profile_change_type', $scope.tokenId, $scope.visitorId)
                        .then(res => {
                            $scope.profileChangeTypeCategories = res.data.categories;

                            $scope.profileChangeTypeCategories.sort((a, b) => {
                                let textA = a.name.toUpperCase();
                                let textB = b.name.toUpperCase();
                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                            });

                            $timeout(function () {
                                $('select').material_select();
                            });
                        });


                }).then(() => {

                    ComplianceDocumentsService.getFullDocumentTemplatesList($scope.tokenId, $scope.visitorId)
                        .then((response) => {
                            $scope.documentTemplatesFullList = response.data.document_templates;
                            $scope.documentTemplates = response.data.document_templates;

                            $timeout(function () {
                                $('select').material_select();
                            });

                        });

                }).then(() => {

                    EmailTemplatesService.getEmailTemplatesList($scope.tokenId, $scope.visitorId)
                        .then((response) => {
                            $scope.emailTemplatesFullList = response.data.email_templates;
                            $scope.emailTemplates = response.data.email_templates;
                            $scope.processing = false;

                            $(document).ready(function () {

                                $('body').removeClass('bg-grey-1');

                                $timeout(function () {
                                    $('select').material_select();
                                    $('textarea').trigger('autoresize');

                                    const docUploadControl = document.getElementById("documentUpload");

                                    const fileDetails = {
                                        folder: "/fsp_profile_change_attachments/",
                                        attachments: $scope.attachments
                                    };

                                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                                    docUploadControl.addEventListener('change', eventDelegator);

                                    $scope.$on('filesUploaded', function (event, attachments) {
                                        const combined = new Set($scope.attachments.concat(attachments));
                                        $scope.attachments = [...combined];
                                        $scope.$apply();
                                    });

                                });

                            });

                        });

                }).catch(err => $scope.checkErrStatus(err));
            }).catch(err => $scope.checkErrStatus(err));


        $scope.selectEmailTemplate = function () {

            $scope.emailTemplates.forEach(template => {
                if (template.id === $scope.email_template) {
                    $scope.email_subject = template.email_subject;
                    $scope.email_body = template.email_body;
                    $timeout(function () {
                        $('select').material_select();
                        $('textarea').trigger('autoresize');
                        $('#email_body').trigger('autoresize');
                    }, 500);
                }
            });

        };

        $scope.backToDetailsPage = function () {
            $('body').addClass('bg-grey-1');
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.deleteAttachment = function (e, index) {

            e.preventDefault();
            $scope.attachments.splice(index, 1);

        };

        $scope.submitForm = function ($form) {

            if ($form.$valid) {

                if ($scope.verify_email_send) {

                    $('#modalConfirmHeader').html('Please confirm');
                    $('#modalConfirmText').html('Are you sure you want to email the client at this time?');
                    $(document).ready(function () {
                        $('#modalAgreeLink').click(function () {
                            $scope.saveProfileChange();
                            $('#are_you_sure_modal').modal('close');
                        });
                        $('#are_you_sure_modal').modal('open');
                    });
                } else {
                    $scope.saveProfileChange();
                }
            }

        };

        $scope.changeProfileSettings = function () {

            $scope.documentTemplates = [];
            $scope.emailTemplates = [];

            const statusRecord = $scope.profileChangeStatusCategories.find(item => item.id == $scope.profile_change_status);
            if (statusRecord && statusRecord.name.toLowerCase() === 'implemented'){
                $scope.implemented_yn = true;
            } else {
                $scope.implemented_yn = false;
            }

            if (!!$scope.documentTemplatesFullList && $scope.documentTemplatesFullList.length > 0) {

                $scope.documentTemplatesFullList.forEach(template => {

                    for (let i = 0; i < template.profile_change_types.length; i++) {
                        if (template.profile_change_types[i].id === $scope.profile_change_type) {
                            $scope.documentTemplates.push(template);
                        }
                    }

                });
            }

            let newTemplateList = [];
            if ($scope.emailTemplatesFullList && $scope.emailTemplatesFullList.length > 0) {
                $scope.emailTemplatesFullList.forEach(template => {

                    for (let i = 0; i < template.profile_change_types.length; i++) {
                        if (template.profile_change_types[i].id === $scope.profile_change_type) {
                            newTemplateList.push(template);
                        }
                    }

                });
            }

            $timeout(() => {
                $scope.emailTemplates = newTemplateList;

                $timeout(function () {
                    $('select').material_select();
                });
                $scope.$applyAsync();
            }, 500);

        };

        $scope.removeDocumentTemplate = function (e, index) {

            e.preventDefault();
            $scope.documentTemplates.splice(index, 1);
            $scope.$applyAsync();

        };

        $scope.saveProfileChange = function () {

            let selected_attachments = [];

            $scope.documentTemplates.forEach(template => {
                selected_attachments.push({
                    file_url: template.firebase_storage_uri,
                    file_name: template.template_name,
                    attach_to_email: template.attach_to_email
                });
            });

            selected_attachments = selected_attachments.concat($scope.attachments);

            $scope.processing = true;

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {

                    $scope.followed_up_date = $('#followed_up_date').val();
                    $scope.submission_date = $('#submission_date').val();
                    $scope.followed_up_with_fsp_on = $('#followed_up_with_fsp_on').val();
                    $scope.followed_up_with_fsca_on = $('#followed_up_with_fsca_on').val();
                    $scope.implemented_on = $('#implemented_on').val();

                    FSPService
                        .createFSPProfileChange($scope.fspId,
                            $scope.profile_change_type,
                            $scope.profile_change_status,
                            $scope.follow_up_yn,
                            $scope.followed_up_date,
                            $scope.implemented_yn,
                            $scope.implemented_on,
                            $scope.submitted_yn,
                            $scope.submission_date,
                            $scope.description,
                            $scope.notes || '',
                            $scope.email_send,
                            $scope.email_subject,
                            $scope.email_body,
                            selected_attachments,
                            $scope.verify_email_send,
                            $scope.followed_up_with_fsp_yn,
                            $scope.followed_up_with_fsp_on,
                            $scope.followed_up_with_fsca_yn,
                            $scope.followed_up_with_fsca_on,
                            tokenId, $scope.visitorId)
                        .then(() => {

                            $('#modalInfoHeader').html('Thank you');
                            $('#modalInfoText').html($scope.verify_email_send ? 'The profile change has been saved, and emails sent.' : 'The profile change has been saved.');
                            $('#information_modal').modal('open');

                            setTimeout(function () {
                                $('#information_modal').modal('close');
                                $scope.backToDetailsPage();
                            }, 2000);
                        })
                        .catch(err => $scope.checkErrStatus(err));

                })
                .catch(err => $scope.checkErrStatus(err));

        }

    });