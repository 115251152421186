angular.module('ics')
    .controller('HomeController', function ($state, $scope, $rootScope, $controller) {

    angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

    if ($rootScope.loggedInUserRole && $rootScope.loggedInUserRole.userRole !== null) {
        $state.transitionTo('admin-dashboard');
    } else {
        $state.transitionTo('signin');
    }

});