angular.module('ics')

    .controller('RecycleBinController', function ($scope, $state, $controller,
                                                  $timeout, RecycleBinService,
                                                  FirebaseAuthService) {

        $scope.recycleBin = [];
        $scope.tokenId = '';
        $scope.processing = true;
        $scope.limit = 10;
        const now = new Date();

        $scope.days = [];
        for (let i = 1; i <= 31; i++) $scope.days.push(i);
        $scope.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        $scope.years = [];
        for (let i = now.getFullYear() - 20; i <= now.getFullYear() + 5; i++) $scope.years.push(i);

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        async function init() {
            try {
                await retrieveRecycleBin();
            } catch (e) {
                $scope.checkErrStatus(e);
            }
        }

        init();

        async function retrieveRecycleBin() {

            $scope.tokenId = await FirebaseAuthService.getUserToken();
            $scope.processing = true;

            const res = await RecycleBinService.getRecycleBin($scope.tokenId, $scope.visitorId, $scope.limit);
            $scope.recycleBin = res.data;
            $scope.$applyAsync();
            $scope.processing = false;

            $(document).ready(function () {
                $('.tabs').tabs();
            });
        }

        $scope.putBack = function (entityType, entityId) {

            $('#modalConfirmHeader').html('PUTTING BACK');
            $('#modalConfirmText').html('Are you sure you want to remove this item from the recycle bin?');

            const fn = async function () {

                try {

                    $scope.tokenId = await FirebaseAuthService.getUserToken();
                    await RecycleBinService.putBack(entityType, entityId, $scope.tokenId, $scope.visitorId);
                    await retrieveRecycleBin();

                } catch (e) {
                    $scope.checkErrStatus(e);
                }

            };

            const event = fn.bind({entityType: entityType, entityId: entityId});

            $('#modalAgreeLink').click(function () {
                event();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.getAllRecycleBin = async function () {
            $scope.limit = 100;
            await retrieveRecycleBin();
        };
    });
