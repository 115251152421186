angular.module('ics')
    .controller('FSPDocsAdminController', function ($scope, $state, $controller, $timeout, CategoriesService,
                                                     FSPDocsService, FirebaseAuthService, FirebaseStorageService) {

    $scope.categoryTypeCode = $state.params.category_type_code;
    $scope.documentTypeTitle = $state.params.document_type_title;
    $scope.fspId = $state.params.fsp_id;
    $scope.selectedCategoryDocuments = [];
    $scope.selectedCategoryTitle = '';

    $scope.editing = false;
    $scope.viewing = false;
    $scope.processing = true;

    angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

    FirebaseAuthService.getUserToken().then(tokenId => {
        $scope.tokenId = tokenId;
        $scope.init();
    });

    $scope.init = function () {

        retrieveData();

        setTimeout(function () {

            $(document).ready(function () {

                let docUploadControl = document.getElementById("documentUpload");

                docUploadControl.addEventListener('change', function (e) {
                    let file = e.target.files[0];
                    if (!file || !file.name) return;
                    $('.file-field #uploading-document').removeClass('ng-hide');
                    $('.file-field .btn').addClass('ng-hide');
                    FirebaseStorageService.uploadFileToCloudStorage(`/fsp_documents/${$scope.fspId}`, file, null).then(
                        (storageInfo) => {
                            $('.file-field #uploading-document').addClass('ng-hide');
                            $('.file-field .btn').removeClass('ng-hide');
                            $scope.documentUrl = storageInfo.downloadUrl;
                            $scope.documentName = file.name;
                            $scope.$apply();
                        }, (errMessage) => {
                            $('.file-field #uploading-document').addClass('ng-hide');
                            $('.file-field .btn').removeClass('ng-hide');
                            alert(errMessage);
                        });
                });

            });

        }, 2000);

    };

    function retrieveData() {

        $scope.processing = true;
        $scope.editing = false;
        $scope.viewing = false;

        CategoriesService.getCategoriesListFromCode($scope.categoryTypeCode, $scope.tokenId, $scope.visitorId).then(res => {

            $scope.categories = res.data.categories;

            if ($scope.categories.length == 0) {
                $scope.processing = false;
                return;
            }

            let typeId = $scope.categories[0].category_type.id;

            FSPDocsService.getDocumentsFromCategoryType($scope.fspId, typeId, $scope.tokenId, $scope.visitorId).then(res => {

                console.log(res);
                $scope.processing = false;
                $scope.documents = res.data.documents;

                $scope.categories.forEach(category => {
                    category.documents = $scope.documents.filter(document => document.category.id === category.id);
                });

            });

        }, reject => {
            $scope.processing = false;
            if (reject.status === 403) {
                location.reload();
            }
        });
    }

    $scope.viewCategoryDocuments = function (index) {
        $('body').addClass('bg-grey-1');
        $scope.selectedCategoryId = $scope.categories[index].id;
        $scope.selectedCategoryTitle = $scope.categories[index].name;
        $scope.selectedCategoryDocuments = $scope.categories[index].documents;
        $scope.editing = false;
        $scope.viewing = true;
    };

    $scope.addDocument = function () {
        $('body').removeClass('bg-grey-1');
        $scope.editing = true;
        $scope.viewing = false;
    };

    $scope.goBack = function () {
        $('body').addClass('bg-grey-1');
        $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
    };

    $scope.saveDocument = function (documentName, documentUrl, selectedCategoryId) {
        if (documentName.length === 0 || documentUrl.name === 0) {
            return;
        }

        FirebaseAuthService.getUserToken().then(tokenId => {

            $scope.processing = true;

            FSPDocsService.saveCategoryDocument($scope.fspId, selectedCategoryId,
                                                documentUrl, documentName,
                                                tokenId, $scope.visitorId).then((res) => {

                retrieveData();

            }, reject => {
                $scope.processing = false;
                if (reject.status === 403) {
                    location.reload();
                }
            });

        });
    };

    $scope.cancelViewing = function () {
        $('body').addClass('bg-grey-1');
        $scope.editing = false;
        $scope.viewing = false;
    };

    $scope.cancelEditing = function () {
        $('body').addClass('bg-grey-1');
        $scope.editing = false;
        $scope.viewing = true;
    };

    $scope.deleteCategoryDocument = function (docId, index) {

        $('#modalConfirmHeader').html('Deleting Document');
        $('#modalConfirmText').html('Are you sure you want to delete this item?');

        let deleteDoc = function () {

            FirebaseAuthService.getUserToken().then(tokenId => {

                FSPDocsService.deleteCategoryDocument(docId, tokenId, $scope.visitorId).then(function () {
                    $scope.processing = false;
                    $scope.selectedCategoryDocuments.splice(index, 1);
                    reset();
                }, reject => {
                    if (reject.status === 403) {
                        $state.transitionTo('signin');
                    }
                });

            });

        };

        let deletionEvent = deleteDoc.bind({docId: docId});

        $(document).ready(function() {

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        });

    }

});