angular.module('ics')

    .factory('FSPDocsService', function ($http) {

        return {

            getDocumentsFromCategoryType: function (fspId, categoryTypeId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp/documents/${fspId}/${categoryTypeId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            deleteCategoryDocument: function (docId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'DELETE',
                    url: `/api/fsp/document/${docId}`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000
                };

                return $http(req);

            },

            saveCategoryDocument: function (fspId, categoryId, documentUrl, documentName, firebaseTokenId, visitorId) {

                var req = {
                    method: 'POST',
                    url: `/api/fsp/document/`,
                    headers: {
                        'x-firebase-token': firebaseTokenId,
                        'Content-Type': 'application/json',
                        'VisitorId': visitorId || getCookie("ics-visitor-id"),
                        'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                    },
                    timeout: 8000,
                    data: {
                        fsp_id: fspId,
                        category_id: categoryId,
                        document_url: documentUrl,
                        document_name: documentName
                    }
                };

                return $http(req);


            },


        }

    });
