angular.module('ics')
    .controller('ComplianceDocumentTypesAdminController', function ($scope, $state, $controller,
                                                                    ComplianceDocumentTypesService, FirebaseAuthService) {

        $scope.documentTypeId = '';
        $scope.documentType = '';
        $scope.code = '';
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService
            .getUserToken()
            .then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.init();
            })
            .catch(err => $scope.checkErrStatus(err));

        $scope.init = function () {

            $scope.processing = true;

            ComplianceDocumentTypesService.getComplianceDocumentTypesList($scope.tokenId, $scope.visitorId)
                .then((response) => {
                    $scope.documentTypesList = response.data.document_types;
                    $scope.documentTypesList.sort((a, b) => {
                        const textA = a.document_type.toUpperCase();
                        const textB = b.document_type.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    reset();
                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.saveDocumentType = function (documentType, code) {

            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.documentTypeId === '') {
                ComplianceDocumentTypesService
                    .createComplianceDocumentType(documentType, code, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err))

            } else {
                ComplianceDocumentTypesService
                    .editComplianceDocumentType($scope.documentTypeId, documentType, code, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteDocumentType = function (documentTypeId, documentType, index) {

            $('#modalConfirmHeader').html('Deleting Document Type');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                ComplianceDocumentTypesService
                    .deleteComplianceDocumentType(this.documentTypeId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({documentTypeId: documentTypeId});

            $(document).ready(function () {

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            });

        };

        $scope.addEditDocumentTemplates = function (documentTypeId, documentTypeName) {

            $state.transitionTo('admin-compliance-templates', {document_type_id: documentTypeId, document_type_name: documentTypeName});

        };

        $scope.editDocumentType = function (documentTypeId, index) {
            $('body').removeClass('bg-grey-1');
            $scope.documentTypeId = documentTypeId;
            $scope.documentType = $scope.documentTypesList[index].document_type;
            $scope.code = $scope.documentTypesList[index].code;
            $scope.editing = true;
        };

        $scope.addDocumentType = function () {
            $('body').removeClass('bg-grey-1');
            $scope.documentTypeId = '';
            $scope.documentType = '';
            $scope.code = '';
            $scope.editing = true;
            $scope.errorMessage = '';
        };

        $scope.cancelSaveDocumentType = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset() {
            $scope.processing = false;
            $scope.documentTypeId = '';
            $scope.documentType = '';
            $scope.code = '';
            $scope.editing = false;
            $scope.errorMessage = '';
        }

    });