angular.module('ics')

    .controller('FSPThirdPartyLispsAdminController', function ($scope, $state, $controller,
                                                                        $timeout, FSPThirdPartyLispsService,
                                                                        FirebaseAuthService, FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = $state.params.fsp_name;
        $scope.third_party_lisps = [];
        $scope.third_party_lisp = {
            id: 0,
            lisps: '',
            client_administered: '',
            contact_person: '',
            date_appointed: new Date(),
            attachments: []

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - third-party LISPs`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init() {

            retrieveThirdPartyLisps();

            setTimeout(function () {
                $(document).ready(function () {

                    const docUploadControl = document.getElementById("documentUpload");

                    const fileDetails = {
                        folder: `/fsp_third_party_lisp_attachments/${$scope.fspId}`,
                        attachments: $scope.third_party_lisp.attachments
                    };

                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                    docUploadControl.addEventListener('change', eventDelegator);

                    $scope.$on('filesUploaded', function (event, attachments) {
                        const combined = new Set($scope.third_party_lisp.attachments.concat(attachments));
                        $scope.third_party_lisp.attachments = [...combined];
                        $scope.$apply();
                    });
                })
            }, 1000);

        };

        function retrieveThirdPartyLisps() {

            $scope.processing = true;
            $scope.editing = false;

            FSPThirdPartyLispsService
                .getThirdPartyLispsList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.third_party_lisps = res.data.third_party_lisps;
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.editThirdPartyLisp = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit third party LISP';

            const third_party_lisp = $scope.third_party_lisps[index];
            $scope.third_party_lisp = {
                id: third_party_lisp.id,
                lisps: third_party_lisp.lisps,
                client_administered: third_party_lisp.client_administered,
                contact_person: third_party_lisp.contact_person,
                attachments: third_party_lisp.attachments

            };
            $scope.editing = true;
        };

        $scope.addThirdPartyLisp = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add third party lisp';

            $scope.third_party_lisp = {
                id: 0,
                lisps: '',
                client_administered: '',
                contact_person: '',
                attachments: []
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - third-party LISPs`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.saveThirdPartyLisp = function (id, lisps, client_administered, contact_person, attachments) {

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {

                    $scope.processing = true;
                    $scope.third_party_lisp.date_appointed = $('#date_appointed').val();

                    if (id) {

                        FSPThirdPartyLispsService
                            .editThirdPartyLisp(id, lisps, client_administered, contact_person, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPThirdPartyLispsService
                            .createThirdPartyLisp($scope.fspId, lisps, client_administered, contact_person, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

        };

        $scope.cancelEditing = function ($event) {

            $event.preventDefault();
            $('body').addClass('bg-grey-1');
            $scope.editing = false;
            $scope.title = `${$scope.fspName} - third_party_lisps`;

        };

        $scope.deleteThirdPartyLisp = function (third_party_lispId, index) {

            $('#modalConfirmHeader').html('DELETING ADMINISTRATOR');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPThirdPartyLispsService
                    .deleteThirdPartyLisp(this.third_party_lispId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())


            };

            let deletionEvent = deleteType.bind({third_party_lispId: third_party_lispId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.removeAttachment = function (index) {
            const filesUploadedIndex = filesUploaded.findIndex(item => item === $scope.third_party_lisp.attachments[index].file_name);
            if (filesUploadedIndex !== -1) {
                filesUploaded.splice(filesUploadedIndex, 1);
            }
            $scope.third_party_lisp.attachments.splice(index, 1);
        }

    });
