angular.module('ics')
    .controller('ExternalAuditorDetailsAdminController', function ($scope, $state, $controller, $timeout,
                                                                    FSPListsService,
                                                                    FirebaseAuthService,
                                                                    FirebaseStorageService) {

    $scope.itemId = $state.params.item_id;
    $scope.fspId = $state.params.fsp_id;
    $scope.fspName = $state.params.fsp_name;
    $scope.errorMessage = '';
    $scope.item = {

        id: $scope.itemId,
        name: '',
        responsible_partner: '',
        contact_number: '',
        practice_number: '',
        appointment_date: new Date(),
        attachments: []

    };
    $scope.processing = true;

    angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

    function init() {

        return new Promise((resolve, reject) => {

            if ($scope.itemId) {

                FirebaseAuthService.getUserToken().then(tokenId => {

                    FSPListsService.getExternalAuditorDetails($scope.itemId, tokenId, $scope.visitorId).then(res => {

                        const item = res.data.item;

                        $scope.item.name = item.name;
                        $scope.item.responsible_partner = item.responsible_partner;
                        $scope.item.contact_number = item.contact_number;
                        $scope.item.practice_number = item.practice_number;
                        $scope.item.appointment_date = new Date(item.appointment_date);
                        $scope.item.attachments = item.attachments;
                        resolve($scope.item);

                    });

                });
            } else {
                resolve($scope.item);
            }

        });

    }

    $(document).ready(function () {

        init().then(tokenId => {

            $scope.processing = false;

            let $appointmentDateInput = $('#appointment_date').pickadate({
                selectMonths: true, // Creates a dropdown to control month
                selectYears: 20, // Creates a dropdown of 20 years to control year
                format: "yyyy-mm-dd"
            });

            let datePicker = $appointmentDateInput.pickadate('picker');
            datePicker.set('select', new Date($scope.item.appointment_date));

            let docUploadControl = document.getElementById("documentUpload");

            docUploadControl.addEventListener('change', function (e) {
                let file = e.target.files[0];
                if (!file || !file.name) return;
                jQuery('#uploading-document').removeClass('hidden');
                FirebaseStorageService.uploadFileToCloudStorage("/external_auditor_attachments/", file, null).then(
                    (storageInfo) => {

                        jQuery('#uploading-document').addClass('hidden');
                        $scope.item.attachments.push({
                            file_url: storageInfo.downloadUrl,
                            file_name: file.name,
                            attach_to_email: true
                        }
                                                    );
                        $scope.$apply();

                    }, (errMessage) => {
                        jQuery('#uploading-document').addClass('hidden');
                        console.log(errMessage);
                        alert("An error occurred, please try again");
                        $scope.processing = false;
                    });
            });


        }, error => {
            alert('An error occurred, please try again');
            $scope.processing = false;
        });

    });


    $scope.removeDoc = function (index) {
        $scope.item.attachments.splice(index, 1);
    };

    $scope.saveItem = function (id, name, responsible_partner, contact_number, practice_number) {

        $scope.processing = true;

        FirebaseAuthService.getUserToken().then(tokenId => {

            let appointment_date = $('#appointment_date').val();

            if ($scope.item.id) {

                FSPListsService.editExternalAuditor($scope.item.id, $scope.fspId, name, responsible_partner,
                                                    contact_number, practice_number, appointment_date, $scope.item.attachments, tokenId, $scope.visitorId).then(res => {
                    success(false);
                });

            } else {

                FSPListsService.createExternalAuditor($scope.fspId, name, responsible_partner,
                                                      contact_number, practice_number, appointment_date, $scope.item.attachments, tokenId, $scope.visitorId).then(res => {
                    success(true);
                });
            }

        });

    };

    function success(added) {

        $('#modalInfoHeader').html('THANK YOU');
        $('#modalInfoText').html('The auditor has been ' + (added ? 'added.' : 'edited.'));
        $('#information_modal').modal('open');

        setTimeout(function () {
            $('#information_modal').modal('close');
            $('body').addClass('bg-grey-1');
            $state.transitionTo('external-auditor-list', {fsp_id: $scope.fspId, fsp_name: $scope.fspName});
        }, 2000);

    }

});