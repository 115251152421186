angular.module('ics')
    .controller('CategoryTypesAdminController', function ($scope, $state, $controller,
                                                          $timeout, CategoryTypesService, FirebaseAuthService) {

        $scope.item = {
            categoryTypeId : '',
            categoryType : '',
            code : ''
        };

        $scope.editing = false;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $scope.init = function () {

            $scope.processing = true;

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {
                    return CategoryTypesService.getCategoryTypesList(tokenId, $scope.visitorId)
                })
                .then((response) => {
                    $scope.categoryTypesList = response.data.category_types;
                    $scope.$applyAsync();
                    $scope.categoryTypesList.sort((a, b) => {
                        const textA = a.category_type.toUpperCase();
                        const textB = b.category_type.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    reset();
                })
                .catch(err => $scope.checkErrStatus(err));


        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveCategoryType($scope.item.categoryType, $scope.item.code);
            }
        };

        $scope.saveCategoryType = function (categoryType, code) {

            $scope.errorMessage = '';
            $scope.processing = true;

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {

                    if ($scope.item.categoryTypeId === '') {
                        CategoryTypesService
                            .createCategoryType(categoryType, code, tokenId, $scope.visitorId)
                            .then(location.reload())
                            .catch(err => $scope.checkErrStatus(err))

                    } else {
                        CategoryTypesService
                            .editCategoryType($scope.item.categoryTypeId, categoryType, code, tokenId, $scope.visitorId)
                            .then(location.reload())
                            .catch(err => $scope.checkErrStatus(err))
                    }

                });

        };

        $scope.addEditCategories = function (categoryTypeId, categoryTypeName) {

            $state.transitionTo('admin-categories', {
                category_type_id: categoryTypeId,
                category_type_name: categoryTypeName
            });

        };

        $scope.deleteCategoryType = function (categoryTypeId, index) {

            $('#modalConfirmHeader').html('Deleting Listing');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FirebaseAuthService.getUserToken()
                    .then(tokenId => CategoryTypesService.deleteCategoryType(this.categoryTypeId, tokenId, $scope.visitorId))
                    .then(location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            };

            const deletionEvent = deleteType.bind({categoryTypeId: categoryTypeId});

            $(document).ready(function () {

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            });

        };

        $scope.editCategoryType = function (categoryTypeId, index) {
            $('body').removeClass('bg-grey-1');
            $scope.item.categoryTypeId = categoryTypeId;
            $scope.item.categoryType = $scope.categoryTypesList[index].category_type;
            $scope.item.code = $scope.categoryTypesList[index].code;
            $scope.editing = true;
        };

        $scope.addCategoryType = function () {
            $('body').removeClass('bg-grey-1');
            $scope.item.categoryTypeId = '';
            $scope.item.categoryType = '';
            $scope.item.code = '';
            $scope.editing = true;
            $scope.errorMessage = '';
        };

        $scope.cancelSaveCategoryType = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset () {
            $scope.processing = false;
            $scope.item.categoryTypeId = '';
            $scope.item.categoryType = '';
            $scope.item.code = '';
            $scope.editing = false;
            $scope.errorMessage = '';
        }

        $scope.init();

    });