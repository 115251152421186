angular.module('ics')

    .controller('FSPReportHistoryController', function ($scope, $state, FirebaseAuthService, FSPReportsService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.reports = [];
        $scope.processing = true;

        function init() {

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => {
                    FSPReportsService
                        .getReportHistory($scope.fspId, tokenId, $scope.visitorId)
                        .then(details => {
                            $scope.reports = details.data.reports;
                            $scope.processing = false;
                        })

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $(document).ready(function () {
            init();
        });

        $scope.goBack = function () {
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

    });
