angular.module('ics')

    .controller('FSPDirectorsAdminController', function ($scope, $state, $controller,
                                                         $timeout, FSPDirectorsService,
                                                         FirebaseAuthService, FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.directors = [];
        $scope.director = {
            id: 0,
            first_name: '',
            last_name: '',
            id_number: '',
            date_appointed: new Date(),
            attachments: []

        };
        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - administer directors`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init() {

            retrieveDirectors();

            setTimeout(function () {
                $(document).ready(function () {

                    // let $dateInput = $('#date_appointed').pickadate({
                    //     selectMonths: true, // Creates a dropdown to control month
                    //     selectYears: 100, // Creates a dropdown of 100 years to control year
                    //     format: "yyyy-mm-dd"
                    // });
                    //
                    // let datePicker = $dateInput.pickadate('picker');
                    // datePicker.set('select', $scope.director.date_appointed);

                    const docUploadControl = document.getElementById("documentUpload");

                    const fileDetails = {
                        folder: `/fsp_director_attachments/${$scope.fspId}`,
                        attachments: $scope.director.attachments
                    };

                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                    docUploadControl.addEventListener('change', eventDelegator);

                    $scope.$on('filesUploaded', function (event, attachments) {
                        const combined = new Set($scope.director.attachments.concat(attachments));
                        $scope.director.attachments = [...combined];
                        $scope.$apply();
                    });

                })
            }, 1000);

        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveDirector($scope.director.id, $scope.director.first_name, $scope.director.last_name,
                    $scope.director.id_number, $scope.director.attachments);
            }
        };

        function retrieveDirectors() {

            $scope.processing = true;
            $scope.editing = false;

            FSPDirectorsService.getDirectorsList($scope.fspId, $scope.tokenId, $scope.visitorId)
                .then(res => {

                    $scope.directors = res.data.directors;
                    $scope.directors.sort((a, b) => {
                        const textA = a.last_name.toUpperCase();
                        const textB = b.last_name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    $scope.directors.forEach(director => {
                        director.attachments.sort((a, b) => {
                            const textA = a.file_name.toUpperCase();
                            const textB = b.file_name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                    });
                    $scope.processing = false;

                })
                .catch(err => $scope.checkErrStatus(err));
        }

        $scope.editDirector = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit director';

            const director = $scope.directors[index];
            $scope.director = {
                id: director.id,
                first_name: director.first_name,
                last_name: director.last_name,
                id_number: director.id_number,
                date_appointed: new Date(director.date_appointed),
                attachments: director.attachments

            };
            $scope.editing = true;
        };

        $scope.addDirector = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add director';

            $scope.director = {
                id: 0,
                first_name: '',
                last_name: '',
                id_number: '',
                date_appointed: new Date(),
                attachments: []
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer directors`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
        };

        $scope.saveDirector = function (id, first_name, last_name, id_number, attachments) {

            FirebaseAuthService.getUserToken()
                .then(tokenId => {

                    $scope.director.date_appointed = $('#date_appointed').val();

                    if (Math.abs(moment(new Date($scope.director.date_appointed)).diff(moment(), 'years')) > 100) {
                        return alert('the date appointed field appears to be incorrect!');
                    }

                    $scope.processing = true;

                    if (id) {

                        FSPDirectorsService
                            .editDirector(id, first_name, last_name, id_number, $scope.director.date_appointed, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPDirectorsService
                            .createDirector($scope.fspId, first_name, last_name, id_number, $scope.director.date_appointed, attachments, tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

        };

        $scope.cancelEditing = function ($event) {
            $('body').addClass('bg-grey-1');
            $event.preventDefault();

            $scope.editing = false;
            $scope.title = `${$scope.fspName} - administer directors`;

        };

        $scope.deleteDirector = function (directorId, index) {

            $('#modalConfirmHeader').html('DELETING DIRECTOR');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteType = function () {

                FSPDirectorsService.deleteDirector(this.directorId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            let deletionEvent = deleteType.bind({directorId: directorId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

    });
