angular.module('ics')
    .controller('FSPFollowUpProfileChangeController', function ($scope, $state, $controller, $timeout,
                                                                FSPService, CategoriesService, FollowUpEmailTemplatesService,
                                                                FirebaseAuthService, FirebaseStorageService) {

        $scope.profileChangeId = '';
        $scope.fspId = '';
        $scope.errorMessage = '';
        $scope.tokenId = '';
        $scope.profileChangeStatusCategories = [];
        $scope.processing = false;
        $scope.emailTemplatesFullList = [];
        $scope.emailTemplates = [];
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $(document).ready(function () {

            FirebaseAuthService.getUserToken()
                .then(tokenId => {
                    $scope.tokenId = tokenId;
                    $scope.processing = true;

                    $scope.profileChangeId = $state.params.profile_change_id;
                    $scope.fspId = $state.params.fsp_id;

                    FirebaseAuthService.getUserToken()
                        .then(() => {

                            FSPService.GetFSPChangeDetails($scope.profileChangeId, $scope.tokenId, $scope.visitorId)
                                .then((response) => {

                                    $scope.fspChangeDetails = response.data.status_change;
                                    $scope.fspChangeDetails.follow_up_yn = false;
                                    $scope.fspChangeDetails.follow_up_on = $scope.fspChangeDetails.follow_up_on ? new Date($scope.fspChangeDetails.follow_up_on) : new Date();

                                    $timeout(function () {
                                        $('textarea').trigger('autoresize');
                                        $('#email_body').trigger('autoresize');
                                    });

                                    $scope.processing = false;

                                    // let $followedUpDateInput = $('#followed_up_date').pickadate({
                                    //     selectMonths: true, // Creates a dropdown to control month
                                    //     selectYears: 20, // Creates a dropdown of 20 years to control year
                                    //     format: "yyyy-mm-dd"
                                    // });
                                    //
                                    // let datePicker = $followedUpDateInput.pickadate('picker');
                                    // datePicker.set('select', new Date($scope.fspChangeDetails.follow_up_on));

                                    CategoriesService.getCategoriesListFromCode('profile_change_status', $scope.tokenId, $scope.visitorId).then(res => {
                                        $scope.profileChangeStatusCategories = res.data.categories;
                                        $timeout(function () {
                                            $('select').material_select();
                                        });
                                    });

                                    FollowUpEmailTemplatesService.getFollowUpEmailTemplatesList($scope.tokenId, $scope.visitorId).then(response => {

                                        response.data.follow_up_email_templates.forEach(template => {

                                            template.profile_change_types.forEach(changeType => {
                                                if (parseInt(changeType.id, 10) === parseInt($scope.fspChangeDetails.type.id)) {

                                                    $scope.emailTemplatesFullList.push(template);
                                                    $scope.emailTemplates.push(template);

                                                }
                                            });

                                        });

                                        $timeout(function () {
                                            $('select').material_select();
                                        });

                                    });

                                    let docUploadControl = document.getElementById("documentUpload");

                                    docUploadControl.addEventListener('change', function (e) {
                                        let file = e.target.files[0];
                                        if (!file || !file.name) return;
                                        $('.file-field #uploading-document').removeClass('ng-hide');
                                        $('.file-field .btn').addClass('ng-hide');
                                        FirebaseStorageService.uploadFileToCloudStorage("/fsp_profile_change_attachments/", file, null).then(
                                            (storageInfo) => {
                                                $('.file-field #uploading-document').addClass('ng-hide');
                                                $('.file-field .btn').removeClass('ng-hide');
                                                $scope.fspChangeDetails.attachments.push({
                                                    file_url: storageInfo.downloadUrl,
                                                    file_name: file.name,
                                                    attach_to_email: true,
                                                    id: '',
                                                });
                                                $scope.$apply();
                                            }, (errMessage) => {
                                                $('.file-field #uploading-document').addClass('ng-hide');
                                                $('.file-field .btn').removeClass('ng-hide');
                                                console.log(errMessage);
                                                alert("An error occurred, please try again");
                                                $scope.processing = false;
                                            });
                                    });

                                });

                        });

                })
                .catch(err => $scope.checkErrStatus(err));

        });

        $scope.selectEmailTemplate = function () {
            $scope.email_subject = '';
            $scope.email_body = '';

            $scope.emailTemplates.forEach(template => {
                if (template.id === $scope.email_template) {
                    $scope.email_subject = template.email_subject;
                    $scope.email_body = template.email_body;
                    $timeout(function () {
                        $('textarea').trigger('autoresize');
                        $('#email_body').trigger('autoresize');
                    }, 500);
                }
            });
        };

        $scope.sendEmail = function () {

            $scope.processing = true;

            FirebaseAuthService.getUserToken().then(tokenId => {

                $scope.followed_up_date = $('#followed_up_date').val();

                FSPService.followUpFSPProfileChange($scope.fspId, $scope.fspChangeDetails.id,
                    $scope.fspChangeDetails.status.id,
                    $scope.fspChangeDetails.follow_up_yn,
                    $scope.followed_up_date,
                    $scope.fspChangeDetails.description,
                    $scope.fspChangeDetails.attachments,
                    $scope.email_subject,
                    $scope.email_body,
                    $scope.fspChangeDetails.email_body,
                    tokenId, $scope.visitorId)
                    .then(() => {

                        $scope.processing = false;

                        $('#modalInfoHeader').html('Thank you');
                        $('#modalInfoText').html('The follow up has been submitted.');
                        $('#information_modal').modal('open');

                        setTimeout(function () {
                            $('#information_modal').modal('close');
                            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
                        }, 2000);

                    })
                    .catch(err => $scope.checkErrStatus(err));

            });

        };

    });