angular.module('ics')
    .controller('FSPKeyIndividualsAdminController', function ($scope, $state, $controller,
                                                              $timeout, FSPKeyIndividualsService,
                                                              CategoriesService, SubCategoriesService,
                                                              FirebaseAuthService, FirebaseStorageService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.category_of_licensing = null;

        $scope.licensingCategories = [];
        $scope.financialProducts = [];
        $scope.classesOfBusiness = [];
        $scope.subClassesOfBusiness = [];
        $scope.key_individuals = [];

        $scope.key_individual = {
            id: 0,
            first_name: '',
            last_name: '',
            id_number: '',
            categories_of_licensing: [],
            financial_products: [],
            date_appointed: new Date(),
            classes_of_business: [],
            sub_classes_of_business: [],
            attachments: [],
            passed_re1: false,
            passed_re3: false,
            passed_re5: false,
            has_qualification: false,
            qualification_code: '',
            qualification_name: ''
        };

        $scope.editing = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - administer key individuals`;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', { $scope: $scope }));

        FirebaseAuthService.getUserToken().then(async tokenId => {
            $scope.tokenId = tokenId;
            await init();
        });

        async function init() {

            await retrieveKeyIndividuals();

            setTimeout(function () {
                $(document).ready(function () {

                    const docUploadControl = document.getElementById("documentUpload");

                    const fileDetails = {
                        folder: `/fsp_key_individual_attachments/${$scope.fspId}`,
                        attachments: $scope.key_individual.attachments
                    };

                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                    docUploadControl.addEventListener('change', eventDelegator);

                    $scope.$on('filesUploaded', function (event, attachments) {
                        const combined = new Set($scope.key_individual.attachments.concat(attachments));
                        $scope.key_individual.attachments = [...combined];
                        $scope.$apply();
                    });

                })
            }, 1000);

        }

        async function retrieveKeyIndividuals() {

            try {

                $scope.processing = true;
                $scope.editing = false;

                const res = await FSPKeyIndividualsService.getKeyIndividualsList($scope.fspId, $scope.tokenId, $scope.visitorId);

                $scope.key_individuals = res.data.key_individuals;

                $scope.key_individuals.forEach(key_individual => {
                    key_individual.attachments.sort((a, b) => {
                        const textA = a.file_name.toUpperCase();
                        const textB = b.file_name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                });

                const res1 = await CategoriesService.getCategoriesListFromCode('licencing_categories', $scope.tokenId, $scope.visitorId);
                $scope.licensingCategories = res1.data.categories;

                $scope.licensingCategories
                    .sort((a, b) => a.display_order - b.display_order)
                    .forEach(item => item.selected = false);

                const res2 = await CategoriesService.getCategoriesListFromCode('financial_products', $scope.tokenId, $scope.visitorId);
                $scope.financialProducts = res2.data.categories;

                $scope.financialProducts
                    .sort((a, b) => a.display_order - b.display_order)
                    .forEach(item => item.selected = false);

                const res3 = await CategoriesService.getCategoriesListFromCode('COB', $scope.tokenId, $scope.visitorId);
                $scope.classesOfBusiness = res3.data.categories;

                $scope.classesOfBusiness
                    .sort((a, b) => a.display_order - b.display_order)
                    .forEach(item => {
                        item.selected = false;
                        item.subClasses = [];
                    });

                for (const category of $scope.classesOfBusiness) {
                    const subCategoriesRes = await SubCategoriesService.getSubCategoriesList(category.id, $scope.tokenId, $scope.visitorId);
                    let subClassesOfBusiness = [];
                    subClassesOfBusiness = subClassesOfBusiness.concat(subCategoriesRes.data.sub_categories || []);
                    subClassesOfBusiness.sort((a, b) => a.display_order - b.display_order).forEach(item => item.selected = false);
                    category.subClasses = subClassesOfBusiness || [];
                }

                $timeout(function () {
                    $('select').material_select();
                });

                $scope.processing = false;

            } catch (err) {
                $scope.checkErrStatus(err);
            }
        }

        $scope.editKeyIndividual = function (index) {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Edit key individual';

            const key_individual = $scope.key_individuals[index];

            $scope.licensingCategories.forEach(item => item.selected = false);
            $scope.financialProducts.forEach(item => item.selected = false);
            $scope.classesOfBusiness.forEach(item => {
                item.selected = false;
                item.subClasses.forEach(item2 => item2.selected = false);
            });

            key_individual.categories_of_licensing.forEach(item => {
                const licensingCategory = $scope.licensingCategories.find(category => category.id === item.id);
                if (licensingCategory) {
                    licensingCategory.selected = true;
                }
            });

            key_individual.financial_products.forEach(item => {
                const financialProduct = $scope.financialProducts.find(product => product.id === item.id);
                if (financialProduct) {
                    financialProduct.selected = true;
                }
            });

            key_individual.classes_of_business.forEach(item => {
                const classOfBusiness = $scope.classesOfBusiness.find(businessClass => businessClass.id === item.id);
                if (classOfBusiness) {
                    classOfBusiness.selected = true;

                    (key_individual.sub_classes_of_business || []).forEach(item => {

                        const subClassOfBusiness = classOfBusiness.subClasses.find(businessClass => businessClass.id === item.id);
                        if (subClassOfBusiness) {
                            subClassOfBusiness.selected = true;
                        }
                    });
                }
            });

            $scope.key_individual = {
                id: key_individual.id,
                first_name: key_individual.first_name,
                last_name: key_individual.last_name,
                id_number: key_individual.id_number,
                date_appointed: new Date(key_individual.date_appointed),
                attachments: key_individual.attachments,
                passed_re1: key_individual.passed_re1 || false,
                passed_re3: key_individual.passed_re3 || false,
                passed_re5: key_individual.passed_re5 || false,
                has_qualification: key_individual.has_qualification || false,
                qualification_code: key_individual.qualification_code || '',
                qualification_name: key_individual.qualification_name || ''
            };

            $timeout(function () {
                $('select').material_select();
            });

            $scope.editing = true;
        };

        $scope.addKeyIndividual = function () {
            $('body').removeClass('bg-grey-1');
            $scope.title = 'Add key individual';

            $scope.licensingCategories.forEach(item => item.selected = false);
            $scope.financialProducts.forEach(item => item.selected = false);
            $scope.classesOfBusiness.forEach(item => {
                item.selected = false;
                (item.subClasses || []).forEach(item2 => item2.selected = false);
            });

            $scope.key_individual = {
                id: 0,
                first_name: '',
                last_name: '',
                id_number: '',
                date_appointed: new Date(),
                attachments: [],
                classes_of_business: [],
                sub_classes_of_business: [],
                passed_re1: false,
                passed_re3: false,
                passed_re5: false,
                has_qualification: false,
                qualification_code: '',
                qualification_name: ''
            };

            $scope.editing = true;

        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            if ($scope.editing) {
                $scope.title = `${$scope.fspName} - administer key individuals`;
                return $scope.editing = false;
            }
            $state.transitionTo('admin-fsp-details', { fsp_id: $scope.fspId });
        };

        $scope.submitForm = function ($form) {

            if ($form.$valid) {
                $scope.saveKeyIndividual();
            }

        }

        $scope.saveKeyIndividual = function () {

            try {

                FirebaseAuthService.getUserToken().then(tokenId => {

                    $scope.processing = true;
                    $scope.key_individual.date_appointed = $('#date_appointed').val();

                    const dateYear = (new Date($scope.key_individual.date_appointed)).getFullYear();
                    if (dateYear < 1900) {
                        $scope.errorMessage = 'Please ensure all dates are valid (i.e. after 1900)';
                        $scope.processing = false;
                        $scope.$applyAsync();
                        return;
                    }

                    const selectedLicensingCategories = $scope.licensingCategories
                        .filter(item => item.selected === true)
                        .map(item => item.id);

                    const selectedFinancialProducts = $scope.financialProducts
                        .filter(item => item.selected === true)
                        .map(item => item.id);

                    const selectedClassesOfBusiness = $scope.classesOfBusiness
                        .filter(item => item.selected === true)
                        .map(item => item.id);

                    let selectedSubClassesOfBusiness = [];
                    $scope.classesOfBusiness
                        .filter(item => item.selected === true)
                        .forEach(cob => {
                            selectedSubClassesOfBusiness = selectedSubClassesOfBusiness.concat(cob.subClasses
                                .filter(item => item.selected === true)
                                .map(item => item.id));
                        });

                    if ($scope.key_individual.id) {

                        FSPKeyIndividualsService.editKeyIndividual(
                            $scope.key_individual.id,
                            $scope.key_individual.first_name,
                            $scope.key_individual.last_name,
                            $scope.key_individual.id_number,
                            $scope.key_individual.date_appointed,
                            selectedLicensingCategories,
                            selectedFinancialProducts,
                            selectedClassesOfBusiness,
                            selectedSubClassesOfBusiness,
                            $scope.key_individual.attachments,
                            $scope.key_individual.passed_re1,
                            $scope.key_individual.passed_re3,
                            $scope.key_individual.passed_re5,
                            $scope.key_individual.has_qualification,
                            $scope.key_individual.qualification_code,
                            $scope.key_individual.qualification_name,
                            tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSPKeyIndividualsService.createKeyIndividual(
                            $scope.fspId,
                            $scope.key_individual.first_name,
                            $scope.key_individual.last_name,
                            $scope.key_individual.id_number,
                            $scope.key_individual.date_appointed,
                            selectedLicensingCategories,
                            selectedFinancialProducts,
                            selectedClassesOfBusiness,
                            selectedSubClassesOfBusiness,
                            $scope.key_individual.attachments,
                            $scope.key_individual.passed_re1,
                            $scope.key_individual.passed_re3,
                            $scope.key_individual.passed_re5,
                            $scope.key_individual.has_qualification,
                            $scope.key_individual.qualification_code,
                            $scope.key_individual.qualification_name,
                            tokenId, $scope.visitorId)
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    }

                });

            } catch (e) {
                $scope.checkErrStatus(e);
            }

        };

        $scope.cancelEditing = function ($event) {
            $('body').addClass('bg-grey-1');
            $event.preventDefault();

            $scope.editing = false;
            $scope.title = `${$scope.fspName} - administer key individuals`;
        };

        $scope.deleteKeyIndividual = function (keyIndividualId, index) {

            $('#modalConfirmHeader').html('DELETING KEY INDIVIDUAL');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSPKeyIndividualsService
                    .deleteKeyIndividual(this.keyIndividualId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({ keyIndividualId: keyIndividualId });

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };


    });
