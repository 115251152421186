angular.module('ics')
    .controller('FSPDocumentTreeAdminController', function ($rootScope, $scope, $state, $q, $sce, $controller, $timeout, CategoriesService,
                                                            FSPDocumentTreeService, FirebaseAuthService, FirebaseStorageService) {

        $scope.categoryTypeCode = $state.params.category_type_code;
        $scope.documentTypeTitle = $state.params.document_type_title;
        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.html = '';
        $scope.selectedFolderId = null;
        $scope.addFolderOrFile = false;
        $scope.processing = true;

        $scope.editFolderName = false;
        $scope.selectedEditFolderName = '';

        $scope.selectedFolderName = '';
        $scope.newSubfolder = '';
        const filesUploaded = [];

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            $scope.init();
        });

        $scope.init = function () {

            // retrieve folders and files from DB
            retrieveFSPFolders()
                .then(prepareTreeStructure);

            $(document).ready(function () {

                // prepare doc upload control
                let docUploadControl = document.getElementById("documentUpload");

                docUploadControl.addEventListener('change', function (e) {

                    const fileNames = [], firebasePromises = [], ndbPromises = [];

                    if (!e.target.files || e.target.files.length === 0) {
                        return;
                    }

                    for (var i = 0; i < e.target.files.length; i++) {
                        const file = e.target.files[i];
                        if (!file || !file.name) {
                            continue;
                        }
                        if (filesUploaded.find(item => item === file.name)) {
                            continue;
                        }
                        filesUploaded.push(file.name);
                        fileNames.push(file.name);
                        firebasePromises
                            .push(
                                FirebaseStorageService
                                    .uploadFileToCloudStorage(`/fsp_documents/${$scope.selectedFolderId}`,
                                        file, null));
                    }

                    if (firebasePromises.length === 0) {
                        return;
                    }

                    $('#or_parent').hide();
                    $('#add_folder_name_parent').hide();
                    $('#cancel_button_parent').hide();
                    $('.file-field #uploading-document').removeClass('ng-hide');
                    $('.file-field .btn').addClass('ng-hide');
                    $('.btn .width-fixed .bg-grey-5').addClass('ng-hide');

                    Promise
                        .all(firebasePromises)
                        .then(storageInfos => {

                            for (let i = 0; i < storageInfos.length; i++) {
                                ndbPromises.push(FSPDocumentTreeService.createFile($scope.fspId,
                                    $scope.selectedFolderId,
                                    fileNames[i],
                                    storageInfos[i].downloadUrl,
                                    $scope.tokenId, $scope.visitorId));
                            }

                            Promise
                                .all(ndbPromises)
                                .then(() => location.reload())
                                .catch((err) => {

                                    $('#or_parent').show();
                                    $('#add_folder_name_parent').show();
                                    $('#cancel_button_parent').show();
                                    $('.file-field #uploading-document').addClass('ng-hide');
                                    $('.file-field .btn').removeClass('ng-hide');
                                    $('.btn .width-fixed .bg-grey-5').removeClass('ng-hide');
                                    alert(err);

                                });

                        });


                });

            });

        };

        $scope.goBack = function () {
            if ($scope.editFolderName) {
                $scope.editFolderName = false;
                return;
            }
            if ($scope.addFolderOrFile) {
                $scope.addFolderOrFile = false;
                return;
            }
            $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});

        };

        // ********* F O L D E R  F U N C T I O N S **********

        $scope.clickFolder = function (folderId) {

            $('a[id ^= "addItem"]').each(function () {
                $(this).hide();
            });

            $('a[id ^= "deleteItem"]').each(function () {
                $(this).hide();
            });

            $('a[id ^= "editItem"]').each(function () {
                $(this).hide();
            });

            $('a[id ^= "viewFile"]').each(function () {
                $(this).hide();
            });

            $('a[id ^= "deleteFile"]').each(function () {
                $(this).hide();
            });

            $('.doc').each(function () {
                $(this).removeClass('bold').removeClass('clicked-folder');
            });

            $('li[role="treeitem"]').each(function () {
                $(this).find('p').removeClass('bold').removeClass('clicked-folder');
            });

            $(`#caption${folderId}`).addClass('bold').addClass('clicked-folder');

            if ($rootScope.isSuperAdmin) {
                $(`#addItem${folderId}`).show();
                $(`#deleteItem${folderId}`).show();
                $(`#editItem${folderId}`).show();
            }

            $scope.selectedFolderId = folderId;

        };

        $scope.addFolder = function (folderId) {

            $scope.selectedFolderId = folderId;
            $scope.addFolderOrFile = true;
            $scope.selectedFolderName = folderId ? '/' + $(`#caption${folderId}`).attr('title') : 'Create directory';
            if (folderId) {
                $scope.$apply();
            }
        };

        $scope.editFolder = function (folderId) {

            $scope.selectedFolderId = folderId;
            $scope.editFolderName = true;
            $scope.selectedEditFolderName = $(`#caption${folderId}`).attr('title');
            $scope.selectedFolderName = $scope.selectedEditFolderName;
            $scope.$applyAsync();
        };

        $scope.createSubFolder = function () {

            if ($scope.newSubfolder.trim().length === 0) {
                return;
            }
            const title = $scope.newSubfolder.trim() + '';
            $scope.processing = true;

            try {

                FSPDocumentTreeService
                    .createFolder($scope.fspId, $scope.selectedFolderId, $scope.newSubfolder.trim(), $scope.tokenId, $scope.visitorId)
                    .then((res) => {
                        retrieveFSPFolders()
                            .then(prepareTreeStructure)
                            .then(() => {
                                $scope.selectedFolderId = res.data.fsp_document_folder.id;
                                $scope.addFolderOrFile = true;
                                $scope.processing = false;
                                $scope.$applyAsync();

                                $timeout(() => {
                                    $('#selectedFolderName').html(`/${title}`);
                                    $scope.$apply();
                                }, 500);


                            });
                    });
            } catch (e) {
                $scope.checkErrStatus(e);
            }
        };

        $scope.saveFolder = function () {

            if ($scope.selectedEditFolderName.trim().length === 0) {
                return;
            }

            FSPDocumentTreeService
                .editFolder($scope.fspId, $scope.selectedFolderId, $scope.selectedEditFolderName.trim(), $scope.tokenId, $scope.visitorId)
                .then(() => location.reload());
        };

        $scope.deleteFolder = function (id, folderName) {

            $('#modalConfirmHeader').html('DELETING FOLDER');
            $('#modalConfirmText').html(`Are you sure you want to delete this folder - ${folderName}?`);

            let deleteItemCallback = function () {

                FirebaseAuthService.getUserToken().then(tokenId => {

                    FSPDocumentTreeService
                        .deleteFolder($scope.fspId, this.id, tokenId, $scope.visitorId)
                        .then(() => location.reload())
                        .catch(err => $scope.checkErrStatus(err));

                });

            };

            $('#modalAgreeLink').click(function () {

                deleteItemCallback.bind({id: id})();

                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };


        // ********* F I L E  F U N C T I O N S **********

        $scope.clickFile = function (fileId) {

            $('a[id ^= "viewFile"]').each(function () {
                $(this).hide();
            });

            $('a[id ^= "deleteFile"]').each(function () {
                $(this).hide();
            });

            $('.doc').each(function () {
                $(this).removeClass('bold').removeClass('clicked-folder');
            });

            $(`#viewFile${fileId}`).show();

            if ($rootScope.isSuperAdmin) {
                $(`#deleteFile${fileId}`).show();
            }
            $(`#${fileId}`).addClass('bold').addClass('clicked-folder');
        };

        $scope.deleteFile = function (id, fileName) {

            $('#modalConfirmHeader').html('DELETING FILE');
            $('#modalConfirmText').html(`Are you sure you want to delete this file - ${fileName}?`);

            let deleteItemCallback = function () {

                FirebaseAuthService.getUserToken().then(tokenId => {

                    FSPDocumentTreeService.deleteFile($scope.fspId, this.id, tokenId, $scope.visitorId)
                        .then(() => location.reload())
                        .catch(err => $scope.checkErrStatus(err));

                });

            };

            $('#modalAgreeLink').click(function () {

                deleteItemCallback.bind({id: id})();

                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.viewFile = function (url) {
            console.log(url);
            window.open(url);
        };


        // ********* T R E E  S T R U C T U R E  F U N C T I O N S **********

        function retrieveFSPFolders () {

            return $q((resolve, reject) => {

                FSPDocumentTreeService.getFolders($scope.fspId, $scope.tokenId, $scope.visitorId)
                    .then(folders => {

                        $scope.folders = folders.data.fsp_document_folders;

                        $scope.selectedFolderId = null;
                        $scope.addFolderOrFile = false;
                        $scope.newSubfolder = '';
                        $scope.html = '<ul role="tree" aria-labelledby="tree1">';

                        const parentFolders = $scope.folders.filter(folder => folder.fsp_document_folder === null); //get all top-level folders

                        parentFolders.sort((a, b) => {
                            const textA = a.title.toUpperCase();
                            const textB = b.title.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });

                        for (let i = 0; i < parentFolders.length; i++) {
                            displayFolderAndFiles(parentFolders[i], 1, parentFolders.length, i + 1);
                        }

                        $scope.html += '</ul>';
                        $scope.trustedHtml = $sce.trustAsHtml($scope.html);
                        $scope.$applyAsync();

                        resolve();

                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        }

        function prepareTreeStructure () {
            $scope.processing = false;
            setTimeout(function () {

                const trees = document.querySelectorAll('[role="tree"]');

                for (let i = 0; i < trees.length; i++) {
                    var t = new Tree(trees[i]);
                    t.init();
                }

                const treeItems = document.querySelectorAll('[role="treeitem"]');

                for (let i = 0; i < treeItems.length; i++) {

                    treeItems[i].addEventListener('click', function (event) {
                        event.stopPropagation();
                        event.preventDefault();
                    });

                }

                $('a[id ^= "addItem"]').each(function () {
                    $(this).hide();
                });
                $('a[id ^= "deleteItem"]').each(function () {
                    $(this).hide();
                });

                $('li[role="treeitem"]').each(function () {
                    $(this).removeClass('bold');
                });

            }, 500);
        }

        function displayFolderAndFiles (folder, level, size, position) {

            const childFolders = retrieveChildFolders(folder); // retrieve sub directories of this directory

            childFolders.sort((a, b) => {
                const textA = a.title.toUpperCase();
                const textB = b.title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            $scope.html += `<li style="margin-left:${level * 20}px" 
                role="treeitem" 
                id="${folder.id}"
                aria-level="${level}"
                aria-setsize="${size}"
                aria-posinset="${position}"
                aria-expanded="${childFolders.length > 0 || folder.fsp_document_files.length > 0}">
                <p id="caption${folder.id}" title="${folder.title}" 
                ondblclick="angular.element(document.getElementById('${folder.id}')).scope().addFolder(${folder.id})" 
                onclick="angular.element(document.getElementById('${folder.id}')).scope().clickFolder(${folder.id})"> ${folder.title}
                
                <a class="hidden directory_tree_action" id="deleteItem${folder.id}"href="#" 
                onclick="angular.element(document.getElementById('${folder.id}')).scope().deleteFolder(${folder.id}, '${folder.title}')">
                <i class="material-icons">delete</i></a>
                
                <a class="hidden directory_tree_action" id="editItem${folder.id}" href="#" 
                onclick="angular.element(document.getElementById('${folder.id}')).scope().editFolder(${folder.id}, '${folder.title}')">
                <i class="material-icons">edit</i></a>
                                
                <a class="hidden directory_tree_action" id="addItem${folder.id}" href="#" 
                onclick="angular.element(document.getElementById('${folder.id}')).scope().addFolder(${folder.id})">
                <i class="material-icons">add</i></a>
                
                </p>`;

            if (childFolders.length > 0 || folder.fsp_document_files.length > 0) {
                $scope.html += '<ul role="group">';
            }

            // invoke function recursively for each sub directory
            for (let i = 0; i < childFolders.length; i++) {
                displayFolderAndFiles(childFolders[i], level, childFolders.length, i + 1);
            }

            // display line for each file in this directory
            for (let j = 0; j < folder.fsp_document_files.length; j++) {

                const file = folder.fsp_document_files[j];

                $scope.html += `<li role="treeitem" style="margin-left:${level * 20}px"
                    aria-level="${level + 1}"
                    id="${file.id}"
                    aria-setsize="${folder.fsp_document_files.length}"
                    aria-posinset="${j + childFolders.length + 1}"
                    ondblclick="angular.element(document.getElementById('${file.id}')).scope().viewFile('${file.url}')"
                    class="doc" onclick="angular.element(document.getElementById('${file.id}')).scope().clickFile(${file.id})"> ${file.title}
                    
                    <a class="hidden directory_tree_action" id="deleteFile${file.id}" href="#" 
                    onclick="angular.element(document.getElementById('${file.id}')).scope().deleteFile(${file.id}, '${file.title}')">
                    <i class="material-icons">delete</i></a>
                    
                    <a class="hidden directory_tree_action" id="viewFile${file.id}" href="#" 
                    onclick="angular.element(document.getElementById('${file.id}')).scope().viewFile('${file.url}')">
                    <i class="material-icons">visibility</i></a>
                    
                    </li>`;

            }

            if (childFolders.length > 0 || folder.fsp_document_files.length > 0) {
                $scope.html += '</ul>';
            }

            $scope.html += '</li>';

        }

        function retrieveChildFolders (parent) {
            return $scope.folders.filter(folder => folder.fsp_document_folder == parent.id);
        }

    });
