angular.module('ics')
    .controller('FSBSubmissionDatesAdminController', function ($scope, $state, $controller,
                                                               $timeout, FSBSubmissionDatesService, CategoriesService,
                                                               FirebaseAuthService) {

        $scope.fspId = $state.params.fsp_id;
        $scope.fspName = decodeURIComponent($state.params.fsp_name);
        $scope.submission_types = [];
        const now = new Date();

        $scope.submission_date = {
            id: 0,
            submission_type: null,
            submissions: [],
            submissions_to_delete: [],
            due_date_day: now.getDate(),
            due_date_month: now.getMonth(),
            is_recurring: '',
            frequency: '',
            send_email_reminder: '',
            reminder_date: new Date(),
            email_reminder_to: 'submissions@complianceservices.co.za',
            email_reminder_subject: '',
            email_reminder_body: '',
            due_date_year: now.getFullYear(),
            is_submitted: false,
            email_template : null,
            notes: ''
        };

        $scope.editing = false;
        $scope.adding = false;
        $scope.tokenId = '';
        $scope.title = `${$scope.fspName} - Deadlines and Submissions`;
        $scope.processing = true;

        $scope.days = [];
        for (let i = 1; i <= 31; i++) $scope.days.push(i);
        $scope.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        $scope.years = [];
        for (let i = now.getFullYear() - 20; i <= now.getFullYear() + 5; i++) $scope.years.push(i);

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            init();
        });

        function init() {

            retrieveSubmissionDates();

            setTimeout(function () {
                $(document).ready(function () {
                    $('select').material_select();
                });
            }, 500);

        };

        async function retrieveSubmissionDates() {

            try {

                $scope.processing = true;
                $scope.editing = false;
                $scope.adding = false;

                const res = await FSBSubmissionDatesService.getSubmissionDatesList ($scope.fspId, $scope.tokenId, $scope.visitorId);

                $scope.submission_dates = res.data.fsb_submission_dates;
                $scope.submission_dates.forEach (date => {
                    date.submissions.forEach (submission => {
                        submission.submission_date = new Date (submission.submission_date);
                    });
                    date.submissions.sort ((a, b) => b.submission_date.getTime () - a.submission_date.getTime ());
                });
                $scope.processing = false;

                const res2 = await CategoriesService.getCategoriesListFromCode ('SUB', $scope.tokenId, $scope.visitorId);
                $scope.submission_types = res2.data.categories;

                $scope.submission_types.sort ((a, b) => {
                    return a.name.toUpperCase () - b.name.toUpperCase ();
                });

                const res3 = await FSBSubmissionDatesService.getSubmissionRemindersEmailList($scope.tokenId, $scope.visitorId);
                $scope.email_templates = res3.data.templates;

                $scope.processing = false;
                $timeout (function () {

                    // let $dateInput = $ ('#new_submission_date').pickadate ({
                    //     selectMonths: true, // Creates a dropdown to control month
                    //     selectYears: 100, // Creates a dropdown of 100 years to control year
                    //     format: "yyyy-mm-dd"
                    // });
                    //
                    // let datePicker = $dateInput.pickadate ('picker');
                    // datePicker.set ('select', new Date ());

                    $ ('select').material_select ();
                });

            } catch(err){
                $scope.checkErrStatus(err);
            }

        }

        $scope.submitSubmissionDateForm = function ($form) {
            if ($form.$valid) {
                $scope.saveSubmissionDate();
            }
        };

        $scope.submitSubmissionForm = function ($form) {
            if ($form.$valid) {
                $scope.saveSubmission();
            }
        };

        $scope.editSubmissionDate = function (index) {
            $scope.title = 'Edit Submission Date';

            $scope.submission_date = Object.assign({}, $scope.submission_dates[index]);

            $scope.submission_date.submission_type = $scope.submission_date.submission_type.id.toString();
            $scope.submission_date.due_date_day = $scope.submission_date.due_date_day.toString();
            $scope.submission_date.due_date_month = $scope.submission_date.due_date_month.toString();
            $scope.submission_date.due_date_year = $scope.submission_date.due_date_year ? $scope.submission_date.due_date_year.toString() : now.getFullYear().toString();
            $scope.submission_date.is_submitted = !!$scope.submission_date.is_submitted;
            $scope.submission_date.notes = $scope.submission_date.notes || '';
            $scope.submission_date.submissions_to_delete = [];
            $scope.submission_date.reminder_date = $scope.submission_date.reminder_date ? new Date($scope.submission_date.reminder_date) : new Date();
            $scope.submission_date.email_template = $scope.submission_date.email_template ? $scope.submission_date.email_template.id.toString() : null;

            $timeout(function () {
                $('select').material_select();
            });

            $scope.editing = true;
            $scope.adding = false;
        };

        $scope.addSubmissionDate = function () {
            $scope.title = 'Add submission date';

            $scope.submission_date = {
                id: 0,
                submission_type: null,
                submissions: [],
                submissions_to_delete: [],
                due_date_day: now.getDate().toString(),
                due_date_month: now.getMonth().toString(),
                due_date_year: now.getFullYear().toString(),
                is_recurring: true,
                is_submitted: false,
                send_email_reminder: true,
                reminder_date: new Date(),
                email_reminder_to: 'submissions@complianceservices.co.za',
                email_reminder_subject: '',
                email_reminder_body: '',
                notes: '',
                frequency: '',
                email_template: null
            };
            $timeout(function () {
                $('select').material_select();
            });

            $scope.editing = true;
            $scope.adding = false;

        };

        $scope.addSubmission = function (id, $index) {
            $scope.selectedSubmission = $scope.submission_dates[$index];
            $scope.new_submission_date = new Date();
            $scope.new_submission_extension_granted = false;

            $scope.editing = false;
            $scope.adding = true;

        };

        $scope.goBack = function () {
            if ($scope.editing) {
                $scope.editing = false;
            } else {
                $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
            }

        };

        $scope.saveSubmissionDate = function () {

            if (!$scope.submission_date.submission_type) {
                return;
            }

            FirebaseAuthService.getUserToken()
                .then(tokenId => {

                    $scope.submission_date.reminder_date = $('#reminder_date').val();
                    if ($scope.submission_date.send_email_reminder){
                        if (Math.abs(moment(new Date($scope.submission_date.reminder_date)).diff(moment(), 'years')) > 100) {
                            return alert('The reminder date appears to be incorrect!');
                        }
                    }


                    $scope.processing = true;

                    if ($scope.submission_date.id) {

                        FSBSubmissionDatesService
                            .editSubmissionDate(
                                $scope.submission_date.id,
                                $scope.submission_date.submission_type,
                                $scope.submission_date.submissions_to_delete,
                                $scope.submission_date.due_date_day,
                                $scope.submission_date.due_date_month,
                                $scope.submission_date.is_recurring,
                                $scope.submission_date.send_email_reminder,
                                $scope.submission_date.reminder_date,
                                $scope.submission_date.email_reminder_to,
                                $scope.submission_date.email_reminder_subject,
                                $scope.submission_date.email_reminder_body,
                                $scope.submission_date.due_date_year,
                                $scope.submission_date.is_submitted,
                                $scope.submission_date.notes,
                                $scope.submission_date.email_template,
                                $scope.submission_date.frequency,
                                tokenId
                            )
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));

                    } else {

                        FSBSubmissionDatesService
                            .createSubmissionDate(
                                $scope.fspId,
                                $scope.submission_date.submission_type,
                                $scope.submission_date.due_date_day,
                                $scope.submission_date.due_date_month,
                                $scope.submission_date.is_recurring,
                                $scope.submission_date.send_email_reminder,
                                $scope.submission_date.reminder_date,
                                $scope.submission_date.email_reminder_to,
                                $scope.submission_date.email_reminder_subject,
                                $scope.submission_date.email_reminder_body,
                                $scope.submission_date.due_date_year,
                                $scope.submission_date.is_submitted,
                                $scope.submission_date.notes,
                                $scope.submission_date.email_template,
                                $scope.submission_date.frequency,
                                tokenId
                            )
                            .then(() => location.reload())
                            .catch(err => $scope.checkErrStatus(err));
                    }

                });

        };

        $scope.saveSubmission = function () {

            if (!$scope.selectedSubmission) return;

            FSBSubmissionDatesService
                .addSubmissionOnDate(
                    $scope.selectedSubmission.id,
                    $('#new_submission_date').val(),
                    $scope.new_submission_extension_granted,
                    $scope.tokenId, $scope.visitorId)
                .then(res => {
                    location.reload();
                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.cancelEditing = function ($event) {
            $('body').addClass('bg-grey-1');
            $event.preventDefault();

            $scope.editing = false;
            $scope.adding = false;
            $scope.title = `${$scope.fspName} - administer submission dates`;

        };

        $scope.deleteSubmissionDate = function (fsbSubmissionDateId, index) {

            $('#modalConfirmHeader').html('DELETING SUBMISSION DATE');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSBSubmissionDatesService.deleteSubmissionDate(this.fsbSubmissionDateId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({fsbSubmissionDateId: fsbSubmissionDateId});

            $('#modalAgreeLink').click(function () {
                deletionEvent();
                $('#are_you_sure_modal').modal('close');
            });

            $('#are_you_sure_modal').modal('open');

        };

        $scope.deleteSubmission = function (id, $index) {

            if (!$scope.submission_date.submissions_to_delete) {
                $scope.submission_date.submissions_to_delete = [id];
            } else {
                $scope.submission_date.submissions_to_delete.push(id);
            }

            $scope.submission_date.submissions.splice($index, 1);

        }

    });
