angular.module('ics')

.controller('FSPReportController', function($scope, $state, $controller, $window, 
    FSPReportsService, FirebaseAuthService) {

    $scope.reportId = $state.params.fsp_report_id;
    $scope.errorMessage = '';
    $scope.report = {};
    $scope.emailAudit = [];
    $scope.processing = true;
    $scope.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    angular.extend(this, $controller('BaseAdminController', { $scope: $scope }));

    function init() {

        FSPReportsService.getFSPReport($scope.reportId)
            .then(details => {
                $scope.report = details.data.report;
                console.log(details.data.report);
                $scope.emailAudit = details.data.email_audit;

                $scope.report.representatives.forEach(representative => {

                    const cat1LicenceItems = representative.license_items.filter(item => item.category_name.toUpperCase().trim() === 'CATEGORY I');
                    const cat2LicenceItems = representative.license_items.filter(item => item.category_name.toUpperCase().trim() === 'CATEGORY II');
                    const cat2ALicenceItems = representative.license_items.filter(item => item.category_name.toUpperCase().trim() === 'CATEGORY IIA');

                    const cat1Items = [];
                    const cat2Items = [];
                    const cat2AItems = [];
                    cat1LicenceItems.forEach(categoryLicenseItem => formatLicensingCategories(cat1Items, categoryLicenseItem));
                    cat2LicenceItems.forEach(categoryLicenseItem => formatLicensingCategories(cat2Items, categoryLicenseItem));
                    cat2ALicenceItems.forEach(categoryLicenseItem => formatLicensingCategories(cat2AItems, categoryLicenseItem));

                    representative.cat1Items = cat1Items;
                    representative.cat2Items = cat2Items;
                    representative.cat2AItems = cat2AItems;

                });

                function formatLicensingCategories(catItems, categoryLicenseItem) {

                    if (categoryLicenseItem.advice || categoryLicenseItem.intermediary || categoryLicenseItem.sus) {

                        const services = [];
                        let doa = '';

                        if (categoryLicenseItem.intermediary) {
                            services.push('I');
                            if (categoryLicenseItem.intermediary_date) {
                                doa = moment(categoryLicenseItem.intermediary_date).format('DD/MM/YYYY');
                            }
                        }
                        if (categoryLicenseItem.sus) {
                            services.push('SuS');
                            if (categoryLicenseItem.sus_date) {
                                doa = moment(categoryLicenseItem.sus_date).format('DD/MM/YYYY');
                            }
                        }
                        if (categoryLicenseItem.advice) {
                            services.push('A');
                            if (categoryLicenseItem.advice_date) {
                                doa = moment(categoryLicenseItem.advice_date).format('DD/MM/YYYY');
                            }
                        }

                        const item = {
                            product: categoryLicenseItem.financial_product_name,
                            services: services.join(', '),
                            doa: doa
                        };

                        catItems.push(item);
                    }

                }

                if ($scope.report.fsb_submissions) {
                    $scope.report.fsb_submissions.sort((a, b) => (new Date(b.submission_date)).getTime() - (new Date(a.submission_date)).getTime());
                }

                if ($scope.report.fsb_submission_dates) {
                    $scope.report.fsb_submission_dates.forEach(date => date.due_date_month = $scope.months[date.due_date_month]);
                }

                if ($scope.report.profile_change_pipeline) {
                    $scope.report.profile_change_pipeline.sort((a, b) => (new Date(b.created)).getTime() - (new Date(a.created)).getTime());
                }
                if ($scope.report.implemented_profile_changes && $scope.report.implemented_profile_changes.length) {
                    $scope.report.implemented_profile_changes.slice(0, 30).sort((a, b) => (new Date(b.submission_date)).getTime() - (new Date(a.submission_date)).getTime());
                }
                $scope.processing = false;
            })
            .catch(err => {
                console.log(err);
                throw (err);
            });

    }

    $(document).ready(function() {
        init();
    });

    $scope.historyBack = function() {
        $window.history.back();
    };

    $scope.digitallySignDocument = function($event) {

        $event.preventDefault();

        $('#modalConfirmHeader').html('You are about to digitally sign this report');
        $('#modalConfirmText').html('Are you sure you wish to proceed?');

        const digitallySignReport = function() {
            $scope.processing = true;
            FirebaseAuthService.getUserToken()
                .then(tokenId => FSPReportsService.digitallySignReport($scope.reportId, $scope.visitorId, tokenId))
                .then(() => init())
                .catch(err => $scope.checkErrStatus(err));
        };
       
        $(document).ready(function() {
            $('#modalAgreeLink').click(function() {
                digitallySignReport();
                $('#are_you_sure_modal').modal('close');
            });
            $('#are_you_sure_modal').modal('open');

        });

    };

    $scope.filterCategories = function(value) {
        return value.advice || value.intermediary || value.discretion || value.sus;
    };

});