angular.module('ics')
    .controller('CalendarController', function ($scope, $controller, CalendarService, FirebaseAuthService) {

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $scope.calendarView = 'month';
        $scope.viewDate = new Date();
        $scope.calendarTitle = 'Reminder Calendar';
        $scope.editing = false;
        $scope.hour = 0;
        $scope.minute = 0;
        $scope.reminderId = null;

        $scope.hour = $scope.padNumber((new Date()).getHours());
        $scope.minute = '00';

        $scope.hours = Array.from({length: 24}, (v, i) => $scope.padNumber(i));
        $scope.minutes = ['00', '15', '30', '45'];

        let $dateInput = $('#reminder_date').pickadate({
            selectMonths: true,
            selectYears: 100,
            format: "yyyy-mm-dd",
            onSet: function (context) {
                $scope.viewDate = new Date(context.select);
            }
        });
        let datePicker = $dateInput.pickadate('picker');

        function init () {

            datePicker.set('select', $scope.viewDate);
            FirebaseAuthService.getUserToken().then(tokenId => {

                // retrieve the last date stored in local storage
                if (typeof(Storage) !== "undefined") {
                    let lastRetrievedDate = localStorage.getItem("ics-reminder-last-date");
                    if (lastRetrievedDate) {
                        $scope.viewDate = new Date(lastRetrievedDate);
                        localStorage.removeItem("ics-reminder-last-date");
                    }
                }

                $scope.token = tokenId;
                retrieveReminders();
                $('select').material_select();

            });

        }

        init();

        function retrieveReminders () {

            CalendarService.getReminders($scope.token)
                .then(reminders => {

                    $scope.reminders = reminders.data.reminders;
                    $scope.reminders.forEach(reminder => {

                        reminder.actions = [
                            {
                                label: "<i style='font-size:16px;color:#FFFFFF;' class='material-icons'>mode_edit</i>",
                                onClick: function (args) {
                                    editReminder(args.calendarEvent);
                                }
                            },
                            {
                                label: "<i style='font-size:16px;color:#FFFFFF;' class='material-icons'>delete</i>",
                                onClick: function (args) {
                                    deleteReminder(args.calendarEvent);
                                }
                            }
                        ];

                        reminder.startsAt = new Date(reminder.startsAt);
                        reminder.startsAt = moment(reminder.startsAt).add(-reminder.startsAt.getTimezoneOffset(), 'm').toDate();

                        if (moment(reminder.startsAt).valueOf() > moment().valueOf()) {

                            reminder.color = {
                                primary: '#D02C33',
                                secondary: '#e27a7e'
                            }

                        } else {

                            reminder.color = {
                                primary: '#979797',
                                secondary: '#bbbbbb'
                            }

                        }

                    });
                    console.log($scope.reminders);
                    $scope.$applyAsync();

                }).catch(err => $scope.checkErrStatus(err));
        }

        function editReminder (calendarEvent) {
            $scope.reminderId = calendarEvent.id;
            $scope.viewDate = calendarEvent.startsAt;

            $scope.reminderTitle = calendarEvent.title;
            $scope.reminderDescription = calendarEvent.description;
            $scope.hour = $scope.padNumber($scope.viewDate.getHours());
            $scope.minute = $scope.padNumber($scope.viewDate.getMinutes());
            $scope.editing = true;
        }

        function deleteReminder (calendarEvent) {

            $('#modalConfirmHeader').html('DELETING REMINDER');
            $('#modalConfirmText').html('Are you sure you want to delete this reminder?');

            const deleteCallback = function () {
                FirebaseAuthService.getUserToken().then(tokenId => {
                    CalendarService.deleteReminder(this.id, tokenId, $scope.visitorId).then(() => {
                        if (typeof(Storage) !== 'undefined') {
                            localStorage.setItem("ics-reminder-last-date", $scope.viewDate.toString());
                        }
                        location.reload();
                    });
                });
            };
            $('#modalAgreeLink').click(function () {
                deleteCallback.bind({id: calendarEvent.id})();
                $('#are_you_sure_modal').modal('close');
            });
            $('#are_you_sure_modal').modal('open');

        };


        $scope.eventClicked = function (calendarEvent) {
            console.log(calendarEvent);
        };

        $scope.timeSpanClicked = function (calendarDate) {
            console.log(calendarDate);
            $scope.viewDate = calendarDate;
            datePicker = $dateInput.pickadate('picker');
            datePicker.set('select', $scope.viewDate);

        };

        $scope.viewDateMonthTitle = function () {
            return moment($scope.viewDate).format('MMM YYYY');
        };

        $scope.viewDateTitle = function () {
            return moment($scope.viewDate).format('DD MMM YYYY');
        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveReminder();
            }
        };

        $scope.saveReminder = function () {

            $scope.viewDate.setHours(0, 0, 0, 0);
            $scope.viewDate.setHours(parseInt($scope.hour));
            $scope.viewDate.setMinutes(parseInt($scope.minute));

            console.log($scope.viewDate);

            let currentTime = new Date();

            if ($scope.viewDate.getTime() < currentTime.getTime()) {
                $('#modalInfoHeader').html('Error');
                $('#modalInfoText').html('Reminder dates cannot be in the past');
                $('#information_modal').modal('open');
                return;
            }
            if (($scope.reminderDescription && $scope.reminderDescription.trim().length === 0) && $scope.reminderTitle.trim().length === 0) {
                $('#modalInfoHeader').html('Error');
                $('#modalInfoText').html('Please provide a title or description');
                $('#information_modal').modal('open');
                return;
            }

            $scope.viewDate = moment($scope.viewDate).add($scope.viewDate.getTimezoneOffset(), 'm').toDate();

            if ($scope.reminderId) {

                CalendarService.updateReminder($scope.reminderId, moment($scope.viewDate).format('YYYY-MM-DD HH:mm'),
                    $scope.reminderTitle, $scope.reminderDescription, $scope.token).then(res => {
                    if (typeof(Storage) !== 'undefined') {
                        localStorage.setItem("ics-reminder-last-date", $scope.viewDate.toString());
                    }
                    location.reload();

                }).catch(err => {

                    $('#modalInfoHeader').html('Error');
                    $('#modalInfoText').html(err.message ? err.message : err);
                    $('#information_modal').modal('open');

                })

            } else {

                CalendarService.createReminder(moment($scope.viewDate).format('YYYY-MM-DD HH:mm'),
                    $scope.reminderTitle, $scope.reminderDescription, $scope.token).then(res => {
                    if (typeof(Storage) !== 'undefined') {
                        localStorage.setItem("ics-reminder-last-date", $scope.viewDate.toString());
                    }
                    location.reload();

                }).catch(err => {

                    $('#modalInfoHeader').html('Error');
                    $('#modalInfoText').html(err.message ? err.message : err);
                    $('#information_modal').modal('open');

                });

            }

        }

    });