angular.module('ics')
    .factory('TwoFactorAuthService', function ($http, $state, $rootScope) {

    
        return {

            requestOneTimePin: function (firebaseTokenId) {
                return new Promise((resolve, reject) => {

                    var req = {
                        method: 'GET',
                        url: '/api/two_factor_auth/send_otp',
                        headers: {
                            'x-firebase-token': firebaseTokenId,
                            'Content-Type': 'application/json'
                        },
                        timeout: 8000
                    };
                    $http(req).then(res => {
                        resolve(res);
                    }, err => {
                        reject(err);
                    });

                });
            },

            verifyOneTimePin: function (firebaseTokenId, otp, visitorId) {
                return new Promise((resolve, reject) => {

                    var req = {
                        method: 'GET',
                        url: `/api/two_factor_auth/verify/${otp}/${visitorId}`,
                        headers: {
                            'x-firebase-token': firebaseTokenId,
                            'Content-Type': 'application/json'
                        },
                        timeout: 8000
                    };
                    $http(req).then(res => {
                        if (res && res.data && res.data.result) {
                            resolve(res.data.result);
                        }
                        reject("One time pin could not be validated");
                    }, err => {
                        reject(err);
                    });

                });
            },

            setupTwoFactorAuthentication: function (firebaseTokenId) {
                return new Promise((resolve, reject) => {

                    var req = {
                        method: 'GET',
                        url:`/api/two_factor_auth/setup`,
                        headers: {
                            'x-firebase-token': firebaseTokenId,
                            'Content-Type': 'application/json'
                        },
                        timeout: 8000
                    };
                    $http(req).then(res => {
                        console.log(res);
                        resolve(res);
                    }, err => {
                        reject(err);
                    });

                });
            },

            cancelTwoFactorAuthentication: function (firebaseTokenId) {
                return new Promise((resolve, reject) => {

                    var req = {
                        method: 'GET',
                        url:`/api/two_factor_auth/cancel`,
                        headers: {
                            'x-firebase-token': firebaseTokenId,
                            'Content-Type': 'application/json'
                        },
                        timeout: 8000
                    };
                    $http(req).then(res => {
                        console.log(res);
                        resolve(res);
                    }, err => {
                        reject(err);
                    });

                });
            },

        }

    });
