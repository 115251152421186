angular.module('ics')
    .controller('SubmitProfileChangeController', function ($scope, $state, $controller, $timeout,
                                                           FSPService, FirebaseAuthService,
                                                           FSBEmailTemplatesService, SubmitProfileChangeService, FirebaseStorageService) {

        $scope.errorMessage = '';
        $scope.tokenId = '';
        $scope.processing = true;

        $scope.profileChangeId = '';
        $scope.attachments = [];
        $scope.email_subject = '';
        $scope.email_body = '';
        $scope.email_template = null;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService
            .getUserToken()
            .then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.processing = true;
                $scope.profileChangeId = $state.params.profile_change_id;
                $scope.fspId = $state.params.fsp_id;

                FSPService
                    .GetFSPChangeDetails($scope.profileChangeId, $scope.tokenId, $scope.visitorId)
                    .then((response) => $scope.fspChangeDetails = response.data.status_change)
                    .then(() => {

                        FSBEmailTemplatesService.getFSBEmailTemplatesList($scope.tokenId, $scope.visitorId)
                            .then((response) => {
                                $scope.emailTemplates = response.data.fsb_email_templates;
                                $scope.processing = false;

                                $timeout(function () {
                                    $('select').material_select();
                                    $('textarea').trigger('autoresize');
                                });

                                $(document).ready(function () {

                                    const docUploadControl = document.getElementById("documentUpload");

                                    const fileDetails = {
                                        folder: "/fsp_profile_change_attachments",
                                        attachments: $scope.fspChangeDetails.attachments
                                    };

                                    const eventDelegator = $scope.fileUploader.bind(fileDetails);
                                    docUploadControl.addEventListener('change', eventDelegator);

                                    $scope.$on('filesUploaded', function (event, attachments) {
                                        const combined = new Set($scope.fspChangeDetails.attachments.concat(attachments));
                                        $scope.fspChangeDetails.attachments = [...combined];
                                        $scope.$apply();
                                    });
                                });

                            });

                    })
                    .catch(err => $scope.checkErrStatus(err));
            })
            .catch(err => $scope.checkErrStatus(err));


        $scope.selectEmailTemplate = function () {
            $scope.emailTemplates.forEach(template => {
                if (template.id === $scope.email_template) {
                    $scope.email_subject = template.email_subject;
                    $scope.email_body = template.email_body;
                    $scope.recipient_list = template.recipient_list;
                }
            });
        };

        $scope.goBack = function () {
            $('body').addClass('bg-grey-1');
            $state.transitionTo('admin-fsp-details',
                {profile_change_id: $scope.profileChangeId, fsp_id: $scope.fspId});
        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.submitProfileChange();
            }
        };

        $scope.submitProfileChange = function () {
            $scope.processing = true;

            FirebaseAuthService.getUserToken()
                .then(tokenId => {

                    let attachments = [];
                    $scope.fspChangeDetails.attachments.forEach(template => {
                        if (!!template.attach_to_email) {
                            attachments.push(template);
                        }
                    });

                    SubmitProfileChangeService
                        .submitProfileChange($scope.profileChangeId, $scope.email_subject,
                            $scope.email_body, $scope.recipient_list, attachments, tokenId, $scope.visitorId)
                        .then(() => {
                            $('#modalInfoHeader').html('Thank you');
                            $('#modalInfoText').html('The profile change has been submitted.');
                            $('#information_modal').modal('open');
                            setTimeout(function () {
                                $('#information_modal').modal('close');
                                $scope.goBack();
                            }, 2000);
                        })
                        .catch(err => $scope.checkErrStatus(err));

                })
                .catch(err => $scope.checkErrStatus(err));
        };

    });