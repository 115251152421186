angular.module('ics')

    .factory('FSPScopeOfLicensingService', function ($http) {

        return {

            getScopeOfLicensing: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_scope_of_licensing/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            postScopeOfLicensing: function (fspId,
                                            category_i_applicable,
                                            category_ii_applicable,
                                            category_iia_applicable,
                                            scope_of_licensing_items,
                                            attachments,
                                            classes_of_business,
                                            sub_classes_of_business,
                                            firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: `/api/fsp_scope_of_licensing/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        scope_of_licensing_items: scope_of_licensing_items || [],
                        category_i_applicable: category_i_applicable,
                        category_ii_applicable: category_ii_applicable,
                        category_iia_applicable: category_iia_applicable,
                        classes_of_business: classes_of_business,
                        sub_classes_of_business: sub_classes_of_business,
                        attachments: attachments || []
                    }
                };

                return $http(req);

            }

        }

    });
