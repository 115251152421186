angular.module('ics')
    .controller('FSPDetailsController', function ($rootScope, $scope, $state, $controller, $timeout,
                                                  FSPService, FirebaseStorageService, CategoriesService,
                                                  FirebaseAuthService) {
        $scope.fspId = '';
        $scope.associatedFSPs = [];
        $scope.errorMessage = '';
        $scope.followUpList = [];
        $scope.showFollowUp = false;
        $scope.changeListStatuses = [];
        $scope.processing = true;

        $scope.privileges = {
            fsb_submissions: false,
            fsp_details: false,
            scope_of_licensing: false,
            contact_persons: false,
            clients: false,
            shareholders: false,
            directors: false,
            compliance_breaches: false,
            key_individuals: false,
            representatives: false,
            third_party_admins: false,
            external_auditor: false,
            profile_changes: false,
            operational_risk_matrix: false,
            market_risk_matrix: false,
            regulatory_risk_matrix: false,
            compliance_documents: false
        };

        angular.extend(this, $controller('BaseAdminController', { $scope: $scope }));

        $(document).ready(function () {
            $('#profile_change_status').material_select();
        });

        $scope.changeFSP = () => {

            const e = document.getElementById("selected_fsp");
            const fspId = e.options[e.selectedIndex].value;
            $scope.fspId = fspId;

            setTimeout(async() => {
                await $scope.retrieveFSPDetails();
            }, 200);
        }

        $scope.retrieveFSPDetails = async () => {
            try {
                $scope.processing = true;
                const tokenId = await FirebaseAuthService.getUserToken();
                console.log($scope.fspId);

                const response1 = await FSPService.GetFSPDetails($scope.fspId, tokenId, $scope.visitorId);
                $scope.fspDetails = response1.data.fsp;
                $scope.privileges = response1.data.privileges;
                $scope.processing = false;

                const response2 = await FSPService.GetFSPChangeList($scope.fspId, tokenId, $scope.visitorId)
                $scope.changesList = response2.data.status_changes_list;

                let statuses = new Map();
                $scope.changesList.forEach(item => {

                    if (statuses.has(item.status.name)) {
                        statuses.set(item.status.name, statuses.get(item.status.name) + 1);
                    } else {
                        statuses.set(item.status.name, 1);
                    }

                    let created = new Date(item.created);
                    created.setTime(created.getTime() - created.getTimezoneOffset() * 60 * 1000);
                    item.created = created;

                });

                $scope.statusTotals = [];
                for (let [key, value] of statuses) {
                    $scope.statusTotals.push({
                        status: key,
                        count: value
                    });
                }

                $scope.statusTotals.sort(function (a, b) {
                    return a.count - b.count;
                });


                const response3 = await FSPService.GetFSPFollowUpList($scope.fspId, tokenId, $scope.visitorId);
                $scope.followUpList = response3.data.follow_up_list;
                $scope.followUpList.sort((a, b) => (new Date(a.follow_up_on)).getTime() - (new Date(b.follow_up_on)).getTime());

                $scope.followUpList.forEach(item => {

                    let created = new Date(item.created);
                    created.setTime(created.getTime() - created.getTimezoneOffset() * 60 * 1000);
                    item.created = created;

                    let follow_up_on = new Date(item.follow_up_on);
                    follow_up_on.setTime(follow_up_on.getTime() - follow_up_on.getTimezoneOffset() * 60 * 1000);
                    item.follow_up_on = follow_up_on;

                });


                const response4 = await CategoriesService.getCategoriesListFromCode('profile_change_status', tokenId, $scope.visitorId);

                const possibleCategories = [];
                response4.data.categories.forEach(category => {
                    if ($scope.changesList.find(change => change.status.id == category.id)) {
                        possibleCategories.push(category)
                    }
                });

                $scope.profileChangeStatusCategories = [].concat(possibleCategories);

                $scope.profileChangeStatusCategories.sort((a, b) => {
                    let textA = a.name.toUpperCase();
                    let textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                $scope.$apply();

                $timeout(function () {
                    $('.modal').modal();
                    $('select').material_select();
                    $('#profile_status').material_select();
                }, 1000);


            } catch (err) {
                $scope.checkErrStatus(err);
            }
        }

        $scope.init = async function () {

            try {
                $scope.fspId = $state.params.fsp_id || '';
                const associatedFSPs = window.localStorage.getItem('associated_fsps');
                if (associatedFSPs) {
                    $scope.associatedFSPs = JSON.parse(associatedFSPs);
                }
                angular.element(document).ready(async () => {
                    if ($scope.associatedFSPs.length) {
                        $scope.fspId = $scope.associatedFSPs[0].id;
                    }
                    await $scope.retrieveFSPDetails();
                });

            } catch (err) {
                $scope.checkErrStatus(err);
            }
        };

        $scope.documentAdmin = function () {
            $state.transitionTo('admin-fsp-document-tree', {
                fsp_id: $scope.fspId,
                fsp_name: encodeURI($scope.fspDetails.name)
            });
        };

        $scope.complianceDocumentAdmin = function () {
            $state.transitionTo('admin-fsp-compliance-documents', {
                fsp_id: $scope.fspId,
                fsp_name: encodeURI($scope.fspDetails.name)
            });
        };

        $scope.riskProfileAdmin = function () {
            $state.transitionTo('admin-fsp-risk-profile', {
                fsp_id: $scope.fspId,
                fsp_name: encodeURI($scope.fspDetails.name)
            });
        };

        $scope.encodeItem = function (item) {
            return encodeURIComponent(item);
        };

        $scope.reportHistory = function () {
            $state.transitionTo('admin-fsp-report-history', {
                fsp_id: $scope.fspId,
                fsp_name: encodeURI($scope.fspDetails.name)
            });
        };

        $scope.addProfileChange = function () {
            $state.transitionTo('admin-fsp-profile-change', { fsp_id: $scope.fspId });
        };

        $scope.viewProfileChangeDetails = function (id) {
            if (!$rootScope.isSuperAdmin) {
                return;
            }
            $state.transitionTo('admin-fsp-profile-change-details', { profile_change_id: id, fsp_id: $scope.fspId });
        };

        $scope.followUpProfileChange = function (id) {
            $state.transitionTo('admin-fsp-follow-up-profile-change', { profile_change_id: id, fsp_id: $scope.fspId });
        };

        $scope.init();

    });
;
