angular.module('ics')

    .factory('FSBEmailTemplatesService', function ($http) {

        return {

            getFSBEmailTemplatesList: function (firebaseTokenId, visitorId) {

                let req = {
                    method: 'GET',
                    url: '/api/fsb_email_template/list',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },

            createFSBEmailTemplate: function (templateName, profileChangeTypes, emailSubject, emailBody, recipientList, firebaseTokenId, visitorId) {
                let req = {
                    method: 'POST',
                    url: '/api/fsb_email_template/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'template_name': templateName,
                        'email_body': emailBody,
                        'email_subject': emailSubject,
                        'recipient_list': recipientList,
                        'profile_change_types': profileChangeTypes
                    }
                };

                return $http(req);
            },

            editFSBEmailTemplate: function (emailTemplateId, templateName, profileChangeTypes,
                                            emailSubject, emailBody, recipientList, firebaseTokenId, visitorId) {
                let req = {
                    method: 'PUT',
                    url: `/api/fsb_email_template/${emailTemplateId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'template_name': templateName,
                        'email_body': emailBody,
                        'email_subject': emailSubject,
                        'recipient_list': recipientList,
                        'profile_change_types': profileChangeTypes
                    }
                };

                return $http(req);
            },

            deleteFSBEmailTemplate: function (emailTemplateId, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    url: `/api/fsb_email_template/${emailTemplateId}`,
                    timeout: 8000
                };

                return $http(req);
            }
        }

    });
