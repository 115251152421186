angular.module('ics')

    .factory('FSPRiskProfileService', function ($http) {

        return {

            getRiskProfile: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_risk_profile/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            saveRiskProfile: function (fspId, risk_profile_scope_of_license,
                                       risk_profile_fit_and_proper,
                                       risk_profile_operational_ability,
                                       risk_profile_financial_soundness,
                                       risk_profile_code_of_conduct,
                                       risk_profile_representatives,
                                       risk_profile_honesty_and_integrity,
                                       risk_profile_discretionary,
                                       risk_profile_general, firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: `/api/fsp_risk_profile/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        risk_profile_scope_of_license: risk_profile_scope_of_license,
                        risk_profile_fit_and_proper: risk_profile_fit_and_proper,
                        risk_profile_operational_ability: risk_profile_operational_ability,
                        risk_profile_financial_soundness: risk_profile_financial_soundness,
                        risk_profile_code_of_conduct: risk_profile_code_of_conduct,
                        risk_profile_representatives: risk_profile_representatives,
                        risk_profile_honesty_and_integrity: risk_profile_honesty_and_integrity,
                        risk_profile_discretionary: risk_profile_discretionary,
                        risk_profile_general: risk_profile_general
                    }
                };

                return $http(req);

            },
        }

    });
