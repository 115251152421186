angular.module('ics')

    .factory('ComplianceDocumentTypesService', function ($http) {

        return {

            getComplianceDocumentTypesList: function (firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: '/api/compliance_document_type/list',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },

            createComplianceDocumentType: function (documentType, code, firebaseTokenId, visitorId) {
                var req = {
                    method: 'POST',
                    url: '/api/compliance_document_type/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'document_type': documentType,
                        'code': code
                    }
                };

                return $http(req);
            },

            editComplianceDocumentType: function (documentTypeId, documentType, code, firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/compliance_document_type/${documentTypeId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        'document_type': documentType,
                        'code': code
                    }
                };

                return $http(req);
            },

            deleteComplianceDocumentType: function (documentTypeId, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/compliance_document_type/${documentTypeId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            }
        }

    });
