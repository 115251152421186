angular.module('ics')
    .controller('EmailTemplatesAdminController', function ($scope, $state, $controller, $timeout,
                                                           EmailTemplatesService, CategoriesService,
                                                           FirebaseAuthService) {
        $scope.item = {
            emailTemplateId: '',
            templateName: '',
            emailSubject: '',
            emailBody: '',
            profileChangeTypes: []
        };

        $scope.editing = false;
        $scope.tokenId = '';
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken().then(tokenId => {
            $scope.tokenId = tokenId;
            $scope.init();
        })

        $scope.init = function () {

            $scope.processing = true;

            EmailTemplatesService
                .getEmailTemplatesList($scope.tokenId, $scope.visitorId)
                .then((response) => {
                    $scope.emailTemplatesList = response.data.email_templates;
                    $scope.emailTemplatesList.sort((a, b) => {
                        const textA = a.template_name.toUpperCase();
                        const textB = b.template_name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    reset();
                })
                .then(() => {
                    CategoriesService.getCategoriesListFromCode('profile_change_type', $scope.tokenId, $scope.visitorId)
                        .then(res => {
                            $scope.profileChangeTypeCategories = res.data.categories;
                            $scope.profileChangeTypeCategories.sort((a, b) => {
                                const textA = a.name.toUpperCase();
                                const textB = b.name.toUpperCase();
                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                            });
                            $timeout(function () {
                                $('select').material_select();
                                $('textarea').trigger('autoresize');
                            });
                        })
                        .catch(err => $scope.checkErrStatus(err))
                })
                .catch(err => $scope.checkErrStatus(err))

        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveEmailTemplate(
                    $scope.item.templateName,
                    $scope.item.emailSubject,
                    $scope.item.emailBody,
                    $scope.item.profileChangeTypes);
            }
        };

        $scope.saveEmailTemplate = function (templateName, emailSubject, emailBody, profileChangeTypes) {

            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.item.emailTemplateId === '') {
                EmailTemplatesService
                    .createEmailTemplate(templateName,
                        profileChangeTypes,
                        emailSubject,
                        emailBody,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            } else {
                EmailTemplatesService.editEmailTemplate($scope.item.emailTemplateId,
                    templateName,
                    profileChangeTypes,
                    emailSubject,
                    emailBody,
                    $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }

        };

        $scope.deleteEmailTemplate = function (emailTemplateId, index) {

            $('#modalConfirmHeader').html('Deleting Email Template');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                EmailTemplatesService
                    .deleteEmailTemplate(this.emailTemplateId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({emailTemplateId: emailTemplateId});

            $(document).ready(function () {

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            });

        };

        $scope.editEmailTemplate = function (emailTemplateId, index) {
            $('body').removeClass('bg-grey-1');
            $scope.item.emailTemplateId = emailTemplateId;
            $scope.item.templateName = $scope.emailTemplatesList[index].template_name;
            $scope.item.emailSubject = $scope.emailTemplatesList[index].email_subject;
            $scope.item.emailBody = $scope.emailTemplatesList[index].email_body;
            $scope.item.profileChangeTypes = $scope.emailTemplatesList[index].profile_change_types.map(category => category.id);

            $timeout(function () {
                $('select').material_select();
                $('textarea').trigger('autoresize');
            });

            $scope.editing = true;
        };

        $scope.addEmailTemplate = function () {
            $('body').removeClass('bg-grey-1');
            $scope.item.emailTemplateId = '';
            $scope.item.templateName = '';
            $scope.item.emailSubject = '';
            $scope.item.emailBody = '';
            $scope.item.profileChangeTypes = [];

            $scope.editing = true;
            $scope.errorMessage = '';

            $timeout(function () {
                $('select').material_select();
                $('textarea').trigger('autoresize');
            });
        };

        $scope.cancelSaveEmailTemplate = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset () {
            $scope.processing = false;
            $scope.editing = false;
            $scope.errorMessage = '';
            $scope.item.templateName = '';
            $scope.item.emailSubject = '';
            $scope.item.emailBody = '';
            $scope.item.profileChangeTypes = [];
        }

    });