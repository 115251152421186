angular.module('ics')
    .factory('FirebaseAuthService', function ($http, $state, $rootScope) {

        let userRoles = [];
        let userFirebaseToken = '';
        let processingToken = false;

        firebase.auth().onAuthStateChanged(function (user) {
            if (user && !processingToken) { //the user must be logged in, and we must not be retrieving his/her token
                processingToken = true;
                firebase.auth().currentUser.getToken().then(token => {
                    if (token === userFirebaseToken) { //thus token is identical to the one stored in memory, don't get user's roles!
                        return;
                    }
                    userFirebaseToken = token;

                    try {

                        localStorage.setItem('user_custom_firebase_token', token);
                        // get decoded token so we can set our userRoles from NodeJS server
                        var req = {
                            method: 'POST',
                            url: '/api/auth/user_role',
                            timeout: 8000,
                            data: {
                                'token': token
                            }
                        };
                        $http(req).then(res => {
                            // extract roles from decoded token into an array to be used by controllers

                            if (res && res.data && res.data.role) {

                                let role = res.data.role;
                                localStorage.setItem('ics_user_role', JSON.stringify(role));
                                $rootScope.$broadcast('user-role-loaded', role);
                            }
                            processingToken = false;
                        }, err => {
                            console.log(err);
                        });
                    } catch (e) {
                        console.log(e);
                    }
                })

            } else {
                processingToken = false;
            }
        });

        return {

            getUserRole: function () {
                return userRole;
            },

            getLoggedInUserRole: function () {
                let userRole = {};
                //put in try catch in case this value has not yet been set in localStorage
                try {
                    userRole = JSON.parse(localStorage.ics_user_role)
                    this.setTwoFaRequiredAfterCallback();
                } catch (exception) {
                    console.log(exception);
                    userRole = this.getRoleFromCookie();
                    if (userRole !== null) {
                        if (userRole.entity_role !== 'fsp') {
                            this.requestRedirect();
                        }
                    }
                }
                return {
                    userRole: userRole
                };
            },

            setTwoFaRequiredAfterCallback: function () {
                if (getCookie('duo_token') !== '') {
                    let twoFaRequired = {'two_fa_required': true}
                    this.saveTwoFaRequired(twoFaRequired)
                }
            },

            saveTwoFaRequired: function (res) {
                localStorage.setItem('two_fa_required', JSON.stringify(res));
            },

            getTwoFaRequired: function () {
                let getTwoFaRequired;
                if (localStorage.getItem('two_fa_required')) {
                    getTwoFaRequired = JSON.parse(localStorage.two_fa_required);
                    return getTwoFaRequired.two_fa_required;
                } else {
                    return false
                }
            },

            performDuo2FARedirect: function (res) {
                try {
                    const auth = res && res.data && res.data.authenticate;
                    const uri = res && res.data && res.data.result;
                    if (auth) {
                        window.location = uri;
                    } else {
                        $state.transitionTo('signin');
                    }
                } catch (err) {
                    alert('An error has occurred, please try again: ' + err);
                    $rootScope.$apply();
                    $rootScope.$applyAsync();
                } finally {
                    $rootScope.show_loading = false;
                }
            },

            getRoleFromCookie: function () {
                let userRole;
                if (getCookie('duo_token') !== '') {
                    const res = this.signInWithCustomToken(getCookie('custom_token'));
                    localStorage.setItem('user_custom_firebase_token', getCookie('custom_token'));

                    userRole = {
                        'entity_id': getCookie('entity_id'),
                        'entity_role': getCookie('entity_role'),
                        'entity_type': getCookie('entity_type'),
                        'id': getCookie('id')
                    }

                    $rootScope.currentState = 'signin';
                    let twoFaReq = {'two_fa_required': true};
                    localStorage.setItem('two_fa_required', JSON.stringify(twoFaReq));
                    localStorage.setItem('ics_user_role', JSON.stringify(userRole));
                } else {
                    userRole = null;
                }
                return userRole;
            },

            signOut: function () {
                localStorage.removeItem('user_custom_firebase_token');
                localStorage.removeItem('ics_user_role');
                localStorage.removeItem('pitchin_user_roles');
                localStorage.removeItem('two_fa_required');


                for (let key in localStorage) {
                    if (key.substring(0, 9) == 'firebase:') {
                        localStorage.removeItem(key);
                    }
                }

                return firebase.auth().signOut();
            },

            getUserToken() {
                return new Promise((resolve, reject) => {

                    let currentTimeStamp = (new Date()).getTime();
                    let currentAccessToken = '';

                    try {

                        for (let key in localStorage) {
                            if (key.startsWith("firebase:authUser:")) {

                                let json = JSON.parse(localStorage[key])
                                let expirationTime = json['stsTokenManager']['expirationTime'];
                                currentAccessToken = json['stsTokenManager']['accessToken'];

                                if (currentTimeStamp < expirationTime) {
                                    resolve(currentAccessToken);
                                } else {
                                    resolve(firebase.auth().currentUser.getToken(true));
                                }

                            }
                        }

                        resolve();

                    } catch (exception) {
                        console.log(exception);
                        this.signOut().then(() => {
                            $state.transitionTo('signin');
                        });
                    }
                });
            },

            createCustomToken: function (firebaseTokenId, visitorId) {
                return new Promise((resolve, reject) => {

                    var req = {
                        method: 'GET',
                        url: '/api/auth/create_custom_token',
                        headers: {
                            'x-firebase-token': firebaseTokenId,
                            'Content-Type': 'application/json'
                        },
                        timeout: 8000
                    };
                    $http(req).then(res => {
                        if (res && res.data && res.data.token) {
                            this.signInWithCustomToken(res.data.token).then(() => {
                                resolve({ 
                                    role: res.data.role, 
                                    two_fa_required : !!res.data.two_fa_required
                                });
                            }, err => {
                                reject(err);
                            })
                        } else {
                            reject('not token received');
                        }
                    }, err => {
                        reject(err);
                    });

                });
            },

            getContactPersonAssociatedFsps: function (firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: '/api/fsp_contact_person_associated_fsps',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            requestRedirect: function () {
                return new Promise( (resolve, reject) => {
                    const token = this.getUserToken();

                    var req = {
                        method: 'GET',
                        url: `/admin-dashboard`,
                        headers: {
                            'x-firebase-token': token,
                            'Content-Type': 'application/json',
                        },
                        timeout: 8000
                    };
                    return $http(req)
                });
            },

            createUserRole: function (firebaseTokenId, firebaseUserId, entityId, entityType, role) {
                return new Promise((resolve, reject) => {

                    var req = {
                        method: 'POST',
                        url: '/api/auth/create_user_role',
                        headers: {
                            'x-firebase-token': firebaseTokenId,
                            'Content-Type': 'application/json'
                        },
                        data: {
                            firebase_user_id: firebaseUserId,
                            entity_id: entityId,
                            entity_type: entityType,
                            role: role,
                        },
                        timeout: 8000
                    };
                    $http(req).then(res => {
                        resolve()
                    }, err => {
                        reject(err);
                    });

                });

            },

            createUserWithEmailAndPassword: function (email, password, first_name, last_name) {
                var promise = new Promise(
                    function (resolve, reject) {

                        firebase.auth().createUserWithEmailAndPassword(email, password).then(res => {

                            let user = firebase.auth().currentUser;

                            user.updateProfile({
                                displayName: `${first_name} ${last_name}`
                            }).then(res => {

                                resolve(user.getToken());

                            });

                        }).catch(err => {
                            reject(err);
                        });

                    });

                return promise;
            },

            createUserWithEmailAndPasswordReturnUid: function (email, password, name) {
                var promise = new Promise(
                    function (resolve, reject) {

                        firebase.auth().createUserWithEmailAndPassword(email, password).then(res => {

                            let user = firebase.auth().currentUser;

                            user.updateProfile({
                                displayName: name
                            }).then(res => {

                                resolve(user.uid);

                            });

                        }).catch(err => {
                            reject(err);
                        });

                    });

                return promise;
            },

            signInWithCustomToken: function (token) {
                localStorage.removeItem('user_custom_firebase_token');
                localStorage.removeItem('ics_user_role');

                let promise = new Promise(
                    (resolve, reject) => {

                        //log in using the new custom token
                        firebase.auth().signInWithCustomToken(token).then(res => {

                            firebase.auth().currentUser.getToken(true).then(token => {

                                localStorage.setItem('user_custom_firebase_token', token);
                                resolve();

                            });


                        }).catch(err => {

                            reject(err);

                        })
                    });

                return promise;
            },

        }

    });
