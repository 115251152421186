angular.module('ics')
    .controller('FSBEmailTemplatesAdminController', function ($scope, $state, $controller, $timeout,
                                                              FSBEmailTemplatesService,
                                                              CategoriesService,
                                                              FirebaseAuthService) {
        $scope.item = {

            emailTemplateId: '',
            templateName: '',
            emailSubject: '',
            emailBody: '',
            recipientList: '',
            profileChangeTypes: []

        };

        $scope.editing = false;
        $scope.errorMessage = '';
        $scope.tokenId = '';
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken()
            .then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.init();
            })
            .catch(err => $scope.checkErrStatus(err));

        $scope.init = function () {
            $scope.processing = true;

            FSBEmailTemplatesService
                .getFSBEmailTemplatesList($scope.tokenId, $scope.visitorId)
                .then((response) => {
                    $scope.emailTemplatesList = response.data.fsb_email_templates;
                    reset();
                })
                .then(() => {
                    CategoriesService.getCategoriesListFromCode('profile_change_type', $scope.tokenId, $scope.visitorId)
                        .then(res => {
                            $scope.profileChangeTypeCategories = res.data.categories;
                            $timeout(function () {
                                $('select').material_select();
                            });
                        })
                        .catch(err => $scope.checkErrStatus(err));
                })
                .catch(err => $scope.checkErrStatus(err));
        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveEmailTemplate(
                    $scope.item.templateName,
                    $scope.item.emailSubject,
                    $scope.item.emailBody,
                    $scope.item.profileChangeTypes,
                    $scope.item.recipientList);
            }
        };

        $scope.saveEmailTemplate = function (templateName, emailSubject,
                                             emailBody,
                                             profileChangeTypes,
                                             recipientList) {
            $scope.errorMessage = '';
            $scope.processing = true;
            if ($scope.item.emailTemplateId === '') {
                FSBEmailTemplatesService
                    .createFSBEmailTemplate(
                        templateName,
                        profileChangeTypes,
                        emailSubject,
                        emailBody,
                        recipientList,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            } else {
                FSBEmailTemplatesService
                    .editFSBEmailTemplate(
                        $scope.item.emailTemplateId,
                        templateName,
                        profileChangeTypes,
                        emailSubject,
                        emailBody,
                        recipientList,
                        $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }
        };

        $scope.deleteEmailTemplate = function (emailTemplateId, index) {
            $('#modalConfirmHeader').html('Deleting Email Template');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FSBEmailTemplatesService
                    .deleteFSBEmailTemplate(this.emailTemplateId, $scope.tokenId, $scope.visitorId)
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            };

            const deletionEvent = deleteType.bind({emailTemplateId: emailTemplateId});

            $(document).ready(function () {

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            });
        };

        $scope.editEmailTemplate = function (emailTemplateId, index) {
            $('body').removeClass('bg-grey-1');
            $scope.item.emailTemplateId = emailTemplateId;
            $scope.item.templateName = $scope.emailTemplatesList[index].template_name;
            $scope.item.emailSubject = $scope.emailTemplatesList[index].email_subject;
            $scope.item.emailBody = $scope.emailTemplatesList[index].email_body;
            $scope.item.recipientList = $scope.emailTemplatesList[index].recipient_list || '';
            $scope.item.profileChangeTypes = $scope.emailTemplatesList[index].profile_change_types.map(category => category.id);

            $timeout(function () {
                $('select').material_select();
                $('textarea').trigger('autoresize');
            });

            $scope.editing = true;
        };

        $scope.addEmailTemplate = function () {
            $('body').removeClass('bg-grey-1');
            $scope.item.emailTemplateId = '';
            $scope.item.templateName = '';
            $scope.item.emailSubject = '';
            $scope.item.emailBody = '';
            $scope.item.recipientList = '';
            $scope.item.profileChangeTypes = [];

            $scope.editing = true;
            $scope.errorMessage = '';

            $timeout(function () {
                $('select').material_select();
                $('textarea').trigger('autoresize');
            });
        };

        $scope.cancelSaveEmailTemplate = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset () {
            $scope.processing = false;
            $scope.editing = false;
            $scope.errorMessage = '';

            $scope.item.templateName = '';
            $scope.item.emailSubject = '';
            $scope.item.emailBody = '';
            $scope.item.recipientList = '';
            $scope.item.profileChangeTypes = [];
        }

    });