angular.module('ics')
    .controller('FSPAuditorsAdminController', function ($scope, $state, $controller, $timeout,
                                                        FSPAuditorsService,
                                                        FirebaseAuthService, FirebaseStorageService) {

            $scope.fspId = $state.params.fsp_id;
            $scope.fspName = decodeURIComponent($state.params.fsp_name);
            $scope.auditors = [];
            $scope.auditor = {
                id: 0,
                name: '',
                responsible_partner: '',
                contact_number: '',
                practice_number: '',
                // date_appointed: new Date(),
                attachments: []

            };
            $scope.editing = false;
            $scope.tokenId = '';
            $scope.title = `${$scope.fspName} - administer auditor`;
            $scope.processing = true;

            angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

            FirebaseAuthService.getUserToken().then(tokenId => {
                $scope.tokenId = tokenId;
                init();
            });

            function init() {

                retrieveAuditors();

                setTimeout(function () {
                    $(document).ready(function () {

                        // let $dateInput = $('#date_appointed').pickadate({
                        //     selectMonths: true, // Creates a dropdown to control month
                        //     selectYears: 100, // Creates a dropdown of 100 years to control year
                        //     format: "yyyy-mm-dd"
                        // });
                        //
                        // let datePicker = $dateInput.pickadate('picker');
                        // datePicker.set('select', $scope.auditor.date_appointed);

                        const docUploadControl = document.getElementById("documentUpload");

                        const fileDetails = {
                            folder: `/fsp_auditor_attachments/${$scope.fspId}`,
                            attachments: $scope.auditor.attachments
                        };

                        const eventDelegator = $scope.fileUploader.bind(fileDetails);
                        docUploadControl.addEventListener('change', eventDelegator);

                        $scope.$on('filesUploaded', function (event, attachments) {
                            const combined = new Set($scope.auditor.attachments.concat(attachments));
                            $scope.auditor.attachments = [...combined];
                            $scope.$apply();
                        });

                    })
                }, 1000);

            }

            function retrieveAuditors() {

                $scope.processing = true;
                $scope.editing = false;

                FSPAuditorsService.getAuditorsList($scope.fspId, $scope.tokenId, $scope.visitorId)
                    .then(res => {
                        $scope.auditors = res.data.auditors;
                        $scope.auditors.sort((a, b) => {
                            const textA = a.name.toUpperCase();
                            const textB = b.name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        $scope.auditors.forEach(auditor => {
                            auditor.attachments.sort((a, b) => {
                                const textA = a.file_name.toUpperCase();
                                const textB = b.file_name.toUpperCase();
                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                            });
                        });
                        $scope.processing = false;
                    })
                    .catch(err => $scope.checkErrStatus(err));
            }

            $scope.editAuditor = function (index) {
                $('body').removeClass('bg-grey-1');
                $scope.title = 'Edit auditor';

                const auditor = $scope.auditors[index];
                $scope.auditor = {
                    id: auditor.id,
                    name: auditor.name,
                    responsible_partner: auditor.responsible_partner,
                    contact_number: auditor.contact_number,
                    practice_number: auditor.practice_number,
                    // date_appointed: new Date(auditor.date_appointed),
                    attachments: auditor.attachments

                };
                $scope.editing = true;
            };

            $scope.submitForm = function ($form) {
                if ($form.$valid) {
                    $scope.saveAuditor($scope.auditor.id, $scope.auditor.name, $scope.auditor.responsible_partner,
                        $scope.auditor.contact_number, $scope.auditor.practice_number, $scope.auditor.attachments);
                }
            };

            $scope.addAuditor = function () {
                $('body').removeClass('bg-grey-1');
                $scope.title = 'Add auditor';

                $scope.auditor = {
                    id: 0,
                    name: '',
                    responsible_partner: '',
                    contact_number: '',
                    practice_number: '',
                    dt: '',
                    attachments: []

                };

                $scope.editing = true;

            };

            $scope.goBack = function () {
                $('body').addClass('bg-grey-1');
                if ($scope.editing) {
                    $scope.title = `${$scope.fspName} - administer auditor`;
                    return $scope.editing = false;
                }
                $state.transitionTo('admin-fsp-details', {fsp_id: $scope.fspId});
            };

            $scope.saveAuditor = function (id, name, responsible_partner, contact_number, practice_number, attachments) {

                FirebaseAuthService.getUserToken()
                    .then(tokenId => {

                        // scope.auditor.date_appointed = $('#date_appointed').val();

                        $scope.processing = true;

                        if (id) {

                            FSPAuditorsService
                                .editAuditor(id,
                                    name,
                                    responsible_partner,
                                    contact_number,
                                    practice_number,
                                    // $scope.auditor.date_appointed,
                                    attachments,
                                    tokenId, $scope.visitorId)
                                .then(() => location.reload())
                                .catch(err => $scope.checkErrStatus(err));


                        } else {

                            FSPAuditorsService
                                .createAuditor($scope.fspId,
                                    name,
                                    responsible_partner,
                                    contact_number,
                                    practice_number,
                                    // $scope.auditor.date_appointed,
                                    attachments,
                                    tokenId, $scope.visitorId)
                                .then(() => location.reload())
                                .catch(err => $scope.checkErrStatus(err));

                        }

                    });

            };

            $scope.cancelEditing = function ($event) {
                $('body').addClass('bg-grey-1');
                $event.preventDefault();
                $scope.editing = false;
                $scope.title = `${$scope.fspName} - administer auditor`;
            };

            $scope.deleteAuditor = function (auditorId, index) {

                $('#modalConfirmHeader').html('Deleting Auditor');
                $('#modalConfirmText').html('Are you sure you want to delete this item?');

                const deleteType = function () {

                    FSPAuditorsService
                        .deleteAuditor(this.auditorId, $scope.tokenId, $scope.visitorId)
                        .then(() => location.reload())
                        .catch(err => $scope.checkErrStatus(err));

                };

                const deletionEvent = deleteType.bind({auditorId: auditorId});

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            };

        }
    );
