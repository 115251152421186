angular.module('ics')

    .factory('RecycleBinService', function ($http) {

        return {

            getRecycleBin: function (firebaseTokenId, visitorId, entityType) {

                const req = {
                    method: 'GET',
                    // entity_type used as integer for data limit
                    url: `/api/recycle_bin/${entityType}/0`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 60000
                };

                return $http(req);

            },

            putBack: function (entityType, entityId, firebaseTokenId, visitorId) {
                const req = {
                    method: 'POST',
                    url: `/api/recycle_bin/${entityType}/${entityId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 60000
                };

                return $http(req);
            }


        }

    });
