angular.module('ics')
    .controller('CategoriesAdminController', function ($scope, $state, $controller,
                                                       CategoriesService, FirebaseAuthService) {

        $scope.categoryType = '';
        $scope.categoryId = '';
        $scope.index = -1;
        $scope.code = '';
        $scope.editing = false;
        $scope.processing = true;

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        $scope.init = function () {

            $scope.processing = true;
            $scope.categoryTypeId = $state.params.category_type_id;
            $scope.categoryTypeName = $state.params.category_type_name;
            getCategoriesList();

        };

        $scope.saveCategory = function (name, code, display_order) {

            $scope.errorMessage = '';
            $scope.processing = true;

            if (isNaN(display_order) || !isFinite(display_order)) {
                display_order = 1;
            }
            if ($scope.categoryId === '') {

                FirebaseAuthService
                    .getUserToken()
                    .then(tokenId => CategoriesService.createCategory($scope.categoryTypeId, name,
                        code, display_order, tokenId, $scope.visitorId))
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));

            } else {

                FirebaseAuthService.getUserToken()
                    .then(tokenId => CategoriesService.editCategory($scope.categoryId, name,
                        code, display_order, tokenId, $scope.visitorId))
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err));
            }
        };

        $scope.deleteCategory = function (categoryId, index) {

            $('#modalConfirmHeader').html('Deleting Category');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            const deleteType = function () {

                FirebaseAuthService.getUserToken()
                    .then(tokenId => CategoriesService.deleteCategory(this.categoryId, tokenId, $scope.visitorId))
                    .then(() => location.reload())
                    .catch(err => $scope.checkErrStatus(err))

            };

            const deletionEvent = deleteType.bind({categoryId: categoryId});

            $(document).ready(function () {

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            });

        };

        $scope.editCategory = function (categoryId, index) {
            $('body').removeClass('bg-grey-1');
            $scope.categoryId = categoryId;
            $scope.index = index;
            $scope.categoryName = $scope.categoriesList[index].name;
            $scope.categoryCode = $scope.categoriesList[index].code || "";
            $scope.categoryOrder = $scope.categoriesList[index].display_order || 1;
            $scope.editing = true;
        };

        $scope.addCategory = function () {
            $('body').removeClass('bg-grey-1');
            $scope.categoryId = '';
            $scope.categoryName = '';
            $scope.categoryCode = '';
            $scope.categoryOrder = 1;
            $scope.editing = true;
            $scope.errorMessage = '';
            $scope.index = -1;
        };

        $scope.cancelSaveCategory = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function getCategoriesList() {

            FirebaseAuthService
                .getUserToken()
                .then(tokenId => CategoriesService.getCategoriesList($scope.categoryTypeId, tokenId, $scope.visitorId))
                .then((response) => {
                    $scope.categoriesList = response.data.categories;
                    $scope.categoriesList.sort((a, b) => a.display_order - b.display_order);
                    $scope.$applyAsync();
                    reset();
                })
                .catch(err => $scope.checkErrStatus(err));

        }

        $scope.addEditSubCategories = function (categoryId, categoryName) {

            $state.transitionTo('admin-sub-categories', {
                category_id: categoryId,
                category_name: categoryName,
                category_type_id: $scope.categoryTypeId,
                category_type_name: $scope.categoryTypeName,
            });

        };

        function reset() {
            $scope.processing = false;
            $scope.errorMessage = '';
            $scope.categoryId = '';
            $scope.categoryName = '';
            $scope.categoryCode = '';
            $scope.categoryOrder = 1;
            $scope.index = -1;
            $scope.editing = false;
        }

        $scope.init();

    })
;