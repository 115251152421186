angular.module('ics')

    .factory('FSPRepresentativesService', function ($http) {

        return {

            getRepresentativesList: function (fspId, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp_representative_list/${fspId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);

            },

            createRepresentative: function (fspId,
                                            first_name,
                                            last_name,
                                            id_number,
                                            date_appointed,
                                            date_of_first_appointment,
                                            license_items,
                                            qualification,
                                            passed_re1,
                                            passed_re3,
                                            passed_re5,
                                            re5_exempt,
                                            re5_due_date,
                                            supervisor,
                                            qualification_name,
                                            qualification_code,
                                            qualification_due_date,
                                            attachments,
                                            key_individual_id,
                                            key_individual_name,
                                            cob,
                                            cob_due_date,
                                            firebaseTokenId, visitorId) {

                const req = {
                    method: 'POST',
                    url: '/api/fsp_representative/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        fsp: fspId,
                        first_name: first_name,
                        last_name: last_name,
                        id_number: id_number,
                        date_appointed: date_appointed,
                        date_of_first_appointment: date_of_first_appointment,
                        license_items: license_items,
                        qualification: !!qualification,
                        passed_re1: !!passed_re1,
                        passed_re3: !!passed_re3,
                        passed_re5: !!passed_re5,
                        re5_exempt: !!re5_exempt,
                        re5_due_date: re5_due_date,
                        supervisor: supervisor,
                        qualification_name: qualification_name,
                        qualification_code: qualification_code,
                        qualification_due_date: qualification_due_date,
                        attachments: attachments || [],
                        key_individual_id: key_individual_id || '',
                        key_individual_name: key_individual_name || '',
                        cob: cob || 'N/A',
                        cob_due_date: cob_due_date
                    }
                };

                return $http(req);

            },

            editRepresentative: function (fspRepresentativeId,
                                          first_name,
                                          last_name,
                                          id_number,
                                          date_appointed,
                                          date_of_first_appointment,
                                          license_items,
                                          qualification,
                                          passed_re1,
                                          passed_re3,
                                          passed_re5,
                                          re5_exempt,
                                          re5_due_date,
                                          supervisor,
                                          qualification_name,
                                          qualification_code,
                                          qualification_due_date,
                                          attachments,
                                          key_individual_id,
                                          key_individual_name,
                                          cob,
                                          cob_due_date,
                                          firebaseTokenId, visitorId) {

                const req = {
                    method: 'PUT',
                    url: `/api/fsp_representative/${fspRepresentativeId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000,
                    data: {
                        first_name: first_name,
                        last_name: last_name,
                        id_number: id_number,
                        date_appointed: date_appointed,
                        date_of_first_appointment: date_of_first_appointment,
                        license_items: license_items,
                        qualification: !!qualification,
                        passed_re1: !!passed_re1,
                        passed_re3: !!passed_re3,
                        passed_re5: !!passed_re5,
                        re5_exempt: !!re5_exempt,
                        re5_due_date: re5_due_date,
                        supervisor: supervisor,
                        qualification_name: qualification_name,
                        qualification_code: qualification_code,
                        qualification_due_date: qualification_due_date,
                        attachments: attachments || [],
                        key_individual_id: key_individual_id || '',
                        key_individual_name: key_individual_name || '',
                        cob: cob || 'N/A',
                        cob_due_date: cob_due_date
                    }
                };

                return $http(req);

            },

            deleteRepresentative: function (fspRepresentativeId, firebaseTokenId, visitorId) {

                const req = {
                    method: 'DELETE',
                    url: `/api/fsp_representative/${fspRepresentativeId}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);


            },


        }

    });
